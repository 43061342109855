import React from 'react'
import "./BannerImage.scss"
const BannerImage = ({Image, PageName,BannerTitle}) => {
    return (
        <div className="headerBanner">
            <img src={Image} className="img-fluid" alt="" />
            <div className="h-hero__section">
                <div className="container">
                    <div className="h-hero__heading-wrap">
                        <h2 className="h1 section-heading">
                            {PageName}
                        </h2>
                        <p className='mb-1'>{BannerTitle}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerImage