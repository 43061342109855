import React from "react";
import PageLayout from "../../Components/PageLayout";
import "./Career.scss";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import BannerImage from "../../Components/BannerImge/BannerImage";

const Career = () => {
	return (
		<PageLayout
			title="Career | Change the future with us – Radical Minds Technologies Pvt. Ltd"
			className="career-page"
			metaDescription={
				"Explore career opportunities and know more about our company culture and benefits on our career page."
			}
		>
			<BannerImage
				Image={"./assets/banner/career.jpg"}
				PageName={"Career"}
				BannerTitle={["Change the future with us"]}
			/>
			<div className="bg-gray fact">
				<div className="container">
					<h2 className="text-md-center text-left section-subheading mb-lg-4 mb-2 w-700">
						Why Radical Minds
					</h2>
					<p className="text-justify max-w-1000 mx-auto mt-md-0 mt-4">
						Radical Minds offers a dynamic and challenging work
						environment for individuals seeking a career in the BPO
						industry. Our company values innovative thinking, hard
						work, and a passion for success. Our focus is on
						creating a culture of excellence, respect, and
						continuous learning. We believe in providing our
						employees with opportunities for personal and
						professional growth. At Radical Minds, we recognize and
						reward the contributions of our employees, and we offer
						competitive compensation and benefits packages. Whether
						you're just starting or have years of experience, we
						have a range of positions available for those who are
						driven and dedicated to their craft. Join us and be part
						of a team that is shaping the future of customer
						support. Help us shape the future of the industry and
						take your career to new heights with Radical Minds.
					</p>
					<Link
						to="/career/opening"
						className="link-job mx-auto career-btn"
					>
						<span className="icon me-2">
							<FiChevronRight />
						</span>
						<span className="text">Current Opening</span>
					</Link>
				</div>
			</div>
			<div className="customer-sec">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-12">
							<div className="customer">
								<img
									src="./assets/image/career-env.jpg"
									className="img-fluid rounde-img shadow-img"
									alt=""
								/>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="client-content d-flex h-100  justify-content-center flex-column">
								<h2>Environment</h2>
								<p className="mb-2">
									Radical Minds is a great place to work, the
									team is composed of highly motivated
									creative people. We're driven by curiosity
									and the passion to experiment with new
									technologies and new approaches.
									Communication and openness are key
									components of our culture. Each person is
									well-respected and had the ear of everyone,
									which makes it easy to make decisions
									together. We've developed a way to work
									collaboratively that improves the speed of
									execution.
								</p>
							</div>
						</div>
					</div>
					<div className="row my-4 col-sm-reverse">
						<div className="col-md-6 col-12">
							<div className="client-content d-flex h-100  justify-content-center flex-column ps-0 pe-20">
								<h2>People</h2>
								<p className="m-0">
									The Radical Minds team is comprised of
									professionals from a variety of backgrounds,
									with expertise in fields such as business
									administration, marketing, and finance.
									They're all dedicated to helping our clients
									reach their full potential and achieve their
									business goals.
								</p>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="customer ms-auto">
								<img
									src="./assets/image/career-people.jpg"
									className="img-fluid rounde-img shadow-img"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="row mb-lg-4 mb-0">
						<div className="col-md-6 col-12">
							<div className="customer">
								<img
									src="./assets/image/career-culture.jpg"
									className="img-fluid rounde-img shadow-img"
									alt=""
								/>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="client-content d-flex h-100  justify-content-center flex-column">
								<h2>Culture</h2>
								<p>
									Our goal is not just to create a great
									product or service; it's also about creating
									a community where you feel comfortable
									knowing that your voice will be heard by
									everyone inside this company.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default Career;
