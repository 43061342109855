import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import "./Solutions.scss";
import { useParams } from "react-router";
import { SolutionsPages } from "./pages/SolutionsPages";
import SmallSideBarMobile from "../../Components/smallSideBarMobile";
import SmallSideBar from "../../Components/smallSideBar";

const Solutions = () => {
	const params = useParams();
	const slag = params.slag;
	const page = SolutionsPages.find((prd) => prd.slag === slag);
	if (!page) {
		window.location.href = "/404-page-not-found";
	}
	const data = page.pagedata[0];

	return (
		<PageLayout title={data.title} metaDescription={data.metaDescription}>
			<div className="solution_page">
				<BannerImage
					PageName={data.subtitle}
					BannerTitle={data.BannerTitle}
					Image={data.bannerImg}
				/>
				<SmallSideBarMobile isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false}/>
				<SmallSideBar  isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false}/>
				<div className="bg-gray">
					<div className="container py-0">
						<div className="w-1000 mx-auto">
							{/* <div className="brandLogoRow">
								{data.leadingLogo.map((logo, idx) => (
									<div className="logo-img" key={idx}>
										<img
											src={logo}
											alt=""
											className="img-fluid"
										/>
									</div>
								))}
							</div> */}
						</div>
					</div>
				</div>
				<div className="customer-sec">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12 pe-md-4">
								<div className="client-content p-0 d-flex flex-column h-100">
									<div>
										<h2 className="section-subheading text-left">
											{data.sec1Title}
										</h2>
									</div>
									<p>{data.para}</p>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="article_img">
									<img
										src={data.img}
										className="img-fluid rounde-img shadow-img"
										alt={data.alt}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="rmt-abt bg-orange">
					<div className="container">
						<div className="w-1000 mx-auto">
							<h2 className="text-center section-subheading mb-2">
								{data.section[0].title}
							</h2>
							<p className="text-center">
								{" "}
								{data.section[0].subtitle}
							</p>
						</div>
						<div className="row rmt-row">
							{data.section[0].feature.map((feat, id) => (
								<div
									className="col-md-4 col-12 mb-lg-0 mb-4"
									key={id}
								>
									<div className="rmt-card rmt-card-white center rmt-feature">
										<div className="icon icon80">
											<img
												src={feat.icon}
												alt={`${feat.title}-radicalminds`}
												className="img-fluid"
											/>
										</div>
										<div className="card-title">
											<h5>{feat.title}</h5>
										</div>
										<div className="card-desc">
											<p>{feat.para}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="section_5">
				<div className="container w-1000 mx-auto">
					<h3 className="section-heading text-center">
						{data.section[1].title}
					</h3>
					<p className="text-center fs14">
						{data.section[1].subtitle}
					</p>
					<div className="row mt-md-4 ">
						{data.section[1].feature.map((feat, id) => (
							<div
								className="col-md-6 col-12 mb-md-4 mb-2 pb-lg-0 pb-4"
								key={id}
							>
								<div className="rmt-card rmt-card-light-blue feat_card">
									<div className="feat_icon icon80">
										<img
											src={feat.icon}
											className="img-fluid"
											alt={`${feat.title}-radicalminds`}
										/>
									</div>
									<div className="card-body">
										<h5>{feat.title}</h5>
										<p>{feat.para}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="section_5">
				<div className="container pt-0">
					<h3 className="section-heading text-center mb-2">
						{data.section[2].title}
					</h3>
					<p className="text-center fs14">
						{data.section[2].subtitle}
					</p>
					<div className="row ">
						{data.section[2].service.map((ser, id) => (
							<div className="col-md-4 col-sm-6 col-12" key={id}>
								<div
									className="product_card icon-list w-100 h-100  vertical text-left"
									href="/en-sg/capabilities/integrations-apps"
									target="_blank"
									rel=""
								>
									<h5>{ser.title}</h5>
									<p>{ser.para}</p>
									<Link
										to={ser.link}
										className="arrow label-btn"
									>
										Learn more
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default Solutions;
