import React, { useState } from "react";
import PageLayout from "../../Components/PageLayout";
import "./CareerDetails.scss";
import { career1 } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { RadicalMind } from "../../assets";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../../helper/api";
import { useParams } from "react-router";
import ButtonLoader from "./../../Components/UI/ButtonLoader";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { TextField } from "../../Components/InputFeild/InputFeild";

const CareerDetails = () => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [formData, updateFormData] = useState({});
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const params = useParams();
	const location = useLocation();
	const Job_id = location.state
		? location.state.id
		: params.slug.split("RMTJOBID000")[1];
	const JobName = location.state.title;

	let toastObj = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const handleChange = (e) => {
		updateFormData((data) => ({
			...data,
			[e.target.name]: e.target.value.trim(),
		}));
	};
	const uploadCV = (e) => {
		console.log(e.target.files[0]);
		updateFormData((data) => ({
			...data,
			[e.target.name]: e.target.files[0],
		}));
	};

	const handleSubmit = async (e) => {
		setLoading(true);
		setIsValidated(true);
		e.preventDefault();
		try {
			const response = await api({
				method: "post",
				url: "apply",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				setLoading(false);
				toast.success(
					"Thank you! The Form Submitted Successfully",
					toastObj
				);
				var close = setInterval(() => {
					setShow(false);
					clearInterval(close);
				}, 1500);
			}
		} catch (err) {
			if (err.response.status === 400 && err.response.status === 505) {
				setLoading(false);
				toast.error("Please try Again!", toastObj);
			}
		}
	};

	useEffect(() => {
		const loadData = async () => {
			const response = await api({
				method: "post",
				url: "fetch",
				data: { career_id: Job_id },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;

			setData(jsonData.data[0]);
		};
		updateFormData({
			...formData,
			job_id: Job_id,
		});
		loadData();
	}, [Job_id]);

	return (
		<PageLayout
			title={JobName}
			metaDescription={
				"Explore career opportunities and know more about our company culture and benefits on our career page."
			}
		>
			<ToastContainer />
			<section className="section-space contact_form career_details">
				<BannerImage BannerTitle={"Apply Now!"} Image={career1} />
				{data && (
					<div className="container position-relative pt-4">
						<div className="row">
							<div className="col-md-8 col-12 mb-4">
								<div className="job_abt">
									<div className="job_intro mb-4">
										<div>
											<h3 className="section-heading m-0">
												{data.title}
											</h3>
											<p className="loc">
												{data["location"]}
											</p>
										</div>
										<button
											className="btn btn-primary btn-r"
											onClick={handleShow}
										>
											Apply Now
										</button>
									</div>
									<div
										className="job_content"
										dangerouslySetInnerHTML={{
											__html: data["description"]
												? JSON.parse(
														data["description"]
												  )[0]
												: "",
										}}
									></div>
								</div>
							</div>
							<div className="col-md-4 col-12">
								<div className="company_abt">
									<h6 className="heading">About us</h6>
									<img
										src={RadicalMind}
										className="img-fluid"
										alt=""
									/>
									<p>
										Radical Minds is a leading
										transformational outsourcing
										(BPO)company. We combine our deep
										industry Knowledge with technology and
										business expertise to co-create
										innovative, digital-led transformational
										solutions. We care for our customers so
										we have conceived, designed and also
										planned service with the aim to serve
										various industries. .
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
				<Modal
					show={show}
					onHide={handleClose}
					className="HomeModal career-form"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Apply Now</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form
							className=""
							onSubmit={handleSubmit}
							encType="multipart/ form data"
						>
							<div className="row">
								<div className="col-12">
									<TextField
										required
										onChange={handleChange}
										id="fullname"
										label={"Full Name"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={formData.fullname ?? ""}
									/>
								</div>
								<div className="col-md-6 col-12">
									<TextField
										required
										onChange={handleChange}
										id="email"
										label={"Email"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={formData.email ?? ""}
									/>
								</div>
								<div className="col-md-6 col-12">
									<TextField
										required
										onChange={handleChange}
										id="mobile"
										label={"Mobile"}
										className={"w-100"}
										type="number"
										isValidated={isValidated}
										defaultValue={formData.mobile ?? ""}
									/>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlhtmlFor="experience"
											className="required form-label"
										>
											Experience
										</label>
										<select
											className="form-select  career_filter career_location mt-1"
											name="experience"
											onChange={handleChange}
										>
											<option defaultValue="">
												All Job Experience
											</option>
											<option defaultValue="Fresher">
												Fresher
											</option>
											<option defaultValue="1-2 years">
												1-2 years
											</option>
											<option defaultValue="2-5">
												2-5 years
											</option>
										</select>
									</div>
								</div>
								<div className="col-md-6 col-12">
									<TextField
										required
										onChange={handleChange}
										id="qualification"
										label={"Qualification"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={
											formData.qualification ?? ""
										}
									/>
								</div>
								<div className="col-12 mt-2">
									<div className="form-group">
										<label
											htmlhtmlFor="location"
											className="required form-label"
										>
											Location
										</label>
										<select
											className="form-select  career_filter career_location"
											name="location"
											onChange={handleChange}
										>
											<option defaultValue="">
												Select Location
											</option>
											<option defaultValue="Mumbai">
												Mumbai
											</option>
											<option defaultValue="Gurugram">
												Gurugram
											</option>
											<option defaultValue="Bengaluru">
												Bengaluru
											</option>
											<option defaultValue="Chennai">
												Chennai
											</option>
											<option defaultValue="Noida">
												Noida
											</option>
											<option defaultValue="Hyderabad">
												Hyderabad
											</option>
											<option defaultValue="New York">
												New York
											</option>
										</select>
									</div>
								</div>
								<div className="col-12 mt-2">
									<div className="form-group">
										<label
											htmlhtmlFor="address"
											className="required"
										>
											Address
										</label>
										<span className="wpcf7-form-control-wrap address">
											<textarea
												type="text"
												name="address"
												className="form-control"
												id="address"
												defaultValue=""
												onChange={handleChange}
											/>
										</span>
									</div>
								</div>
								<div className="col-12 mt-2">
									<div className="form-group">
										<label
											htmlhtmlFor="drop_cv"
											className="required"
										>
											Upload your CV
										</label>
										<span className="wpcf7-form-control-wrap drop_cv">
											<input
												type="file"
												name="drop_cv"
												className="form-control"
												id="drop_cv"
												defaultValue=""
												onChange={uploadCV}
												required
											/>
										</span>
									</div>
								</div>
								<div className="ms-auto">
									<ButtonLoader
										type="Submit"
										className="applyBtn btn-r ms-auto d-block"
										data-id={data["id"]}
										loading={loading}
									>
										Submit
									</ButtonLoader>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			</section>
		</PageLayout>
	);
};

export default CareerDetails;
