import React from "react";

import PageLayout from "../../Components/PageLayout";
import "./Continous-Innovation.scss";
import BannerImage from "../../Components/BannerImge/BannerImage";

const ContinousInnovation = () => {
	return (
		<PageLayout
			title="Continuous Innovation | Customer Centric Approach – Radical Minds Technologies Pvt. Ltd."
			metaDescription={
				"At Radical Minds, we believe in continuous innovation to provide next-generation solutions."
			}
		>
			<BannerImage
				Image="./assets/banner/continous-innovation.jpg"
				PageName={"Continuous Innovation"}
				BannerTitle={["Customer Centric Approach"]}
			/>

			<div className="customer-sec">
				<div className="container">
					<div className="row pb-0">
						<div className="col-md-6 col-12">
							<div className="">
								<img
									src="./assets/image/continous-innovation.jpg"
									className="img-fluid rounde-img shadow-img"
									alt=""
								/>
							</div>
						</div>
						<div className="col-md-6 col-12 mt-md-0 mt-4">
							<div className="client-content  d-flex flex-column justify-content-center h-100">
								<h2 className="para-heading">
									Radical Minds don’t believe in Conventional
									Thinking
								</h2>
								<p style={{ margin: "0" }}>
									Radical Minds has given a task to its own
									people and management to challenge and
									overcome conventional thinking right from
									the start of its journey in 2010. In 2016
									and within six years after its
									incorporation, Radical Minds had already
									made a mark as one of the best contact
									centers across the globe. Since then Radical
									Minds is getting awards year after year and
									has seen multi-fold growth. Now, Radical
									Minds is known as one of the most preferred
									and innovative organizations in its own
									domain.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="customer-sec bg-gray">
				<div className="container">
					<div className="client-content  d-flex flex-column justify-content-center h-100">
						<h2 className="para-heading text-center">
							Radical Minds view from Inside
						</h2>
						<p className="text-center">
							2018, many industry veterans visited the Radical
							Minds office to see how Radical Minds works and how
							its management model supports innovative thoughts in
							the company. From the outside, the company
							successfully integrated multiple different
							competencies: IT engineering skills in software and
							intelligent-based expertise towards human assets.
							The outcome was that Radical Minds has built an
							unorthodox management model that is based on the
							following principles.
						</p>
					</div>
					<div className="row mt-md-4 mt-2">
						<div className="col-md-4 col-sm-6 col-12 my-lg-2 my-2">
							<div className="rmt-card rmt-card-white">
								<div className="card-title">
									<h5>Go-ahead capabilities</h5>
								</div>
								<div className="card-desc">
									<p>
										The company’s ability to integrate,
										develop, and reorganize internal and
										external competencies in order to meet
										swiftly changing surroundings.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-12 my-lg-2 my-2">
							<div className="rmt-card rmt-card-white">
								<div className="card-title">
									<h5>Continuous change</h5>
								</div>
								<div className="card-desc">
									<p>
										Radical Minds has been infused with a
										proactive approach to change. Delay,
										always makes a wrong mark.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-12 my-lg-2 my-2">
							<div className="rmt-card rmt-card-white">
								<div className="card-title">
									<h5>
										Customer and people-centric approach
									</h5>
								</div>
								<div className="card-desc">
									<p>
										Being Customer and People-Centric is
										always a challenge for any organization.
										Radical Minds focuses on both so
										beautifully and has been a key point for
										the success of Radical Minds. Creativity
										never stops here.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-4 col-sm-6 col-12 my-lg-2 my-2">
							<div className="rmt-card rmt-card-white">
								<div className="card-title">
									<h5>Speed on Innovation</h5>
								</div>
								<div className="card-desc">
									<p>
										Continuous innovation must combine
										different forms of reason within the
										same organization. PCI Approach would do
										wonders but with open and innovative
										minds around it and that’s what Radical
										Minds do on daily basis.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-12 my-lg-2 my-2">
							<div className="rmt-card rmt-card-white">
								<div className="card-title">
									<h5>80-20 Rule</h5>
								</div>
								<div className="card-desc">
									<p>
										Radical Minds believe the 80:20 rule
										gets applied everywhere as 80% of work
										gets directed by 20% of innovative
										thoughts. And the reason, Radical Minds
										keep pushing this to every single
										associate irrespective of their role and
										responsibilities.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default ContinousInnovation;
