export const RadicalMind = require("./Images/Logo/Logo.png");

export const businessBanner = require("./Images/businessBanner.jpg");
export const SalesBanner = require("./Images/CTAbanner.png");
export const ThankyouImage = require("../../src/assets/Images/tick.png");

// Logitech Logo
export const Logi1 = require("./Images/brandlogo/logi_1.png");
export const Logi2 = require("./Images/brandlogo/logi_2.png");
export const Logi3 = require("./Images/brandlogo/logi_3.png");
export const Logi4 = require("./Images/brandlogo/logi_4.png");

// Travel Logo
export const travel1 = require("./Images/brandlogo/travel_1.png");
export const travel2 = require("./Images/brandlogo/travel_2.png");
export const travel3 = require("./Images/brandlogo/travel_3.png");
export const travel4 = require("./Images/brandlogo/travel_4.png");
export const travel5 = require("./Images/brandlogo/travel_5.png");
export const travel6 = require("./Images/brandlogo/travel_6.png");
export const travel7 = require("./Images/brandlogo/travel_7.png");
export const travel8 = require("./Images/brandlogo/travel_8.png");
export const travel9 = require("./Images/brandlogo/travel_9.png");
export const travel10 = require("./Images/brandlogo/travel_10.png");
export const travel11 = require("./Images/brandlogo/travel_11.png");
export const travel12 = require("./Images/brandlogo/travel_12.png");

// gov Logo
export const gov1 = require("./Images/brandlogo/gov_1.png");
export const gov2 = require("./Images/brandlogo/gov_2.png");
export const gov3 = require("./Images/brandlogo/gov_3.png");

// tech Logo
export const tech1 = require("./Images/brandlogo/tech_1.png");
export const tech2 = require("./Images/brandlogo/tech_2.png");
// export const tech3 = require("./Images/brandlogo/tech_3.png");

// BSFI Logo
export const bsfi1 = require("./Images/brandlogo/bsfi_1.png");
export const bsfi2 = require("./Images/brandlogo/bsfi_2.png");
export const bsfi3 = require("./Images/brandlogo/bsfi_3.png");
export const bsfi4 = require("./Images/brandlogo/bsfi_4.png");
export const bsfi5 = require("./Images/brandlogo/bsfi_5.png");
export const bsfi6 = require("./Images/brandlogo/bsfi_6.png");
export const bsfi7 = require("./Images/brandlogo/bsfi_7.png");

// retail Logo
export const retail1 = require("./Images/brandlogo/retail_1.png");
export const retail2 = require("./Images/brandlogo/retail_2.png");
export const retail3 = require("./Images/brandlogo/retail_3.png");
export const retail4 = require("./Images/brandlogo/retail_4.png");
export const retail5 = require("./Images/brandlogo/retail_5.png");
export const retail6 = require("./Images/brandlogo/retail_6.png");
export const retail7 = require("./Images/brandlogo/retail_7.png");

// EDU Logo  
export const edu1 = require("./Images/brandlogo/edu_1.png");
export const edu2 = require("./Images/brandlogo/edu_2.png");
export const edu3 = require("./Images/brandlogo/edu_3.png");
export const edu4 = require("./Images/brandlogo/edu_4.png");

// hel Logo  
export const hel1 = require("./Images/brandlogo/hel_1.png");
export const hel2 = require("./Images/brandlogo/hel_2.png");
export const hel3 = require("./Images/brandlogo/hel_3.png");
export const hel4 = require("./Images/brandlogo/hel_4.png");
export const hel5 = require("./Images/brandlogo/hel_5.png");


export const contactBanner = require("./Images/Contactbanner.png");

export const BPO = require("./Images/business/CallCenter.jpg");
export const OpticalFiber = require("./Images/business/OpticalFiber.jpg");
export const ItTech = require("./Images/business/Tech.jpg");

export const RMMD = require("./Images/team/RMMD.jpg");
export const Stuti = require("./Images/team/Stuti.jpg");
export const Syeddd = require("./Images/team/syeddd.jpg");
export const RP = require("./Images/team/t.jpg");
export const Umesh = require("./Images/team/t1.jpg");
export const SeemaSingh = require("./Images/team/SeemaSingh.jpg");
export const Sachin = require("./Images/team/Sachin.jpg");
export const Abhishek = require("./Images/team/abhishek.jpeg");
export const Rajat = require("./Images/team/rajat.jpeg");

export const Tarun = require("./Images/team/2.jpg");

export const news1 = require("./Images/newsa/bpo.webp");
export const news2 = require("./Images/Slider/slider7.jpg");
export const news3 = require("./Images/newsa/food.avif");

export const Mumbai = require("./Images/location/Mumbai.png");
export const Favicon = require("./Images/favicon.png");
export const dots = require("./Images/dots.png");
export const India = require("./Images/location/India.png");
export const Austrlia = require("./Images/location/Austrlia.png");
export const Canada = require("./Images/location/Canada.png");
export const USA = require("./Images/location/USA.png");
export const China = require("./Images/location/China.png");
export const UK = require("./Images/location/UK.png");

export const DeepKalra = require("./Images/client/DeepKalra.jpg");
export const MakeMyTrip = require("./Images/client/logo/MakeMyTrip.png");
export const ARArvind = require("./Images/client/ARArvind.jpg");
export const IBM = require("./Images/client/logo/IBM.png");

export const CSRBanner = require("./Images/Slider/slider2.jpg");

export const VideoShowreel = require("./Video/Showreel.mp4");
export const AboutBanner = require("./Images/about_banner.webp");

export const WorldMap = require("./Images/WorldMap.svg");

export const CSR1 = require("./Images/CSR/NEPAL_EARTHQUAKE.jpg");
export const CSR2 = require("./Images/CSR/Pulwama.jpg");
export const CSR3 = require("./Images/CSR/girl.jpg");
export const dot = require("./Images/dot.png");

// Banner
export const TeamBanner = require("./Images/Banner/Team.jpg");
export const CustomerBanner = require("./Images/Banner/Customer.jpg");
export const Customer1 = require("./Images/customer-1.jpg");
export const CSRICON = require("./Images/CSR/csr.png");
export const career1 = require("./Images/career1.jpg");
export const Sales = require("./Images/sales1.png");

export const Story1 = require("./Images/story1.png");
export const WhatsAppImage = require("../../src/assets/Images/WhatsAppIcon.png");



export const Certi1 = require("./Images/Certificate/certificate1.png");
export const Certi2 = require("./Images/Certificate/certificate2.png");

export const CoreBanner = require("./Images/Banner/core-banner.jpg");
export const AboutBanner2 = require("./Images/Banner/about-banner.jpg");
export const StoryBanner = require("./Images/Banner/story-banner.jpg");


