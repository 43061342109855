import React, { useEffect, useState } from "react";

import { career1 } from "../../assets";
import PageLayout from "../../Components/PageLayout";
import "./Career-Opening.scss";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { api } from "./../../helper/api";

const CareerOpening = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [filter, setFilter] = useState([]);
  const [careerCat, setCareerCat] = useState([]);
  const [careerLoc, setCareerLoc] = useState([]);

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const loadCareerData = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: {
            fetch_all_career: true,
            location: filter?.location ?? "",
            experience: filter?.experience ?? "",
            category: filter?.category ?? "",
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        if (response.status === 200) {
          setloading(true);
          setData(jsonData.data);
        }
      } catch (err) {
        console.log(err.response);
      }
    };
    const loadCareerCat = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: {
            fetch_career_cat: true,
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        if (response.status === 200) {
          setCareerCat(jsonData.data);
        }
      } catch (err) {
        console.log(err.response);
      }
    };
    const loadCareerLoc = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: {
            fetch_career_loc: true,
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        if (response.status === 200) {
          setCareerLoc(jsonData.data);
        }
      } catch (err) {
        console.log(err.response);
      }
    };
    Promise.all([loadCareerData(), loadCareerCat(), loadCareerLoc()]);
  }, [filter]);

  return (
<PageLayout title="Career | Change the future with us – Radical Minds Technologies Pvt. Ltd" className="career-page" metaDescription={"Explore career opportunities and know more about our company culture and benefits on our career page."}>
      <BannerImage
        BannerTitle={["Current openings at Radical  Mind"]}
        PageName="Career"
        Image={career1}
      />
      <section className="section-space contact_form">
        <div className="container position-relative">
          <div className="career_header">
            <div className="career_filter_section">
              <p>Fliter By :</p>
              <select
                className="form-select  career_filter career_location mx-2"
                name="location"
                onChange={handleChange}
              >
                 {careerLoc.length > 0 ? (
                  <>
                    <option selected value="">
                      All Job Location
                    </option>
                    {careerLoc.map((cat, id) => (
                      <option value={cat.id} key={id}>
                        {cat.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <>
                    <option selected>Job Location Not avaliable</option>
                  </>
                )}
              </select>
              <select
                className="form-select mx-2"
                name="category"
                id="category"
                onChange={handleChange}
              >
                {careerCat.length > 0 ? (
                  <>
                    <option selected value="">
                      All Job Category
                    </option>
                    {careerCat.map((cat, id) => (
                      <option value={cat.id} key={id}>
                        {cat.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <>
                    <option selected>Job Category Not avaliable</option>
                  </>
                )}
              </select>
              <select
                className="form-select  career_filter career_location mx-2"
                name="experience"
                onChange={handleChange}
              >
                <option selected value="">
                  All Job Experience
                </option>
                <option defaultValue="Fresher">Fresher</option>
                <option defaultValue="1+ years">1+ year</option>
                <option defaultValue="1-2 years">1-5 years</option>
                <option defaultValue="5-10 years">5-10 years</option>
                <option defaultValue="10+ years">10+ years</option>
              </select>
            </div>
          </div>
          <div className="row">
            {loading ? (
              data.length > 1 ? (
                <>
                  {data.map((job, idx) => (
                    <div className="col-md-6 col-12">
                      <div className="jobop__container">
                        <div>
                          <h5 className="job__title">{job.title}</h5>
                          <p className="countrys">{job.location_name}</p>
                          <p className="job-type">
                            <span className="lbl">Job Type - </span>
                            <span className="type-value">
                             Full Time
                            </span>
                          </p>
                          <p className="exp-level">
                            <span className="lbl">Experience level -</span>
                            <span className="type-value">{job.experience}</span>
                          </p>
                          <p className="exp-level">
                            <span className="job__title"><b>Location - </b></span>
                            <span className="job__title"><b>{job.location}</b></span>
                          </p>
                        </div>
                        <div className="appy-action">
                          <div className="block-r ms-auto">
                            <div className="btn-wrap">
                              <Link
                                to={`/career/RMTJOBID000${job.id}`}
                                state={job}
                                className="btn btn-primary btn-r"
                                href="#"
                              >
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="notFound">Vaccany Not avaliable !</div>
              )
            ) : (
              <div className="notFound">Loading.....</div>
            )}
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default CareerOpening;
