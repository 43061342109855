import React, { useEffect, useState } from "react";
import PageLayout from "../../Components/PageLayout";
import "./Search.scss";
import { CSRBanner } from "../../assets";
import BannerImage from "./../../Components/BannerImge/BannerImage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "./../../helper/api";

const Search = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [Loading, setLoading] = useState(false);
  const [searchData, setsearchData] = useState([]);

  const searchInput = state?.searchInput ?? "";

  useEffect(() => {
    setLoading(false);
    if (
      searchInput === undefined ||
      searchInput === null ||
      searchInput === ""
    ) {
      navigate("/");
      return;
    }

    const loadSearchData = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: { searchInput: searchInput },
          headers: { "Content-Type": "multipart/form-data" },
        });

        const jsonData = await response.data;
        console.log("Error:::", await response);
        if (response.status === 200) {
          setsearchData(jsonData.data);
          setLoading(true);
        } else {
          setLoading(true);
          setsearchData([]);
        }
      } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response; // take everything but 'request'
        setLoading(true);
        setsearchData([]);

        console.log(errorObject);
        if (errorObject.status === 400 || errorObject.status === 500) {
        }
      }
    };
    loadSearchData();
  }, [navigate, searchInput]);
  console.log(searchData);

  return (
    <PageLayout title="Search">
      <BannerImage
        PageName="Search"
        Image={CSRBanner}
      />
      <div className="container">
        <div className="row media_section">
          {Loading ? (
            searchData.length > 0 ? (
              searchData.map((news, id) => (
                <>
                  {news.sources === "Blog" && (
                    <div className="col-md-6 col-12" key={id}>
                      <div className="article pt-50 pb-50 bdb-707070-op30p">
                        <p className="d-f article_bradcamb">
                          <span>{news.sources}</span>
                        </p>
                        <h2 className="mt-2">{news.title}</h2>
                        <Link
                          to={`/blog/BLOG000${news.id}`}
                          state={news}
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  )}
                  {news.sources === "Media" && (
                    <div className="col-md-6 col-12" key={id}>
                      <div className="article pt-50 pb-50 bdb-707070-op30p">
                        <p className="d-f article_bradcamb">
                          <span>{news.sources}</span>
                        </p>
                        <h2 className="mt-2">{news.title}</h2>
                        <Link
                          to={`/media/MEDIA000${news.id}`}
                          state={news}
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  )}
                  {news.sources === "Press Release" && (
                    <div className="col-md-6 col-12" key={id}>
                      <div className="article pt-50 pb-50 bdb-707070-op30p">
                        <p className="d-f article_bradcamb">
                          <span>{news.sources}</span>
                        </p>
                        <h2 className="mt-2">{news.title}</h2>
                        <a
                          href={news.link}
                          target="_blank"
                          rel="noreferrer"
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : (
              <>Serach not Found</>
            )
          ) : (
            <>Seraching....</>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Search;
