import { Stuti, RP, Rajat,Abhishek, Sachin,RMMD } from "../assets";

export const teamData = [
	{
		key: 11,
		name: "Sanjoo Sharma",
		designaton: "Founder & MD",
		img: RMMD,
		bio: [
			"Sanjoo often regarded as “Man on the move” have contributed relentlessly to the Outsourcing Industry in India”. After completion of 23 years in the IT industry holding various senior leadership roles in many fortune 500 companies like HSBC, HP, PeopleSoft, Infogain etc., Sanjoo ventured into the outsourcing industry in the year 2010. Since then, as a young Entrepreneur, Sanjoo has spread his wings to various other industry segments incl. IT Services, IT Products, Optic Fibre Manufacturing, Publications etc. With his pioneering effort Sanjoo has created over 25000 new jobs in India and believed to have indirectly enabled employment of over 2,00,000 people till date with more to come. Due to these significant contributions towards the IT & BPO industry, Sanjoo has been awarded various prestigious awards from the industry like National Achievers Award by Zee Digital in 2022, Distinguished Alumni award by IMT Ghaziabad 2021, Business Leader of the Year 2020 among many others.",
			<br />,<br />,
			"A MBA in IT and Graduate Engineer in IT, Sanjoo is a passionate technocrat who constantly strives to bring difference in the current trends of service offerings and customer experience. His in-depth hold on IT and keenness to technological advancements and innovations in new technologies, Sanjoo believes is the key to enhance Customer engagement to the next level. Sanjoo is felicitated with many industry awards for his contributions towards IT and Business community. With vast experience in Finance and General management, Strategy development, Sanjoo is also a seasoned professional in Stake Holder management, Investor management, Employee management, M&A etc. and has been featured a number of times in leading Publications and TV Channels.",
		
		],
		linkedin: "#",
	},
	{
		key: 1,
		name: "Stuti Gaekwad", 
		designaton: "Co-Founder & Executive Director",
		img: Stuti,
		bio:[
			"Stuti Gaekwad, Co-Founder and Executive Director at Radical Minds Technologies, boasts an impressive tenure exceeding two decades in Operations and Business Development. With her hands-on approach, she excels in diagnosing client needs and skillfully negotiating outsourcing contracts across IT Infrastructure, Business Process Outsourcing services, Client Services, and Project Management.",
<br />,<br />,
			"A pivotal figure since the inception of Radical Minds, Stuti has been instrumental in establishing and expanding the organization’s core businesses, focusing particularly on domestic clients. She spearheads all client-facing divisions, including Sales & Marketing and Client Management, across the company’s various business lines. Her leadership is pivotal in driving the organic growth of the company, fostering a culture of proactive business development, and providing strategic executive leadership.",
<br />,
			"Prior to her current role, Stuti enriched her portfolio with significant positions at NIIT Technologies & Astra Business Services, where she managed diverse departments such as Operations, Customer Services, Credit & Collection, and Sales. Her portfolio included major clients like Collect America, HSBC, Financial Control Services, Central Credit Services, and Data Locator Group.",
<br />,
			"Stuti holds both bachelor’s and master’s degrees, complemented by professional certifications such as FDCPA & ISO 27001, underscoring her comprehensive expertise and commitment to excellence in her field.",
		],
		
		linkedin: "#",
	},

	

	{
		key: 2,
		name: "RP RATH",
		designaton: "Chief Technology Officer",
		img: RP,
		bio: [
			"RP has over 25+ years of distinguished IT Infrastructure management experience in Domestic & International Call Centres. Prior to joining Radical Minds, RP worked with Quatrro for 12+ years and his last role was SVP-Technologies before that was working with Wipro Spectramind from inception. RP was responsible to manage Global IT and Information Security Operations at Quatrro. He has extensive experience in Digital Automation, Product Engineering, Service Delivery, IT Infrastructure, and Information Security planning, execution, and sustenance in Banking and Financial Services, Consumer IT Solutions and Products, Network Operating Centres, and Other IT Enabled Services among others. ",
			"He has received a great deal of industry-wide recognition during his careers like CIO 100 Award, Green Crusader Award, Security SUPREMO Award, CIO 100-Insight Award, CISO 100 Award, Digital Maestro Award, CIO 100 Power List, Eminent CIO of India Award and NetApp-Innovation Award, etc. Last year he was felicitated with the CIO-HALL OF FAME award. ",
			"RP also represents as Vice President of OSPAI (Other Service Providers Association of India (a non-profitable Industry body that provides support to industries working closely with DOT and TRAI in defining telecom guidelines and framework). ",
			"RP is a certified Microsoft, HP, Dell, Novell, and Cloud technologies professional and a certified ISO lead auditor. He is a graduate of Electronics & Communication Engineering from the Institute of Engineers- Calcutta.",
		],
		linkedin: "#",
	},

	{
		key: 3,
		name: "Rajat Khare",
		designaton: "Head-Products & Services",
		img: Rajat,
		bio: [
			"Rajat is an innovative and results-driven professional with a proven track record of more than 22 years in spearheading digital transformation projects that elevate operational efficiency and drive business success.",<br />,
			"He specializes in the realms of IIoT (Industrial Internet of Things), Digital Twins, Data Analytics, Business Intelligence, and Generative AI. With a wealth of expertise, he excel in crafting innovative solutions that harness the power of these technologies to drive operational excellence and business success. His proficiency lies in leveraging cutting-edge tools to transform data into actionable insights, ultimately steering organizations towards intelligent decision-making and strategic advancements.",<br />,
			"With a keen understanding of industry trends and a robust network, he effectively leverage industry connections to secure strategic partnerships and business opportunities, contributing significantly to revenue growth. His adept negotiation skills and ability to align organizational goals with market demands enable him to cultivate lucrative relationships and drive sustained financial success for the organization.",<br />,
		    "His efforts are also dedicated to working collaboratively on margin improvement initiatives, identifying opportunities for efficiency, and implementing measures to enhance overall profitability. Through fostering strong relationships with teams across departments, he seamlessly facilitate collaboration, ensuring cohesive efforts that deliver optimal results for both the organization and customers. His interpersonal skills and genuine connections empower teams to work synergistically, fostering a culture of shared goals and streamlined operations.",<br />,
		    "He excel in a diverse set of technologies, including Cloud and Microservices, GenAI, DevOps, Datalake, Big Data, and Advanced Analytics with AI-ML. His proficiency shines through when navigating complex scenarios, allowing him to seamlessly integrate these cutting-edge solutions to meet the unique demands of the situation, ensuring optimal performance and innovation.",<br />,
		    "Prior to Radical Minds, Rajat served in senior management roles at several Global IT Companies including CGI, Infosys and Saviant. His experience is with a wide gamut of clientele - Energy companies, Real Estate and Construction, Logistics, US Federal, Manufacturing giant, P&C Insurance client, Product Co, and several Multinational banks.",<br />,
		    "Rajat holds a Bachelor’s degree in Engineering and additionally holds certifications - PMP and CSM.",<br />,
			"He is an avid reader, loves long-distance driving, enjoys photography and a spiritual seeker.",<br />,
		
		],
		linkedin: "#",
	},
	{
		key: 4,
		name: "Abhishek Sobti",
		designaton: "Chief Sales Officer",
		img: Abhishek,
		bio: [
			"Abhishek has over 20 years of experience in the areas of B2B & B2C Sales, New Business Development & Client Relations, Bid Management and Solution Development for ITeS RFPs and Service Delivery Operations with inclination towards various Technology Solutions includes AI & NLP Solutions. ",<br />,
			"He has an extensive experience of solutioning and leading end-to-end process for the large deals & RFP's includes creating Solutions & Commercial Models across various following verticals includes Banking, Telecom, Travel, e-Commerce and Govt./PSU’s. ",<br />,
			"Prior to joining us Abhishek was Head of Sales for Tech Mahindra Business Process Services (TechM BPS) India, BPO arm of Tech Mahindra. Before Tech Mahindra, he has lead efforts to provide Next Gen Digital CX & Back Office services across multiple industries, which include Communication, Media & Entertainment, Retail & CPG, Healthcare & Life Science, Banking & Financial Services, Travel & Hospitality while working with  Intelenet Global Services, EOS Globe and KocharTech. ",<br />,
			"Abhishek is a Post Graduate from MICA in Digital Marketing & Communication.",
	
		],
		linkedin: "#",
	},
	{
		key: 5,
		name: "Sachin Rastogi",
		designaton: "Chief Financial Officer",
		img: Sachin,
		bio: [
			"Sachin with over 20 years of experience, is a member of the executive leadership team and CFO at Radical Mind, driving growth with efficient capital allocation. He comes with over twenty years of corporate finance experience, Finance & Accounts, Statutory & Internal audits, Financial and Regulatory reporting, Financial controllership, Taxation, Payment Gateway, Finance transformation process, Budgeting & forecasting. ",
			"Sachin is a finance leader with global experience with an executed history of business partnerships to accelerate growth and improve business performance. Before Radical Mind, he worked in a leadership position with IBIBO, Happy, and Menarini. ",
			"Sachin has a Master's Degree in Finance from IMT, Ghaziabad and is IFRS certified from Dun & Bradstreet.",
		],
		linkedin: "#",
	}
	
];
