import React, { useState } from "react";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import "./Product.scss";
import { useParams } from "react-router";
import { ProductsPages } from "./pages/productPages";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { bsfi1, Logi1, travel3, travel2, Logi4, retail1 } from "../../assets";

import Modal from "react-bootstrap/Modal";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import ButtonLoader from "../../Components/UI/ButtonLoader";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { TextField } from "../../Components/InputFeild/InputFeild";
import ReadMore from "../../Components/ReadMore";
import SmallSideBarMobile from "../../Components/smallSideBarMobile";
import SmallSideBar from "../../Components/smallSideBar";
import InquiryForm from "../../Components/inquiryForm";
import { useLocation } from 'react-router-dom';

const Product = () => {
	const location = useLocation();
	const [showForm, setShowForm] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const params = useParams();
	const slag = params.slag;
	
	const page = ProductsPages.find((prd) => prd.slag === slag);
	if (!page) {
		window.location.href = "/404-page-not-found";
	}
	const data = page.pagedata[0];
	// console.log(data);


	const handleCloseForm = () => {
		setShowForm(false);
	};

	return (
		<PageLayout title={data.title} metaDescription={data.metaDescription}>
			<BannerImage
				PageName={data.sectitle}
				BannerTitle={data.BannerTitle}
				Image={data.bannerImg}
			/>
			<ToastContainer />
			<SmallSideBarMobile isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false}/>
			<SmallSideBar  isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false}/>
			<div className="customer-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-5 col-12 pe-md-4 px-3">
							<div className="client-content p-0 d-flex flex-column justify-content-center h-100 pe-lg-4 pe-0 mb-lg-0 mb-5">
								<div>
									<h1 className="section-subheading text-left">
										{data.subtitle}
									</h1>
								</div>
								<p className={`${!isOpen && "max-length-5"}`}>
									{data && data.section[1].para}
								</p>
								<a
									href="#!"
									className="readMoreBtn"
									onClick={(e) => {
										e.preventDefault();
										setIsOpen((prevState) => !prevState);
									}}
								>
									{isOpen ? "Read Less" : "Read More"}
								</a>
							</div>
						</div>
						<div className="col-md-7 col-12 d-flex align-items-center justify-content-center">
							<div className="article_img">
								<img
									src={data.section[1].mobImg}
									className="img-fluid rounde-img shadow-img w-100 h-100"
									alt={data.section[1].alt}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{data.benfits && (
				<div className="rmt-abt bg-gray">
					<div className="container">
						<div className="w-1000 mx-auto">
							<h2 className="text-center section-subheading">
								Benefits of {data.subtitle}
							</h2>
						</div>
						<div className="row rmt-row">
							<div className="col-md-1"></div>
							<div className="col-md-10 col-12">
								<div className="row">
									{data.benfits.map((lists, id) => (
										<div
											className="col-md-4 col-12"
											key={id}
										>
											<ul className="rmt-tick-card-container">
												{lists.list.map((li, i) => (
													<li>
														<span
															className="icon i-orange"
															key={i}
														>
															<BsFillArrowRightCircleFill />
														</span>
														<h5>{li.title}</h5>
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
							</div>
							<div className="col-md-1"></div>
						</div>
					</div>
				</div>
			)}
			<div className="">
				<div className="container d-flex flex-column">
					<h2 className="section-heading text-center mb-2">
						Trusted by 120+ Customers across globe
					</h2>
					{/* <Swiper
						grabCursor={true}
						loop={true}
						loopFillGroupWithBlank={true}
						modules={[Autoplay, Navigation]}
						className="mySwiper logo_slider"
						autoplay={{
							delay: 1500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							0: {
								slidesPerView: 2,
								spaceBetween: 5,
							},
							786: {
								width: "150px",
								slidesPerView: 2,
								spaceBetween: 15,
							},
							1024: {
								slidesPerView: 8,
								spaceBetween: 15,
							},
						}}
					>
						{Brandlogo.map((logo, index) => (
							<SwiperSlide className="logo_block" key={index}>
								<img src={logo} alt="" className="img-fluid" />
							</SwiperSlide>
						))}
					</Swiper> */}
					<button
						className="btn btn-primary btn-r mx-auto"
						onClick={() => setShowForm(true)}
					>
						Request a Demo Now!
					</button>
				</div>
			</div>
			{showForm && <InquiryForm handleClose={handleCloseForm} />}

		</PageLayout>
	);
};

export default Product;
