import React from "react";
import { Link } from "react-router-dom";

import PageLayout from "../../Components/PageLayout";
import "./ErrorPage.scss";
const ErrorPage = () => {
  return (
    <PageLayout title="404 Page Not Found | RMT">
      <div className="error_wrapper">
        <div className="d-flex flex-column align-items-center">
          <h2>404</h2>
          <p>Page Not Found</p>
		  <Link to="/" className="btn btn-primary">Back to Home</Link>
        </div>
      </div>
    </PageLayout>
  );
};

export default ErrorPage;
