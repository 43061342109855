import React from "react";
import { Certi1, Certi2 } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";

import PageLayout from "../../Components/PageLayout";
import "./Our-Certificate.scss";
const OurCertificate = () => {
	return (
		<PageLayout
			title="Certificates | Accreditations | Radical Minds Certificate – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Check out our certificates and accreditations, showcasing our dedication to quality and industry standards."
			}
		>
			<div className="ourvalue_page">
				<BannerImage
					Image="./assets/banner/certificate.jpg"
					PageName={"Our Certificate"}
					BannerTitle={"Radical Minds Certificate"}
				/>
				<div className="container">
					<div className="main_certification_container">
						{/* <div className="row">
							<div className="col-md-6 col-12">
								<img
									src={Certi1}
									className="img-fluid"
									alt=""
									 
								/>
							</div>
							<div className="col-md-6 col-12">
								<img
									src={Certi2}
									className="img-fluid"
									alt=""
									 
								/>
							</div>
						</div> */}
						<div className="main_certification_data_div top">
							<div className="certification_img_div right">
								<img
									src={Certi1}
									alt="Industries"
									className="img-fluid"
								/>
							</div>

							<div className="certification_content_div">
								<div className="certification_title_div">
									<h2 className="title">
										ISO/IEC 27001:2013
										<br />
										Certificate No: 21RQ08AB
									</h2>
								</div>
								<div className="certification_content">
									<p>
										Radical Minds has been independently
										assessed and is compliant with the
										requirements of ISO/IEC 27001:2013
										<br />
										This Certificate is applicable to the
										following product or service ranges:
									</p>
									<p>
										INFORMATION TECHNOLOGY ENABLED
										SERVICES/BUSINESS PROCESS
										OUTSOURCING/BUSINESS PROCESS MANAGEMENT/
										CONTACT CENTER
									</p>
									<p>
										Date of this Certificate, 01 September
										2018
										<br />
									</p>
								</div>
							</div>
						</div>
						<div className="main_certification_data_div top left">
							<div className="certification_content_div left">
								<div className="certification_title_div">
									<h2 className="title">
										ISO/IEC 27001:2013
										<br />
										Certificate No: 21RQ08AB
									</h2>
								</div>
								<div className="certification_content">
									<p>
										Radical Minds has been independently
										assessed and is compliant with the
										requirements of ISO/IEC 27001:2013
										<br />
										This Certificate is applicable to the
										following product or service ranges:
									</p>
									<p>
										INFORMATION TECHNOLOGY ENABLED
										SERVICES/BUSINESS PROCESS
										OUTSOURCING/BUSINESS PROCESS MANAGEMENT/
										CONTACT CENTER
									</p>
									<p>
										Date of this Certificate, 01 September
										2018
										<br />
									</p>
								</div>
							</div>
							<div className="certification_img_div left">
								<img
									src={Certi2}
									alt="Industries"
									className="img-fluid"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default OurCertificate;
