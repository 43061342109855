import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { FiChevronDown } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";

const MobileNavTab = ({ items, CloseMenu = () => {}, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("touchstart", handler);
    document.addEventListener("mousedown", handler);

    return () => {
      document.addEventListener("touchstart", handler);
      document.addEventListener("mousedown", handler);
    };
  }, [dropdown]);

  const toggleDrop = () => {
    setDropdown((prev) => !prev);
  };
  return (
    <li
      className={`nav-item ${
        items.submenu || items.megamenu ? "dropdown" : ""
      }`}
      onClick={() => toggleDrop}
      ref={ref}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? true : false}
            className={`nav-link single ${dropdown ? "hover" : ""}`}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}
            <span className="nav-icon">
              <FiChevronDown />
            </span>
          </button>
          <NestedTab
            depthlevel={depthLevel}
            submenu={items.submenu}
            dropdown={dropdown}
            isMegaMenu={false}
          />
        </>
      ) : items.megamenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? true : false}
            className={`nav-link ${dropdown ? "hover" : ""}`}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}
            <span className={`nav-icon ${dropdown ? "hover" : ""}`}>
              <FiChevronDown />
            </span>
          </button>
          <Dropdown
            depthlevel={depthLevel}
            submenu={items.submenu}
            dropdown={dropdown}
            isMegaMenu={items.megamenu}
            isclassName={items.class}
          />
        </>
      ) : (
        <a href={items.link} className="nav-link ss" onClick={CloseMenu}>
          <span className="icon">
            <BiChevronRight />
          </span>{" "}
          {items.title}
        </a>
      )}
    </li>
  );
};

export default MobileNavTab;

export function NestedTab({ submenu, dropdown = false, depthlevel }) {
  const [drop, setDrop] = useState(dropdown);

  useEffect(() => {
    setDrop(dropdown);
  }, [dropdown]);
  depthlevel = depthlevel + 1;
  return (
    <>
      <ul
        className={`mobile_menu  droplevel_${depthlevel} ${
          drop ? "active" : ""
        }`}
      >
        <li
          className="w-100 d-flex backBtn"
          onClick={() => setDrop((prev) => !prev)}
        >
          {"<"} BACK
        </li>
        {submenu.map((menu, index) => {
          return (
            <MobileNavTab items={menu} key={index} depthLevel={depthlevel} />
          );
        })}
      </ul>
    </>
  );
}
