import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { FiChevronDown } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";

const MenuItems = ({ items, depthLevel }) => {
	const [dropdown, setDropdown] = useState(false);
	let ref = useRef();
	// let button = useRef(null);
	useEffect(() => {
		const handler = (e) => {
			if (dropdown && ref.current && !ref.current.contains(e.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener("touchstart", handler);
		document.addEventListener("mousedown", handler);

		return () => {
			document.addEventListener("touchstart", handler);
			document.addEventListener("mousedown", handler);
		};
	}, [dropdown]);

	const onMouseEnter = () => {
		window.innerWidth > 960 && setDropdown(true);
	};
	const onMouseLeave = () => {
		window.innerWidth > 960 && setDropdown(false);
	};

	return (
		<li
			className={`nav-item ${items.submenu ? "dropdown" : ""} ${items.megamenu ? "dropdown-megaMenu" : ""
				}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			ref={ref}>
			{items.submenu ? (
				<>
					<button
						type="button"
						aria-haspopup="menu"
						aria-expanded={dropdown ? true : false}
						className={`nav-link ${dropdown ? "hover" : ""}`}
						onClick={() => setDropdown((prev) => !prev)}>
						{items.title}
						<span className={`nav-icon ${dropdown ? "hover" : ""}`}>
							<FiChevronDown />
						</span>
					</button>
					<Dropdown
						depthlevel={depthLevel}
						submenu={items.submenu}
						dropdown={dropdown}
						isMegaMenu={false}
					/>
				</>
			) : items.megamenu ? (
				<>
					<button
						type="button"
						aria-haspopup="menu"
						aria-expanded={dropdown ? true : false}
						className={`nav-link ${dropdown ? "hover" : ""}`}
						onClick={() => setDropdown((prev) => !prev)}>
						{items.title}
						<span className={`nav-icon ${dropdown ? "hover" : ""}`}>
							<FiChevronDown />
						</span>
					</button>
					<Dropdown
						depthlevel={depthLevel}
						submenu={items.submenu}
						dropdown={dropdown}
						isMegaMenu={items.megamenu}
						isClass={items.class}
					/>
				</>
			) : (
				(items.desc) ?
					(
						<Link to={items.link} className="nav-link mega-drop-v1">
							<span className="icon"><BiChevronRight /></span> {items.title}
							<p>{items.desc}</p>
						</Link>
					) : (
						<Link to={items.link} className="nav-link">
							<span className="icon"><BiChevronRight /></span> {items.title}
						</Link>
					)

			)}
		</li>
	);
};

export default MenuItems;
