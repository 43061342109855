import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./App.scss";
import Contact from "./pages/Contact/Contact";
import Career from "./pages/Career/Career";
import Leadership from "./pages/Leadership/Leadership";
import CSR from "./pages/CSR/CSR";
import About from "./pages/About/About";
import CareerDetails from "./pages/CareerDetails/CareerDetails";
import OurValues from "./pages/Our-Values/Our-Values";
import Search from "./pages/Search/Search";
import Partnership from "./pages/Partnership/Partnership";
//  import OurCustomer from "./pages/Our-Customer/Our-Customer";
import CareerOpening from "./pages/Career-Opening/Career-Opening";
import MediaCenter from "./pages/Media-Center/Media-Center";
import OurCertificate from "./pages/Our-Certificate/Our-Certificate";
import OurStory from "./pages/Our-Story/Our-Story";
import Media from "./pages/Media/Media";
import Award from "./pages/Award/Award";
import PartnershipInformation from "./pages/Partnership-Information/Partnership-Information";
import ContinousInnovation from "./pages/Continous-Innovation/Continous-Innovation";
import ProcessSecurity from "./pages/Process-Security/Process-Security";
import PressRelase from "./pages/Media/Press-Relase";
import Solutions from "./pages/Solutions/Solutions";
import Product from "./pages/Product/Product";
import OrangainsationType from "./pages/Orangainsation-Type/Orangainsation-Type";
import MediaDetails from "./pages/Media/MediaDetails/MediaDetails";
import ScrollToTop from "./Components/ScrollToTop";
import ReadMoreAward from "./pages/Award/ReadMore/Readmore";
import Blog, { BlogDetails } from "./pages/Media/blog";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PrivacyPolicy from "./pages/Policy/privacy-policy";
import CookiePolicy from "./pages/Policy/cookie-policy";
import Thankyou from "./pages/Thankyou/thankyou";
import TermsCondition from "./pages/Policy/termsCondition";
import LandingPage from "./pages/LandingPage/landingPage";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/csr" element={<CSR />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/media-center" element={<MediaCenter />} />
          <Route path="/media" element={<Media />} />
          <Route path="/press-relase" element={<PressRelase />} />
          <Route path="/our-certificate" element={<OurCertificate />} />
          <Route path="/career/:slug" element={<CareerDetails />} />
          <Route path="/career/opening" element={<CareerOpening />} />
          <Route path="/our-vision" element={<OurValues />} />
          <Route path="/our-story" element={<OurStory />} />
          {/* <Route path="/our-customer" element={<OurCustomer />} /> */}
          <Route path="/our-award" element={<Award />} />
          <Route path="/become-partner" element={<Partnership />} />
          <Route
            path="/partnership-information"
            element={<PartnershipInformation />}
          />
          <Route
            path="/continous-innovation"
            element={<ContinousInnovation />}
          />
          <Route path="/industries/:slag" element={<Solutions />} />
          <Route path="/services/:slag" element={<Product />} />
          <Route
            path="/organization-type/:slag"
            element={<OrangainsationType />}
          />
          <Route path="/process-security" element={<ProcessSecurity />} />
          <Route path="/media/:slag" element={<MediaDetails />} />
          <Route path="/award/:slag" element={<ReadMoreAward />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slag" element={<BlogDetails />} />
          <Route path="/search" element={<Search />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/services/contact-center-solution" element={<LandingPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
