import axios from "axios";

let URL = "";

// console.log(window.location.origin);

switch (window.location.origin) { 

	case "https://radicalminds.in":
		URL = "https://radicalminds.in/api/";
		break;
	case "https://rmt.incincmedia.com/":
		URL = "https://rmt.incincmedia.com/api/";
		break;
		
		case "http://localhost:3000":
		// URL = "https://rmt.incincmedia.com/api/";
		URL = "https://radicalminds.in/api/";
		break;

	default:
		URL = "/api/";
		break;
}

export const api = axios.create({
	baseURL: URL,
});

console.log(URL)

api.interceptors.response.use(
	(response) => response,
	(error) => {
		// const { status } = error.response;
		if (error.response.status === 401) {
		}
		return Promise.reject(error);
	}
);
