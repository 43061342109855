import React from "react";
import { TeamBanner } from "../../assets";
import PageLayout from "../../Components/PageLayout";
import "./Leadership.scss";
import TeamCard from "../../Components/TeamCardModel/TeamCard";
import { teamData } from "../../data/TeamData";
import BannerImage from "../../Components/BannerImge/BannerImage";



const Leadership = () => {
	const firstRow = teamData.slice(0, 2);
	const remainingRows = [...Array(Math.ceil((teamData.length - 3) / 4))].map(
	  (row, idx) => teamData.slice(idx * 4 + 2, idx * 4 + 7)
	);
  
	const content = [firstRow, ...remainingRows].map((row, idx) => (
	  <div
		style={idx === 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' } : {}}
		className="row p-relative"
		key={idx}
	  >
		{row.map((team) => (
		  <TeamCard teams={team} key={team.id} />
		))}
	  </div>
	));
  
	return (
	  <PageLayout
		title="Meet Our Leaders | Team of innovators and dreamers – Radical Minds Technologies Pvt. Ltd"
		metaDescription={
		  "Introducing our leaders, who worked extremely hard and dedicatedly to help the organization achieve this position."
		}
		className="contact-page"
	  >
		<BannerImage
		  Image={TeamBanner}
		  PageName={"Meet our Leaders"}
		  BannerTitle={["Team of innovators and dreamers"]}
		/>
		<section className="section-space contact_form">
		  <div className="container position-relative">
			<h2 className="section-heading text-center">Meet Our Leaders</h2>
  
			<div className="TeamData-section">{content}</div>
		  </div>
		</section>
	  </PageLayout>
	);
  };
  
  

export default Leadership;
