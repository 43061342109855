import React, { useState } from 'react';

export const TextField = ({
  type = "text",
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  loading = false,
  columns,
  multiline,
  accept,
  onChange = () => {},
  onClick = () => {},
  isValidated = false,
}) => {
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;

    // Allow only letters and spaces for names
    if (id === 'firstname' || id === 'lastname') {
      if (/^[a-zA-Z ]*$/.test(value)) {
        setError('');
        onChange(e); // Pass the event up to the parent component
      } else {
        setError('Only alphabet are allowed');
        const cleanedValue = value.replace(/[^a-zA-Z ]/g, '');
        e.target.value = cleanedValue; // Update the input value
        onChange(e);
      }
    } else {
      onChange(e); // Pass the event up to the parent component
    }
  };

  return (
    <div
      className={`form-group${className ? " " + className : ""}`}
      onClick={onClick}
    >
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
          {required ? <span className="text-danger ms-1">*</span> : null}
        </label>
      ) : null}

      {multiline ? (
        <textarea
          type={type}
          className="form-control"
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          defaultValue={defaultValue ?? ""}
          disabled={loading}
          rows={multiline}
        ></textarea>
      ) : type === "file" ? (
        <input
          accept={accept}
          type={type}
          className="form-control"
          name={id}
          id={id}
          onChange={handleChange}
          disabled={loading}
        />
      ) : (
        <input
          type={type}
          className="form-control"
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          defaultValue={defaultValue ?? ""}
          disabled={loading}
        />
      )}

      {error && (
        <div className="invalid-feedback d-block">
          {error}
        </div>
      )}
    </div>
  );
};


export const InputFieldButton = ({
  type = "text",
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  loading = false,
  onChange = () => {},
  onBtnCick = () => {},
  isValidated = false,
}) => {
  return (
    <div>
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
          {required ? <span className="text-danger ms-1">*</span> : null}
        </label>
      ) : null}
      <div className={`input-group${className ? " " + className : ""}`}>
        <input
          type={type}
          // required={required}
          className="form-control"
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          defaultValue={defaultValue ?? ""}
          disabled={loading}
        />
        <button className="locationbtn" type="button" onClick={onBtnCick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="36.726"
            viewBox="0 0 30 36.726"
          >
            <g
              id="Group_2181"
              data-name="Group 2181"
              transform="translate(-348 -1382.274)"
            >
              <g id="Group_3134" data-name="Group 3134">
                <text
                  id="Detect"
                  transform="translate(348 1416)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="SegoeUI, Segoe UI"
                  letterSpacing="0.02em"
                >
                  <tspan x="0" y="0">
                    Detect
                  </tspan>
                </text>
                <path
                  id="Path_3928"
                  data-name="Path 3928"
                  d="M92.684,0a7.352,7.352,0,0,0-6.469,10.845l6.067,10.972a.46.46,0,0,0,.8,0l6.069-10.976A7.352,7.352,0,0,0,92.684,0Zm0,11.027A3.676,3.676,0,1,1,96.36,7.351,3.68,3.68,0,0,1,92.684,11.027Z"
                  transform="translate(270.668 1382.274)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>

      {required && label && isValidated && !defaultValue ? (
        <p className="form-error-msg">
          Please enter {label.toLowerCase()}{" "}
        </p>
      ) : null}
    </div>
  );
};

export const SelectField = ({
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  options = [],
  loading = false,
  columns,
  onChange = () => {},
  onClick = () => {},
  isValidated = false,
}) => {
  return (
    <div className={`col-12 mb-4${columns ? " " + columns : ""}`}>
      <div
        className={`form-group${className ? " " + className : ""}`}
        onClick={onClick}
      >
        {label ? (
          <label htmlFor={id} className="form-label">
            {label}
            {required ? <span className="text-danger ms-1">*</span> : null}
          </label>
        ) : null}
        <select
          // required={required}
          className="form-select"
          name={id}
          id={id}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e)}
          disabled={loading}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}

          {options.length > 0
            ? options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                );
              })
            : null}
        </select>

        {required && label && isValidated && !defaultValue ? (
          <p className="form-error-msg">
            Please select {label.toLowerCase()}{" "}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export const InputRadioButton = ({
  type = "radio",
  required,
  name,
  id,
  label,
  defaultChecked,
  loading = false,
  onChange = () => {},
  isValidated = false,
  errorMessage = "Please check the required field",
}) => {
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type={type}
          name={name}
          id={id}
          onChange={(e) => onChange(e)}
          disabled={loading}
          defaultChecked={defaultChecked}
          // required={required}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>

      {required && isValidated && !defaultChecked ? (
        <p className="form-error-msg">
          Please enter {errorMessage.toLowerCase()}{" "}
        </p>
      ) : null}
    </>
  );
};
