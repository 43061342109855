import React from "react";
import { Link } from "react-router-dom";
import { CSR1, CustomerBanner, RMMD, Customer1 } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";

import PageLayout from "../../Components/PageLayout";
import "./Media-Center.scss";
const MediaCenter = () => {
	return (
		<PageLayout
			title="Media Center | Media | Press Release| Awards– Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Explore our curated collection of videos, photos, press releases, and awards on our Media Center page."
			}
		>
			<div className="ourvalue_page">
				<BannerImage
					Image="./assets/banner/media-center.jpg"
					BannerTitle={"Read all about us"}
					PageName={"Media Center"}
				/>
				<div className="bg-gray fact">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<div className="">
									<img
										src="./assets/image/event.jpg"
										className="img-fluid rounde-img shadow-img"
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-6 col-12 mt-md-0 mt-4">
								<div className="client-content  d-flex flex-column justify-content-center h-100">
									<h2 className="para-heading">
										Welcome to Radical Minds’ Media Center!
									</h2>
									<p className="text-justify m-0">
										Here, we share all the latest updates
										and news about our company. You will
										find our press releases, videos, photos,
										and awards on this page that showcase
										our journey and accomplishments. We take
										pride in our work and are honored to
										have received numerous awards for our
										excellence in the industry. Our press
										releases offer valuable insights into
										our business strategies, partnerships,
										and achievements. Our videos showcase
										our work culture and the services we
										offer to our clients. The photos capture
										the spirit of Radical Minds and the
										various events we participate in. Stay
										tuned to this page for the latest
										updates from Radical Minds.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<h2 className="text-center section-heading">
						Media Resources
					</h2>
					<div className="row">
						<div className="col-md-2 col-12"></div>
						<div className="col-md-8 col-12">
							<div className="row media-kit">
								<div className="col-md-4 col-12 mb-3">
									<Link
										to="/media"
										className="btn btn-primary"
									>
										Media
									</Link>
								</div>
								<div className="col-md-4 col-12 mb-3">
									<Link
										to="/press-relase"
										className="btn btn-primary"
									>
										Press Release
									</Link>
								</div>
								<div className="col-md-4 col-12 mb-3">
									<Link
										to="/our-award"
										className="btn btn-primary"
									>
										Awards
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-2 col-12"></div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default MediaCenter;
