import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cloud from "./image/cloud_contact.png";
import AiEnable from "./image/AI_enabled.png";
import OnkiChannel from "./image/onki_channel.png";
import AutomatedTele from "./image/automated_tele.png";
import CtiSoltion from "./image/CTI_soltion.png";
import CmrIntegration from "./image/CRM_integration.png";
import InboundCall from "./image/inbound_call.png";
import Outbound from "./image/outbound_call.png";
import Blended from "./image/blended_call.png";
import Tech from "./image/tech.png";
import Improved from "./image/improved_cutomer.png";
import TimeSaving from "./image/time_saving.png";
import Global from "./image/global_expansion.png";
import CostSaving from "./image/cost_saving.png";
import Focus from "./image/focus_on_core_business.png";
import Saclability from "./image/saclability.png";
import Competitive from "./image/competitive_advantage.png";
import ImprovedAgent from "./image/improved_agent.png";
import Avation from "./image/aviation.svg";
import Travel from "./image/travel.svg";
import Government from "./image/Government.png";
import Banking from "./image/banking.png";
import Food from "./image/food.png";
import Ecommerce from "./image/e_commerce.png";
import Education from "./image/education.png";
import Healthcare from "./image/healthcare.png";
import Technology from "./image/technology.png";
import Manufacturing from "./image/manufacturing.png";
import Stocks from "./image/stocks.png";
import Insurance from "./image/insurance.png";
import Flexibilty from "./image/flexibilty.png";
import Reliable from "./image/reliable.png";
import Comprehensive from "./image/comprehensive.png";
import Seamless from "./image/seamless.png";
import Customer from "./image/customer.png";
import Market from "./image/best_in_market.png";
import User from "./image/user_friedndly.png";
import Uptime from "./image/uptime_guarantee.png";
import"../../pages/LandingPage/css/landingPageStyle.css"
import "../../pages/LandingPage/LandingPageCSS/FaqSection.css"
import InquiryForm from '../../Components/inquiryForm';
import PageLayout from '../../Components/PageLayout';
import { api } from '../../helper/api';
import { GrAnnounce } from "react-icons/gr";
import SmallSideBar from '../../Components/smallSideBar';
import PolicyPopup from '../../Components/PolicyPopup/PolicyPopup';
import SmallSideBarMobile from '../../Components/smallSideBarMobile';
import Award_1 from '../../pages/LandingPage/image/award3.png';
import Award_2 from './image/award-2.png';
import Award_3 from './image/award-3.png';
import Award_4 from './image/award4.png';
import Award_5 from './image/award-5.png';
import Video from '../../pages/LandingPage/image/home.mp4'
import InquiryFormStandalone from '../../Components/inquiryFormStandalone';

const LandingPage = () => {


    const faqs = [
        {
            question: 'What industries does Radical Minds Technologies serve?',
            answer: 'Radical Minds Technologies serves a wide range of industries, like travel & hospitality, education, telecommunications, finance, healthcare, e-commerce, and manufacturing. Our expertise spans across various sectors, ensuring we can meet your specific industry needs.',
        },
        {
            question: 'How do I get started with Radical Minds Technologies Contact Center as a Service (CCaaS)?',
            answer: 'Getting started is simple. Contact us via the provided link, and one of our representatives will reach out to understand your requirements and design a customized solution tailored to your business.',
        },
        {
            question: 'What sets Radical Minds Technologies apart from other Contact Center as a Service (CCaaS) providers?',
            answer: 'Radical Minds Technologies stands out due to our commitment to excellence, industry expertise, global network, and cutting-edge technology. We don’t just provide services; we become your strategic partner in achieving your business goals.',
        },
        {
            question: 'How cloud-based contact center solution improves customer experience?',
            answer: 'Cloud-based contact centers enable businesses to offer a consistent, reliable, and integrated customer experience across multiple communication channels. They provide advanced analytics and reporting, real-time monitoring, and streamlined workflows, leading to increased agent productivity and faster problem resolution.',
        },
        {
            question: 'What should I consider while choosing right cloud contact center solution vendor?',
            answer: 'While choosing a cloud contact center solution, businesses should consider factors such as scalability, flexibility, reliability, security, ease of use, ability to integrate with various communication channels, advanced analytics and reporting capabilities, and level of customer support. Schedule a call of our Tech Expert.',
        }, {
            question: 'How can I setup cloud contact center?',
            answer: 'Setting up a cloud contact center requires selecting a cloud contact center solution, choosing the desired features, selecting suitable hardware, configuring integrations, and training agents and supervisors to use the platform effectively.',
        },
    ];
    const awardsData = [
        {
            imageSrc: Award_1,
            title: 'Dr. APJ Abdul Kalam Inspiration Award 2024',
            description: 'For Innovation in Contact Center & Software Services Of The Year! The ceremony took place on 29th June 2024 at Bharat Mandapam, New Delhi.'
        },
        {
            imageSrc: Award_2,
            title: 'Women Entrepreneur Award 2024',
            description: 'Awarded by Women Entrepreneur Award 2024 in India’s leading Business Magazine!'
        },
        {
            imageSrc: Award_3,
            title: 'Zee National Award Achievers',
            description: 'National Achievers Awards Winner For Customer Choice In Contact Centre and IT Industry 2022'
        },
        {
            imageSrc: Award_4,
            title: 'The 2019 Global Choice Awards',
            description: 'The 2019 Global Choice Awards from JACKIE SHROFF under Excellence in Service & Leadership categor.'
        },
        {
            imageSrc: Award_5,
            title: 'Business Women Leader',
            description: 'Business Women Leader of the Organization Award at GIWL Awards 2019'
        }
    ];
    const [openIndex, setOpenIndex] = useState(0);
    const [showForm, setShowForm] = useState(false);

    const handleClick = (index) => {
        setOpenIndex(index === openIndex ? -1 : index);
    };
    const handleCloseForm = () => {
        setShowForm(false);
    };
    const [data, setData] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await api({
                    method: "post",
                    url: "fetch",
                    data: { notice: true },
                    headers: { "Content-Type": "multipart/form-data" },
                });
                const jsonData = await response.data;
                // console.log(jsonData.data[0]);
                setData(jsonData.data);
            } catch (err) {
                console.log(err);
            }
            // setData(jsonData.data[0]);
        };

        loadData();
    }, []);
    return (
        <div>
            <PageLayout
                title={
                    "Best Contact Center Solutions | Reliable & Efficient Services"
                }
                metaDescription={"Experience the best in contact center solutions with Radical Minds Technologies. Our services offer unmatched reliability, flexibility, and user-friendly interfaces to boost customer satisfaction."}
            >
                <head>
                    <meta charset="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no"
                    />
                    <title>RMT Engineering</title>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />
                </head>

                <section>
                    <div class="container-fluid">
                        <div class="row banner d-flex myvideo-container">
                            <div class="col-lg-8 banner-content">
                                <div class="banner-content-box">
                                    <h1>Experience the Future with AI-Driven Contact Center Services
                                    </h1>
                                    <h5>
                                        Unlock business efficiency, reduce costs, and achieve
                                        operational excellence with our AI enabled business process
                                        outsourcing service
                                    </h5>
                                    <ul>
                                        <li>200+ Years of Combined Leadership Experience</li>
                                        <li>Voice Support: Inbound & Outbound</li>
                                        <li>Non Voice: Live Chat & Email Support</li>
                                        <li>Back Office Support</li>
                                        <li>Reliable and Secure Technology</li>
                                    </ul>
                                </div>
                            </div>


                            <div className="col-lg-4 Enquire-content">
                                <div class=" w-100 d-flex">
                                    <InquiryFormStandalone />
                                </div>
                            </div>
                        </div>

                        {/* <video class="fullscreen myvideo" src={Video} type="video/mp4"> </video> */}
                        <video className="fullscreen myvideo " loop autoPlay muted >
                            <source src={Video} type="video/mp4" />
                        </video>
                    </div>
                </section>
                <PolicyPopup />


                {data.length > 0 && (
                    <div className="annoucement annoucementtwo">
                        <div className="w-100 marquee">
                        {data.map((d, index) => (
									<>
										<div
											className="text-center marqueeContent"
											key={index}
										>
											<a
												href={d.link}
												target={index === 1 ? "_blank" : "_self"}
												className="nav__banner-link-cms w-inline-block"
												aria-label="Home Notice"
											>
												<div className="nav__banner-icon">
													<GrAnnounce />
												</div>
												<div className="inline">
													<span>{d.title}</span>
												</div>
											</a>
											{index + 1 !== data.length && (
												<span className="sperator">
													|
												</span>
											)}
										</div>
									</>
								))}
                        </div>
                    </div>
                )}

                <SmallSideBar isEmail={true} isWhatsApp={true} isQuote={false} isDemo={false} isSolutions={false} />
                <SmallSideBarMobile isEmail={true} isWhatsApp={true} isQuote={false} isDemo={false} isSolutions={false} />

                <section class="text-center">
                    <div class="container pb-0">
                        <h2 class="bold-h1">
                            Leading the way in Next-Generation Customer Experience Management (CX)

                        </h2>
                        <h3>
                            We provide world class outsourcing solutions for your business growth
                        </h3>

                        <br />

                        <div class="content">
                            <p class="s32">
                                Radical Minds stands as your trusted partner, offering a
                                comprehensive suite of services designed to propel your business
                                towards success. Whether it’s our {" "}
                                <strong>Contact Center as a Service (CCaaS)</strong> providing
                                seamless customer interactions,
                                <strong>CX Enhancement services</strong> optimizing customer
                                experiences, or AI, Analytics, and Digitization services driving
                                innovation and efficiency, we are committed to delivering
                                excellence.
                            </p>
                            <p class="s32">
                                Our relentless pursuit of <strong> customer-centricity</strong>,
                                coupled with advanced technologies and a diverse set of skills,
                                ensures that we exceed your expectations. Join hands with
                                <strong> Radical Minds </strong> to embark on a transformative
                                journey, where innovation meets reliability, and your business
                                achieves new heights of success.
                            </p>
                        </div>
                    </div>
                </section>


                <section id="serviceOursolutions" class="single_service_frame text-center">
                    <div class="container">
                        <h2 class="bold-h1 mb-4">Tailored Solutions to Address Your Business Needs</h2>
                        <div class="row mt-2 justify-content-center">
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS1">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Cloud} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title"> Contact Centre</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our Contact Center is a customer service solution that operates on a cloud computing infrastructure. It provides businesses with the ability to manage and route customer interactions through multiple communication channels such as voice, chat, email, and social media.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS2">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={AiEnable} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">AI, Analytics and Digitization</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our AI, Analytics, and Digitization services at Radical Minds empower businesses with cutting-edge technologies to drive digital transformation and stay ahead in today's dynamic landscape. Through Artificial Intelligence and Automation, we optimize processes, enhance efficiency, and enable intelligent decision-making.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={OnkiChannel} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">
                                            Omni Channel Platform
                                        </h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our  Contact Center services  captures
                                            customer interactions across all the possible channels i.e.
                                            Voice, SMS, Email, Chat  (Mobile or Website
                                            Applications), and Social Media. The platform is deployable
                                            across Cloud, On Premise and
                                            Hybrid environments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={AutomatedTele} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Automated Tele Marketing</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our Automated Telemarketing software can save businesses time and resources while still reaching a large audience. Automated call center services personalize the customer experience by providing relevant information and offering personalized solutions. Additionally, Automated Call Center solutions can allow for quick and efficient follow-up calls to prospects and can also gather data from each call.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={CtiSoltion} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">CTI Solution</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our CTI Solution services can significantly enhance the customer experience and improve the efficiency of call center operations. By providing access to customer information and call controls directly from a computer, CTI Integration services can help customers and agents work more effectively and provide faster, more personalized service to customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={CmrIntegration} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">CRM Integration</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our CRM Integration refers to the process of integrating a
                                            dialer with any CRM system to streamline and
                                            automate customer interactions and
                                            data management . CRM Integration for call
                                            centers allows the seamless transfer of customer data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={InboundCall} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Inbound Call Centre</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our Inbound Call Center service operates 24/7 to ensure that customers can get the help they need whenever they need it. These centers improve the customer experience and reduce the workload on other departments, such as sales and marketing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Outbound} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Outbound Call Centre</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our Outbound Call Center software is equipped with the latest technology and tools to effectively reach and engage with customers, including automatic dialing systems, call monitoring and recording, and customer relationship management software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Blended} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Blended Call Center</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Our Blended Call Center solutions are equipped with advanced technology that allows agents to handle multiple interactions at once, providing customers with quick and efficient support. By offering multiple channels, a Blended Call Center software ensures that customers can reach out at their convenience, regardless of the time of day or their location
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="advantageMainContainer">
                    <h2 class="industry-section bold-h1">
                        Advantages of AI-Driven Contact Center Services
                    </h2>
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Tech}
                                        class="img-fluid"
                                        alt="Technological advantage"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Technological advantage</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Improved}
                                        class="img-fluid"
                                        alt="Improved customer experience"
                                    />
                                    <p class="fw-bold fs-5 ms-2 text-start">Improved customer experience</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={TimeSaving}
                                        class="img-fluid"
                                        alt="Time Saving"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Time Saving</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Global}
                                        class="img-fluid"
                                        alt="Global expansion"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Global expansion</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={CostSaving}
                                        class="img-fluid"
                                        alt="Cost savings"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Cost savings</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Focus}
                                        class="img-fluid"
                                        alt="Focus on core business"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Focus on core business</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Saclability}
                                        class="img-fluid"
                                        alt="Scalability"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Scalability</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={Competitive}
                                        class="img-fluid"
                                        alt="Competitive advantage"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Competitive advantage</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-4">
                                <div class="advantage-item">
                                    <img
                                        src={ImprovedAgent}
                                        class="img-fluid"
                                        alt="Improved agent experience"
                                    />
                                    <p class="fw-bold fs-5 ms-2">Improved agent experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="custom-bg pt-4">
                    <br />
                    <section class="industry-section">
                        <h2 class="bold-h1 headingsection3">
                            Delivering Industry Knowledge
                        </h2>
                        <h5 class="headingsection3">
                            We recognize that every industry, customer, and interaction is unique. Our expert team leverages their <br></br>creativity and industry expertise to address your business challenges effectively.

                        </h5>
                    </section>
                    <div class="container mt-5 pb-0" >
                        <div class="row">
                            <div class="col-lg-3 col-md-6 mb-4" >
                                <div class="card card_x h-100">
                                    <img class="card-img-top" src={Avation} alt="card" />
                                    <div class="card-body">
                                        <h5 class="card-title">Aviation</h5>
                                        <p class="card-text">
                                            Flying High with Operational Excellence and Efficiency
                                        </p>
                                        <a href="/industries/travel-and-hospitality" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img class="card-img-top" src={Travel} alt="card" />
                                    <div class="card-body">
                                        <h5 class="card-title">Travel & Hospitality</h5>
                                        <p class="card-text">
                                            Guest Experiences with Expert Contact Center as a Service
                                            (CCaaS)
                                        </p>
                                        <a href="/industries/travel-and-hospitality" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Government}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Government</h5>
                                        <p class="card-text">
                                            Efficient Solutions for Effective Public Service
                                        </p>
                                        <a href="/industries/government" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img class="card-img-top" src={Banking} alt="card" />
                                    <div class="card-body">
                                        <h5 class="card-title">Banking and Financial</h5>
                                        <p class="card-text">
                                            Empowering Financial Institutions with Contact Center as a
                                            Service (CCaaS)
                                        </p>
                                        <a href="/industries/financial-services" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img class="card-img-top" src={Food} alt="card" />
                                    <div class="card-body">
                                        <h5 class="card-title">Food and Logistics</h5>
                                        <p class="card-text">
                                            Serving Up Efficiency in Food and Logistics
                                        </p>
                                        <a href="#" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Ecommerce}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">E-commerce</h5>
                                        <p class="card-text">
                                            Boosting E commerce Success through Seamless Contact Center as
                                            a Service (CCaaS)
                                        </p>
                                        <a href="#" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Education}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Education</h5>
                                        <p class="card-text">
                                            Elevating Education Support with Expert Contact Center as a
                                            Service (CCaaS) Services.
                                        </p>
                                        <a href="/industries/education" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Healthcare}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Healthcare</h5>
                                        <p class="card-text">
                                            Ensuring Healthier Outcomes with Contact Center as a Service
                                            (CCaaS) Expertise
                                        </p>
                                        <a href="/industries/healthcare" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Technology}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Technology</h5>
                                        <p class="card-text">
                                            Innovating Technology Services with Radical Minds
                                        </p>
                                        <a href="#" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Manufacturing}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Manufacturing</h5>
                                        <p class="card-text">
                                            Streamlining Operations for Manufacturing Excellence
                                        </p>
                                        <a href="#" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img class="card-img-top" src={Stocks} alt="card" />
                                    <div class="card-body">
                                        <h5 class="card-title">Stocks & Capital market</h5>
                                        <p class="card-text">
                                            Optimizing Market Operations with Radical Minds
                                        </p>
                                        <a href="/industries/capital-market" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="card card_x h-100">
                                    <img
                                        class="card-img-top"
                                        src={Insurance}
                                        alt="card"
                                    />
                                    <div class="card-body">
                                        <h5 class="card-title">Insurance </h5>
                                        <p class="card-text">
                                            Claims, Customer Care, and More Redefining Insurance 
                                        </p>
                                        <a href="#" class="bold-link">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="serviceOursolutions" class="single_service_frame">
                    <div class="container">
                        <br />
                        <div class="row pt-4 justify-content-center">
                            <section class="choose-us-section">
                                <h2 class="bold-h1">
                                    Why Choose Us?
                                </h2>
                                <h3>End-to-end CX support partner, empowering businesses to</h3>
                                <h4>
                                    modernize processes, elevate efficiency, and transform
                                    experiences.
                                </h4>
                            </section>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS1">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Flexibilty} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Flexibility and Control</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies provides contact center services
                                            with unmatched flexibility and control. Tailor your solutions
                                            to fit your needs and ensure superior customer support.
                                            Experience the difference with our  adaptable services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS2">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Reliable} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Reliable and Secure</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Experience reliable and secure contact center services with
                                            Radical Minds Technologies. Our advanced solutions ensure
                                            efficient customer interactions , robust data protection, and
                                            seamless scalability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Comprehensive} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Comprehensive Reporting</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Extensive Reporting and Analytics arm to enable
                                            data driven decision making, business forecasting , trend
                                            analysisand more. The rich dashboard provides the key indicators of
                                            operations and dynamically decide the right course of action.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Seamless} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Seamless Integrations</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies offers seamless integrations for
                                            contact center services. Enhance your operations with our
                                            advanced solutions, ensuring  smooth connectivity and efficient
                                            customer support. Experience flawless integration with our
                                            expert services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Customer} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">
                                            Customer Engagement Expertise
                                        </h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies excels in customer engagement for
                                            contact center services. Our expertise ensures meaningful
                                            interactions, enhanced customer satisfaction, and loyalty.
                                            Trust us to elevate your customer experience with our proven
                                            strategies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Market} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">Best In Market Price</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies offers the best-in-market contact
                                            center services. Our advanced solutions ensure top-notch
                                            customer support, efficiency, and reliability. Trust us to
                                            elevate your customer service experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={User} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">User Friendly</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies provides user-friendly contact
                                            center services. Our intuitive solutions ensure easy
                                            operation, enhancing efficiency and customer satisfaction.
                                            Experience seamless interactions with our accessible and
                                            effective services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 mb-5 mt-4">
                                <div class="single_service_single_box single_service_optiMLQMS3">
                                    <div class="single_service_single_box_img_title">
                                        <div class="single_service_img_box">
                                            <img src={Uptime} alt="Features" />
                                        </div>
                                        <h2 class="single_service_title">99.99% Uptime Guarantee</h2>
                                    </div>

                                    <div class="single_service_text">
                                        <p>
                                            Radical Minds Technologies offers contact center services with
                                            a 99.99% uptime guarantee. Experience uninterrupted, reliable
                                            customer support with our advanced solutions. Trust us to keep
                                            your operations running smoothly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="serviceOursolutions" className="single_service_frame">
                    <div className="container pt-0">
                        <br />
                        <div className="row pt-4 px-3 justify-content-center gap-4">
                            <section className="choose-us-section">
                                <h2 className="bold-h1">Accolades & Awards</h2>
                                <h3>We take pride in our work and are honored to have received</h3>
                                <h4>numerous awards for our excellence in the industry</h4>
                            </section>

                            {awardsData.map((award, index) => (
                                <div key={index} className="col-md-5 single_service_single_box single_service_optiMLQMS3">
                                    <div className="w-100 d-flex flex-row">
                                            <div
                                                className="w-75 my-3 mx-3"
                                                style={{
                                                    backgroundColor: '#FFDDC8',
                                                    borderRadius: '25px',
                                                    overflow: 'hidden',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '320px' // Set a height to make sure the container has a defined size
                                                }}
                                            >
                                                <img
                                                    className="card-img"
                                                    src={award.imageSrc}
                                                    alt={award.title}
                                                    loading="lazy"
                                                    style={{
                                                        display: 'block',
                                                        maxWidth: '80%',
                                                        maxHeight: '80%',
                                                        objectFit: 'contain' // Ensures the image scales while maintaining its aspect ratio
                                                    }}
                                                />
                                            </div>

                                        <div className="w-55 my-2 py-3 px-4">
                                            <div className="card-body py-3 justify-content-start">
                                                <h5 className="card-title single_service_title fs-4 mb-3 fw-bold">{award.title}</h5>
                                                <p className="card-text fs-5 fw-semibold py-2">{award.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section class="custom-section">
                    <div class="container">
                        <h2 class="text-center fw-bold fs-1">
                            You have reached the right place to increase your ROI
                        </h2>
                        <br /><br />
                        <div class="row text-center justify-content-center">

                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="custom-item">
                                    <div>
                                        <span class="custom-text text-red">32+</span>
                                        <p class="custom-subtext">Language Support</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="custom-item">
                                    <div>
                                        <span class="custom-text text-green">7000+</span>
                                        <p class="custom-subtext">Employees</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 mb-4">
                                <div class="custom-item">
                                    <div>
                                        <span class="custom-text text-blue">100+</span>
                                        <p class="custom-subtext">Global Client</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        <div class="row">
                            <h2 class="fw-bold fs-1 text-center">
                                Trusted by 120+ Customers Across Globe
                            </h2>
                            <div class="text-center py-3">
                                <button class="btn btn-danger requestAdemo text-white font-bold" onClick={() => setShowForm(true)}>Request a Demo Now!</button>

                            </div>
                        </div>
                    </div>
                </section>
                {showForm && <InquiryForm handleClose={handleCloseForm} />}

                <section>
                    <div className="container">
                        <h2>Frequently Asked Questions?</h2>
                        {faqs.map((faq, index) => (
                            <div key={index}>
                                <button
                                    className="accordion"
                                    onClick={() => handleClick(index)}
                                >
                                    <h5 className=''> {faq.question}</h5>
                                    <span className="icon">
                                        {openIndex === index ? '-' : '+'}
                                    </span>
                                </button>
                                <div
                                    style={{ display: openIndex === index ? 'block' : 'none' }}
                                >
                                    <p className='my-3'>{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </PageLayout>
        </div>

    );
}

export default LandingPage;
