export const MobNavItems = [
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Industries",
    link: "/",
    class: "break center_dropdown grid-3",
    submenu: [
      {
        title: "Industries",
        link: "/",
        submenu: [
          {
            title: "BPO",
            link: "/industries/bpo",
            desc: "On call - fingertips Solutions",
          },
          {
            title: "Education",
            link: "/industries/education",
            desc: "Secure learning Excellence",
          },
          {
            title: "Financial Services",
            link: "/industries/financial-services",
            desc: "Next generation banking",
          },
          {
            title: "Travel & Hospitality",
            link: "/industries/travel-and-hospitality",
            desc: "Secure best memories with us",
          },
          {
            title: "Telecommunication",
            link: "/industries/telecommunication",
            desc: "Where boundary get connected",
          },
          {
            title: "Government",
            link: "/industries/government",
            desc: "Empowering Nation Digitally",
          },
          {
            title: "Healthcare",
            link: "/industries/healthcare",
            desc: "A transforming Healing Presence",
          },
          {
            title: "Capital Market",
            link: "/industries/capital-market",
            desc: "Discover investment opportunities",
          },
        ],
      },
      {
        title: "Organization Type",
        link: "/",
        col: true,
        submenu: [
          {
            title: "Enterprise",
            link: "/organization-type/enterprise",
            desc: "Large scale Business solution",
          },
          {
            title: "Mid-Sized",
            link: "/organization-type/mid-sized",
            desc: "Scalable agile Business Solution",
          },
          {
            title: "Small Business",
            link: "/organization-type/small-business",
            desc: "Solution for Compact Businesses",
          },
        ],
      },
    ],
  },
  {
    title: "Services",
    link: "/",
    class: "mega-drop center_dropdown",
    submenu: [ 
      {
        title: "Call Center Solution",
        link: "/",

        submenu: [
          {
            title: "Cloud Contact Center",
            link: "/services/cloud-contact-center",
          },
          {
            title: "Blended Call Center",
            link: "/services/blended-call-center",
          },
          {
            title: "Outbound Call Center",
            link: "/services/outbound-call-center",
          },
          {
            title: "Inbound Call Center",
            link: "/services/inbound-call-center",
          },
          {
            title: "CTI Solutions",
            link: "/services/cti-solutions",
          },
          {
            title: "Cloud Telephony",
            link: "/services/cloud-telephony",
          },
          {
            title: "Automated Telemarketing",
            link: "/services/automated-telemarketing",
          },
          {
            title: "CRM Integration",
            link: "/services/crm-integration",
          },
        ],
      },
      {
        title: "Dialer Solution",
        link: "/",
        submenu: [
          {
            title: "IVR",
            link: "/services/ivr",
          },
          {
            title: "Click to Call",
            link: "/services/click-to-call",
          },
          {
            title: "Auto Dialer",
            link: "/services/auto-dialer",
          },
          {
            title: "Auto Predictive Dialer",
            link: "/services/auto-predictive-dialer",
          },
          {
            title: "Predictive Dialer",
            link: "/services/predictive-dialer",
          },
          {
            title: "ACD/Smart Routing",
            link: "/services/acd-smart-routing",
          },
          {
            title: "Screen Recording",
            link: "/services/screen-recording",
          },
          {
            title: "Toll Free Number Service",
            link: "/services/toll-free-number-service",
          },
        ],
      },
      {
        title: "Customer Connect",
        link: "/",
        submenu: [
          {
            title: "Conversational AI",
            link: "/services/conversational-ai",
          },
          {
            title: "Omnichannel Communication",
            link: "/services/omnichannel-communication",
          },
          {
            title: "Chatbot",
            link: "/services/chatbot",
          },
          {
            title: "Customer Journey Experience",
            link: "/services/customer-journey-experience",
          },
          {
            title: "Omnichannel Ticketing",
            link: "/services/omnichannel-ticketing",
          },
          {
            title: "SMS/Whatsapp",
            link: "/services/sms-whatsapp",
          },
        ],
      },
      {
        title: "Business Solution",
        link: "",
        submenu: [
          {
            title: "Auto Attendant",
            link: "/services/auto-attendant",
          },
          {
            title: "Contact Manager",
            link: "/services/contact-manager",
          },
          {
            title: "Knowledge Management",
            link: "/services/knowledge-management-platform",
          },
          {
            title: "Help Desk Routing",
            link: "/services/help-desk-routing",
          },
          {
            title: "Unified Agent Solution",
            link: "/services/unified-agent-solution",
          },
        ],
      },
    ],
  },
  {
    title: "Who We Are",
    link: "/leadership",
    submenu: [
      {
        title: "Our Leadership",
        link: "/leadership",
      },
      {
        title: "Continous Innovation",
        link: "/continous-innovation",
      },
      {
        title: "Our Vision and Value",
        link: "/our-vision",
      },
      // {
      //   title: "Our Customer",
      //   link: "/our-customer",
      // },
      {
        title: "Media Center",
        link: "/media-center",
      },
      {
        title: "Our Story",
        link: "/our-story",
      },
      // {
      //   title: "Certificate",
      //   link: "/our-certificate",
      // },

      {
        title: "Process Security",
        link: "/process-security",
      },
    ],
  },
  {
    title: "Partner",
    link: "/",
    submenu: [
      {
        title: "Become Partner",
        link: "/become-partner",
      },
      {
        title: "Partner Information",
        link: "/partnership-information",
      },
    ],
  },
  {
    title: "CSR",
    link: "/csr",
  },
  {
    title: "Career",
    link: "/career",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
];
