import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import { api } from "../../helper/api";
import "./Media.scss";

const Media = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const loadCareerData = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: { fetch_all_media: true},
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        console.log(response);
        if (jsonData.status === 200) {
          setloading(true);
          setData(jsonData.data);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setloading(false);
        }
      }
    };
    loadCareerData();
  }, []);

  console.log(data)
  return (
    <>
      <PageLayout title="Media – Radical Minds Technologies Pvt. Ltd">
        <div className="media_page">
          <BannerImage
            Image={
              "./assets/banner/media.jpg"
            }
            PageName="Media"
            BannerTitle="Business news, trends and insights"
          />
          <div className="container">
            <div className="d-flex flex-row justify-content-between mb-flex-col-with-10gap">
              <div className="ms-auto ms-d-auto header__navigate">
                <Link to="/press-relase" className="btn btn-primary me-2">
                  Press Release
                </Link>
                <Link to="/our-award" className="btn btn-primary">
                  Awards
                </Link>
              </div>
            </div>
            <div className="row media_section">
              {loading ? (
                data.length > 0 ? (
                  data.map((news) => {
                    return (
                      <div className="col-md-6 col-12">
                        <div className="article pt-50 pb-50 bdb-707070-op30p">
                          <div
                            className="blog_image pos-r w-100 d-block overflow-hidden"
                            style={{ paddingTop: "calc(24600%/416)" }}
                          >
                            <img
                              src={`${news.images[0].src}`}
                              className="bg_img pos-a t-0 l-0 w-100"
                              alt="Awards"
                              style={{ width: "100%", height: "100%" , objectFit: "cover"}}
                            />
                          </div>
                          <h2 className="mt-2">{news.title}</h2>
                          <Link
                            to={news.uni_url}
                            state={news}
                            className="mt-16 readMoreBtn"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>Loading....</>
                )
              ) : (
                <>Media Not Found</>
              )}
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Media;
