import React from 'react';

const SelectBox = ({
  options,
  value,
  onChange,
  placeholder = '',
  isMulti = false,
  isDisabled = false,
  className = '',
  label,
  required,
  ...rest
}) => {
  return (
    <div className="form-group">
      {label && (
        <label className="form-label">
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </label>
      )}
      <select
        value={value}
        onChange={onChange}
        multiple={isMulti}
        disabled={isDisabled}
        className={`form-select ${className}`}
        {...rest}
      >
        {!isMulti && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
