import React from "react";
import twitter from "../../../src/pages/LandingPage/image/twit.svg"
import {
	FaFacebookF,
	FaInstagram,
	FaYoutube,
	FaLinkedinIn,
} from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import "./SocialLink.scss";
const SocialLink = () => {
	return (
		<>
			<div className="SocialList">
				<a
					href="https://www.facebook.com/RadicalMindsTechnologies/"
					target="_blank"
					aria-label="Radical Minds Facebook"
					className="icons"
					rel="noreferrer"
				>
					<FaFacebookF />
				</a>
				<a
					href="https://www.instagram.com/radicalminds_in/"
					target="_blank"
					aria-label="Radical Minds Instagram"
					className="icons"
					rel="noreferrer"
				>
					<FaInstagram />
				</a>
				<a
					href="https://twitter.com/Radicalmindsin"
					target="_blank"
					aria-label="Radical Minds Twitter"
					className="icons"
					rel="noreferrer"
					class="icons"
				>
					<img src={twitter} alt="Twitter" />

				</a>
				<a
					//href="https://www.youtube.com/@user-nm8eo9ds2r"

					href="https://www.youtube.com/channel/UCuengmtOiMYjViXHelEptnQ"
					target="_blank"
					aria-label="Radical Minds Youtube"
					className="icons"
					rel="noreferrer"
				>
					<FaYoutube />
				</a>
				<a
					href="https://www.linkedin.com/company/radical-minds-pvt--ltd-/"
					target="_blank"
					className="icons"
					rel="noreferrer"
				>
					<FaLinkedinIn />
				</a>
				<a
					//href="https://wa.me/+918860250225?text=Hii,I contacted through your website."
					href="https://wa.me/+919355321919?text=Hii,I contacted through your website."
					target="_blank"
					aria-label="Radical Minds Whatsapp"
					className="icons"
					rel="noreferrer"
				>
					<BsWhatsapp />
				</a>
			</div>
		</>
	);
};

export default SocialLink;
