import React from "react";
import { Story1, StoryBanner } from "../../assets";

import PageLayout from "../../Components/PageLayout";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { FaUserFriends } from "react-icons/fa";
import { IoMdAirplane } from "react-icons/io";

import "./Our-Story.scss";
import { HiShoppingCart } from "react-icons/hi";
import { MdSecurity } from "react-icons/md";
import { AiFillBank, AiFillHome } from "react-icons/ai";
import { GiHealthNormal } from "react-icons/gi";
import { BsFillBagFill, BsGraphUp } from "react-icons/bs";

const OurStory = () => {
	return (
		<PageLayout
			title="Our Story | Radical Minds Journey – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Radical Minds has served customers with the utmost priority for the last 12+ years and will continue to do so in the future."
			}
		>
			<BannerImage
				Image={StoryBanner}
				PageName={"Our Story"}
				BannerTitle={["We create inspiration with purpose"]}
			/>

			<div className="container">
				<div className="row">
					<div className="col-md-5 col-12">
						<h2 className="section-bheading">
							Radical Minds Technology
						</h2>
						<p>
							Radical Minds believe in sharing the dreams of their
							Customers and we cater to all aspect of the Contact
							Center in every possible way to achieve customer
							vision and mission.
						</p>
						<p>
							Change happens with small steps and Radical Minds
							stand with their Customer throughout the customer
							journey till their success and redefine their
							business-critical operation with ease. Customer
							delight is always the focus of Radical Minds.
						</p>
					</div>
					<div className="col-md-7 col-12">
						<div className="story_img">
							<img src={Story1} className="img-fluid" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="bg-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12 mb-lg-0 mb-2">
							<h2 className="section-heading">
								Radical Minds Journey last 12+ years
							</h2>
							<p className="text-justify">
								Radical Minds is a leading transformational
								outsourcing (BPO)company. We combine our deep
								industry Knowledge with technology and business
								expertise to co-create innovative, digital-led
								transformational solutions. We care for our
								customers so we have conceived, designed and
								also planned service with the aim to serve
								various industries. Our company offers result
								driven services like lead generation, sales &
								marketing, customer retention management
								service, BFSI (BPO, KPO, LPO), etc. that empower
								business as well as enhance the credibility and
								profitability of the company.
							</p>
						</div>
						<div className="col-md-6 col-12">
							<div className="story__row">
								<div className="row">
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<FaUserFriends />
											</div>
											<div className="story__content">
												<div className="year">2010</div>
												<p>50 Employees</p>
												<p className="subtitle">
													The Beginning
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<BsGraphUp />
											</div>
											<div className="story__content">
												<div className="year">2011</div>
												<p>200 Employees </p>
												<p className="subtitle">
													{" "}
													+telesales process
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<MdSecurity />
											</div>
											<div className="story__content">
												<div className="year">2013</div>
												<p>500 Employees</p>
												<p className="subtitle">
													+Employement service process
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<IoMdAirplane />
											</div>
											<div className="story__content">
												<div className="year">2015</div>
												<p>1800 Employees </p>
												<p className="subtitle">
													+E-commerce travel company
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<GiHealthNormal />
											</div>
											<div className="story__content">
												<div className="year">2016</div>
												<p>2200 Employees </p>
												<p className="subtitle">
													{" "}
													Health & wellness process
													and online food aggregator
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<BsFillBagFill />
											</div>
											<div className="story__content">
												<div className="year">2017</div>
												<p>3000 Employees </p>
												<p className="subtitle">
													+International travel client
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<AiFillHome />
											</div>
											<div className="story__content">
												<div className="year">2019</div>
												<p>4500 Employees</p>
												<p className="subtitle">
													Health & wellness process
													and <br />
													BFSI Process
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<HiShoppingCart />
											</div>
											<div className="story__content">
												<div className="year">2021</div>
												<p>5000+ Employees</p>
												<p className="subtitle">
													Focus on doubling the
													current count
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="story__sec">
											<div className="icon">
												<AiFillBank />
											</div>
											<div className="story__content">
												<div className="year">2022</div>
												<p>7000+ Employees</p>
												<p className="subtitle">
													Focus on doubling the
													current count
												</p>
												<p className="subtitle">
													Accquired GAMMA BPO
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default OurStory;
