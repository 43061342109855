import { India } from "../assets";
import UK from "../assets/Images/location/UK.png"
// import Londonbridge from "../assets/Images/location/Londonbridge.png"



export const locationAdd = [
	{
		contury: "India",
		cities: [
			{
				city: "Gurugram",
				content: [
					{
						registered: true,
						img: India,
						mapUrl: "https://goo.gl/maps/oSPHS61XZr5sVbct6",
						add: [
							<b>Radical Minds Technologies Pvt. Ltd.</b>,
							<br />,
							"368, Phase II, Udyog Vihar, Sector 20, Gurugram, Haryana 122008",
						],
						mob: [
							<p>
								Business Inquiry :<br />{" "}
								<a href="tel:919958058594">+91-9958058594</a>
							</p>,
							<p>
								HR Management :<br />{" "}
								<a href="tel:919599290073">+91-9599290073</a>
							</p>,
						],
						email: [
							"info@radicalminds.in",
							<br />,
							"gurgaon@radicalminds.in",
						],
						getloca: "",
					},
				],
			},
			{
				city: "Bengaluru",
				content: [
					{
						registered: false,
						img: India,
						mapUrl: "https://goo.gl/maps/MyoMKUd3bMefzuKfA",
						add: [
							<b>Radical Minds Technologies Pvt. Ltd.</b>,
							<br />,
							"Krimson Square, 5th Floor, Above Vishal Mega Mart Roopena Agrahara, Hosur main road Bengaluru Karnataka – 560068",
						],
						mob: [
							<p>
								Business Inquiry :<br />{" "}
								<a href="tel:919958058594">+91-9958058594</a>
							</p>,
							<p>
								HR Management :<br />{" "}
								<a href="tel:919599290073">+91-9599290073</a>
							</p>,
						],
						email: [
							"info@radicalminds.in",
							<br />,
							"info@radicalminds.in",
						],
						getloca: "",
					},
				],
			},
			{
				city: "Chennai",
				content: [
					{
						registered: false,
						img: India,
						mapUrl: "",
						add: [
							<b>Radical Minds Technologies Pvt. Ltd.</b>,
							<br />,
							"DP111(SP)F   18A,5TH ST,Ambattur Industrial Estate Chennai , Pincode:- 600058",
						],
						mob: [
							<p>
								Business Inquiry :<br />{" "}
								<a href="tel:919958058594">+91-9958058594</a>
							</p>,
							<p>
								HR Management :<br />{" "}
								<a href="tel:919599290073">+91-9599290073</a>
							</p>,
						],
						email: [
							"info@radicalminds.in",
							<br />,
							"info@radicalminds.in",
						],
						getloca: "",
					},
				],
			},
			{
				city: "Noida",
				content: [
					{
						registered: false,
						img: India,
						mapUrl: "https://goo.gl/maps/LzaHxgmggvM9Jp8JA",
						add: [
							<b>Radical Minds Technologies Pvt. Ltd.</b>,
							<br />,
							" Plot No C22A 1st Floor C Block Sec 57 Noida Pincode:- 201301",
						],
						mob: [
							<p>
								Business Inquiry :<br />{" "}
								<a href="tel:919958058594">+91-9958058594</a>
							</p>,
							<p>
								HR Management :<br />{" "}
								<a href="tel:919599290073">+91-9599290073</a>
							</p>,
						],
						email: [
							"info@radicalminds.in",
							<br />,
							"info@radicalminds.in",
						],
						getloca: "",
					},
				],
			},
			
		],


	},



	{
    contury: "UK",
    cities: [
        {
            city: "London",
            content: [
                {
                    img: UK,
					mapUrl: "https://maps.app.goo.gl/aPw8KP2MdigtjaKz7",
                    add: [
                        <b>Radical Minds Technologies Pvt. Ltd.</b>,
                        <br />,
                        " Suite 102, Oceanair House, 750-760, High Road, Leytonstone E11 3BB",
                    ],
                    mob: [
                        <p>
                            Business Email :
                            <a style={{ color: 'blue' }} href="email:info@radicalminds.uk">&nbsp; info@radicalminds.uk</a>
                        </p>,
                    ],
					getloca: "",
                },
            ],
        },
    ],
},

];


