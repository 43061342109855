import React from "react";
import styled from "styled-components";

const StyledField = styled.div`
  .fz-12 {
    font-size: 12px;
  }
  .form-label {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    color: #000;
    font-size: 18px;
    letter-spacing: 0.1px;
    font-weight: 500;
    .text-danger {
      color: #ff0000;
    }
  }
  select,
  input {
    padding: 10px;
    letter-spacing: 0.1px;
    border: none;
    width: 100%;
    outline: 0;
    background: #fff;
    height: 54px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    border: 1px solid #ddd;
    &::placeholder {
      color: #d2d2d2;
    }
  }
  .locationbtn {
    border: none;
    outline: 0;
    height: 100%;
    width: 60px;
    height: 54px;
    background: #00b0f0;
    cursor: pointer;
    color: #fff;
  }
  &.form-check {
    padding: 5px 10px 10px 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    label {
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    input {
      margin-right: 10px;
    }
    input[type="checkbox"] {
      border-radius: 0;
    }
    @media (max-width: 786px) {
      padding: 5px 5px 5px 20px;
      font-size: 14px;
      input {
        margin-right: 5px;
      }
    }
  }
`;

export const TextField = ({
  type = "text",
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  loading = false,
  multiline,
  accept,
  onChange = () => {},
  onClick = () => {},
  isValidated = false,
}) => {
  return (
      <div
        className={`form-group${className ? " " + className : ""}`}
        onClick={onClick}
      >
        {label ? (
          <label htmlFor={id} className="form-label">
            {label}
            {required ? <span className="text-danger ms-1">*</span> : null}
          </label>
        ) : null}

        {multiline ? (
          <textarea
            type={type}
            required={required}
            className="form-control"
            name={id}
            id={id}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            defaultValue={defaultValue ?? ""}
            disabled={loading}
            rows={multiline}
          ></textarea>
        ) : type === "file" ? (
          <input
            accept={accept}
            type={type}
            required={required}
            className="form-control"
            name={id}
            id={id}
            onChange={(e) => onChange(e)}
            disabled={loading}
          />
        ) : (
          <input
            type={type}
            required={required}
            className="form-control"
            name={id}
            id={id}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            defaultValue={defaultValue ?? ""}
            disabled={loading}
          />
        )}

        {required && label && isValidated && !defaultValue ? (
          <>
            <p className="text-danger mt-8 fz-12">
              Please enter {label.toLowerCase()}{" "}
            </p>
          </>
        ) : null}
      </div>
  );
};

export const InputFieldButton = ({
  type = "text",
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  loading = false,
  onChange = () => {},
  onBtnCick = () => {},
  isValidated = false,
}) => {
  return (
    <StyledField>
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
          {required ? <span className="text-danger ms-1">*</span> : null}
        </label>
      ) : null}
      <div className={`input-group${className ? " " + className : ""}`}>
        <input
          type={type}
          required={required}
          className="form-control"
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          defaultValue={defaultValue ?? ""}
          disabled={loading}
        />
        <button className="locationbtn" type="button" onClick={onBtnCick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="36.726"
            viewBox="0 0 30 36.726"
          >
            <g
              id="Group_2181"
              data-name="Group 2181"
              transform="translate(-348 -1382.274)"
            >
              <g id="Group_3134" data-name="Group 3134">
                <text
                  id="Detect"
                  transform="translate(348 1416)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="SegoeUI, Segoe UI"
                  letterSpacing="0.02em"
                >
                  <tspan x="0" y="0">
                    Detect
                  </tspan>
                </text>
                <path
                  id="Path_3928"
                  data-name="Path 3928"
                  d="M92.684,0a7.352,7.352,0,0,0-6.469,10.845l6.067,10.972a.46.46,0,0,0,.8,0l6.069-10.976A7.352,7.352,0,0,0,92.684,0Zm0,11.027A3.676,3.676,0,1,1,96.36,7.351,3.68,3.68,0,0,1,92.684,11.027Z"
                  transform="translate(270.668 1382.274)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>

      {required && label && isValidated && !defaultValue ? (
        <p className="text-danger mt-8 fz-12">
          Please enter {label.toLowerCase()}{" "}
        </p>
      ) : null}
    </StyledField>
  );
};

export const SelectField = ({
  required,
  className,
  id,
  label,
  placeholder,
  defaultValue,
  options = [],
  loading = false,
  columns,
  onChange = () => {},
  onClick = () => {},
  isValidated = false,
}) => {
  return (
    <div className={`col-12 mb-4${columns ? " " + columns : ""}`}>
      <StyledField
        className={`form-group${className ? " " + className : ""}`}
        onClick={onClick}
      >
        {label ? (
          <label htmlFor={id} className="form-label">
            {label}
            {required ? <span className="text-danger ms-1">*</span> : null}
          </label>
        ) : null}
        <select
          required={required}
          className="form-select"
          name={id}
          id={id}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e)}
          disabled={loading}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}

          {options.length > 0
            ? options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                );
              })
            : null}
        </select>

        {required && label && isValidated && !defaultValue ? (
          <p className="text-danger mt-8 fz-12">
            Please select {label.toLowerCase()}{" "}
          </p>
        ) : null}
      </StyledField>
    </div>
  );
};

export const InputRadioButton = ({
  type = "radio",
  required,
  name,
  id,
  label,
  defaultChecked,
  loading = false,
  onChange = () => {},
  isValidated = false,
  errorMessage = "Please check the required field",
}) => {
  return (
    <>
      <StyledField className="form-check">
        <input
          className="form-check-input"
          type={type}
          name={name}
          id={id}
          onChange={(e) => onChange(e)}
          disabled={loading}
          defaultChecked={defaultChecked}
          required={required}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </StyledField>

      {required && isValidated && !defaultChecked ? (
        <p className="text-danger mt-8 fz-12">
          Please enter {errorMessage.toLowerCase()}{" "}
        </p>
      ) : null}
    </>
  );
};
