import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RadicalMind } from "../../assets";
import { NavItems } from "./NavItems/NavItems";
import MenuItems from "./Dropdown/MenuItems";
import SocialLink from "../ScoialLink/SocialLink";
import { HiOutlineSearch } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./Header.scss";
import { useEffect } from "react";
import { MobNavItems } from "./NavItems/MobNavItems";
import MobileNavTab from "./Dropdown/MobileNavTab";
import WhatsAppIcon from "../whatsAppIcon";

const Header = ({searchInput = null}) => {
  const location = useLocation();
  const [mobileNav, setMobileNav] = useState(false);
  const [searchTab, setSearchTab] = useState(false);
  const [searchData, setSearchData] = useState({});
  const navigate = useNavigate();
  
  const toggleNav = (e) => {
    // e.preventDefault(); 
    console.log("hi");
    setMobileNav((prev) => !prev);
  };

  const [deviceType, setDeviceType] = useState("");

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      navigate("/search", { state: searchData});
    }
  }


  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  return (
    <header>
      <nav role="navigation">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={RadicalMind} alt="RadicalMind" className="header .navbar .navbar-brand img" />
            </Link>

{/* 
            {deviceType != "Mobile" && (
              <div className={`mobileNav ms-auto ${mobileNav ? "active" : ""}`}>
                <ul className="navbar-nav">
                  {NavItems.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                      <MenuItems
                        items={menu}
                        key={index}
                        depthLevel={depthLevel}
                      />
                    );
                  })}
                </ul>
              </div>
            )} */}


            <div className={`mobileNav ms-auto ${mobileNav ? "active" : ""}`}>
                <ul className="navbar-nav">
                  {NavItems.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                      <MenuItems
                        items={menu}
                        key={index}
                        depthLevel={depthLevel}
                      />
                    );
                  })}
                </ul>
              </div>



            <div className="d-flex flex-row align-items-center">
              <div className="search-feild mx-3">
                <button type="button" onClick={() => setSearchTab(true)} aria-label="Radical Minds Search Button" >
                  <HiOutlineSearch />
                </button>
              </div>
              <div
                className={`mobile-nav ${mobileNav ? "active" : ""} ${
                  searchTab ? "d-none" : ""
                }`}
                onClick={toggleNav}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="header-social">
              <SocialLink />
            </div>
          </div>
        </nav>
        <div className={`search-feid ${searchTab ? "search--open" : ""}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-group">
              <HiOutlineSearch />
              <input
                className="search__input"
                type="search"
                placeholder="What are you looking for?"
                autoComplete="off"
                spellCheck="false"
                title="Search"
                name="searchInput"
                defaultValue={searchInput}
                aria-label="search text"
                onKeyDown={handleKeyDown}
                onChange={(e)=>setSearchData({[e.target.name]: e.target.value.trim(),})}
              />
            </div>
            <button
              id="btn-search-close"
              className="btn1 btn--search-close"
              onClick={() => setSearchTab(false)}
              aria-label="Radical Minds Search Button" 
            >
              <AiOutlineCloseCircle />
            </button>
          </div>
        </div>
      </nav>
      {deviceType === "Mobile" && (
        <div className={`mobile_menu ms-auto ${mobileNav ? "active" : ""}`}>
          <ul className="navbar-nav">
            {MobNavItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MobileNavTab
                  items={menu}
                  isActive={mobileNav}
                  key={index}
                  depthLevel={depthLevel}
                />
              );
            })}
          </ul>
        </div>
      )}
      <WhatsAppIcon/>
    </header>
  );
};

export default Header;
