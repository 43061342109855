import React, { useState } from "react";
import { GrLinkedin } from "react-icons/gr";
import { BsArrowRight } from "react-icons/bs";
import TeamModal from "./TeamModal";
import styled from "styled-components/macro";
import "./TeamCard.scss";
export const ReadMore = styled(BsArrowRight)`
  font-weight: 800;
  /* color: #fff; */
  border-radius: 20px;
  margin-left: 5px;
  color: #ee4648;
  stroke-width: 1px;
`;
const TeamCard = ({ teams }) => {
  const [teamModal, setTeamModal] = useState(false);

  return (
    <>
      <div className="col-lg-3 col-md-6 col-12 team-container ">
        <div className="team businessCard">
          <div className="business-img">
            <img src={teams.img} alt={teams.name} className="img-fluid" />
          </div>
          <div className="team-content business-text">
            <h4>{teams.name}</h4>
            <p className="">{teams.designaton}</p>
            {!teamModal && (
              <p className="rightArrowBtn" onClick={() => setTeamModal(true)}>
                <span>Know More</span>
                <ReadMore />
              </p>
            )}
          </div>
        </div>

        <TeamModal team={teams} active={teamModal} closeModal={setTeamModal} />
      </div>
    </>
  );
};

export default TeamCard;
