import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomerBanner } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import { api } from "../../helper/api";
import "./Media.scss";

const Blog = () => {
	const [data, setData] = useState([]);
	const [loading, setloading] = useState(true);

	useEffect(() => {
		const loadPressRelease = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch",
					data: { fetch_all_blog: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				if (jsonData.status === 200) {
					setloading(true);
					setData(jsonData.data);
				}
			} catch (err) {
				if (err.response.status === 400) {
					setloading(false);
				}
			}
		};
		loadPressRelease();
	}, []);

	return (
		<>
			<PageLayout title="Blog – Radical Minds Technologies Pvt. Ltd">
				<div className="media_page">
					<BannerImage
						Image="./assets/banner/blog.jpg"
						PageName="Blog"
					/>
					<div className="container">
						<div className="row media_section">
							{loading ? (
								data.length > 0 ? (
									data.map((news) => (
										<Link
											to={`/blog/${news.uni_url}`}
											className="col-md-6 col-12"
										>
											<div className="article pt-50 pb-50 bdb-707070-op30p">
												<div
													className="blog_image pos-r w-100 d-block overflow-hidden"
													style={{
														paddingTop:
															"calc(24600%/416)",
													}}
												>
													<img
														src={`${
															news && news.images
														}`}
														className="bg_img pos-a t-0 l-0 w-100 h-100"
														alt={news.title}
														style={{
															width: "100%",
															height: "100%",
														}}
													/>
												</div>
												<p className="d-f article_bradcamb">
													<span>{news.date}</span>
													<span className="ms-lg-4 ms-2">
														{news.pr_from}
													</span>
												</p>
												<h2 className="mt-2">
													{news.title}
												</h2>
												<a
													href={`/blog/${news.uni_url}`}
													target="_blank"
													rel="noreferrer"
													className="mt-16 readMoreBtn"
												>
													Read more
												</a>
											</div>
										</Link>
									))
								) : (
									<>Loading....</>
								)
							) : (
								<>Blog not published!</>
							)}
						</div>
					</div>
				</div>
			</PageLayout>
		</>
	);
};

export default Blog;

export const BlogDetails = () => {
	const { state } = useLocation();
	const location = useParams();

	const [data, setData] = useState([]);
	useEffect(() => {
		const loadCareerData = async () => {
			const response = await api({
				method: "post",
				url: "fetch",
				data: {
					blog_url: state?.uni_url ? state?.uni_url : location.slag,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setData(jsonData.data[0]);
		};
		loadCareerData();
	}, [location.slag, state?.uni_url]);

	return (
		<>
			<PageLayout
				title={data?.meta_title}
				metaDescription={data?.meta_desc}
			>
				<div className="media_page">
					<BannerImage
						Image="../assets/banner/blog.jpg"
						PageName="Blog"
						SubTitle="An archive of our media features, appearances, and mentions."
					/>
					<div className="container">
						<div className="d-flex flex-row justify-content-between mb-3">
							<div className="header__navigate">
								<Link
									to="/blog"
									className="btn btn-primary me-2"
								>
									Back
								</Link>
							</div>
						</div>
						<div className="rmt-card details-page rmt-card-white">
							<div
								className="blog_image pos-r w-100 d-block overflow-hidden mb-4"
								style={{ paddingTop: "calc(24600%/416)" }}
							>
								<img
									src={`${data && data.images}`}
									className="bg_img pos-a t-0 l-0 w-100 h-100"
									alt={data && data.title}
									style={{ width: "100%", height: "100%" }}
								/>
							</div>
							<p className="mb-0">{data && data.created_on}</p>
							<h1 className="section-heading">
								{data && data.title}
							</h1>
							<div
								dangerouslySetInnerHTML={{
									__html: data["content"]
										? JSON.parse(data["content"])[0]
										: "",
								}}
							></div>
						</div>
					</div>
				</div>
			</PageLayout>
		</>
	);
};
