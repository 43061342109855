/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useState } from "react";
import BannerImage from "../../../Components/BannerImge/BannerImage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import PageLayout from "../../../Components/PageLayout";
import "../Award.scss";
import { BsImageFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router";
import { api } from "../../../helper/api";

const ReadMoreAward = () => {
	const { state } = useLocation();
	const location = useParams();
	const navigate = useNavigate();
	//   console.log(state);
	console.log();

	const [data, setData] = useState([]);
	const [MediaImages, setMediaImages] = useState([]);
	const [loading, setloading] = useState(false);
	useEffect(() => {
		const loadCareerData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch",
					data: {
						award_url: state?.uni_url
							? state?.uni_url
							: location.slag,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				if (jsonData.status === 200) {
					setloading(true);
					setData(jsonData.data[0]);
					setMediaImages(jsonData.data[1].images);
				}
			} catch (err) {
				if (err.response === 400) {
					navigate("/media");
				}
			}
		};
		loadCareerData();
	}, [location.slag, navigate, state?.uni_url]);

	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);

	return (
		<PageLayout title="National Achievers Awards Winner for Customer choice in Contact Centre and IT industry 2022">
			<div className="award_page">
				<BannerImage
					BannerTitle={"Inspired to be best"}
					PageName="Our Awards"
					Image={"../assets/banner/award.jpg"}
				/>
				<div className="container">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div className="header__navigate">
							<Link
								to="/our-award"
								className="btn btn-primary me-2"
							>
								Back
							</Link>
						</div>
					</div>
					{loading ? (
						data && (
							<div className="rmt-card details-page rmt-card-white">
								<div className="row mb-4 g-0 galleryAward">
									{MediaImages.length > 0 &&
										MediaImages.map(
											(img, id) =>
												id < 3 && (
													<div
														className="pos-r col-md-4 col-12 mb-2"
														key={id}
													>
														<div
															className="pos-r w-100"
															style={{
																paddingTop:
																	"calc(35000%/460)",
															}}
														>
															<img
																src={`${img.src}`}
																className="bg_img pos-a t-0 l-0 img-fluid w-100 h-100"
																alt=""
																style={{
																	width: "100%",
																	height: "100%",
																	objectFit:
																		"cover",
																}}
															/>
														</div>
													</div>
												)
										)}
									{MediaImages.length &&
										MediaImages.length - 3 != 0 && (
											<button
												className="btn btn-primary viewButton"
												onClick={() => setIsOpen(true)}
											>
												<span>
													+{MediaImages.length - 3}{" "}
												</span>
												<BsImageFill />
											</button>
										)}
								</div>
								<h2 className="section-heading">
									{data && data.title}
								</h2>
								<div
									dangerouslySetInnerHTML={{
										__html: data["content"]
											? JSON.parse(data["content"])[0]
											: "",
									}}
								></div>
							</div>
						)
					) : (
						<>Loading.... </>
					)}
				</div>
			</div>
			{isOpen && (
				<Lightbox
					mainSrc={MediaImages[photoIndex].src}
					nextSrc={
						MediaImages[(photoIndex + 1) % MediaImages.length].src
					}
					prevSrc={
						MediaImages[
							(photoIndex + MediaImages.length - 1) %
								MediaImages.length
						].src
					}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() =>
						setPhotoIndex(
							(photoIndex + MediaImages.length - 1) %
								MediaImages.length
						)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % MediaImages.length)
					}
				/>
			)}
		</PageLayout>
	);
};

export default ReadMoreAward;
