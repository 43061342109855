import React from "react";
import { CoreBanner } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";

import PageLayout from "../../Components/PageLayout";
import "./Our-Values.scss";
const OurValues = () => {
	return (
		<PageLayout
			title="Our Vision | Delivering Excellence with Minds of Innovation – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"We are committed to providing exceptional customer service with our top-notch services"
			}
		>
			<div className="ourvalue_page">
				<BannerImage
					Image={CoreBanner}
					PageName={"Our Values"}
					BannerTitle={[
						"Delivering Excellence  with Minds of Innovation",
					]}
				/>
				<div className="pb-0">
					<div className="container mb-md-4 mb-0">
						<div className="row">
							<div className="col-md-6 col-12">
								<div className="ourvalue_container about-center">
									<div className="icon">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											version="1.1"
											viewBox="0 0 510.833 510.833"
											width={150}
										>
											<g>
												<g>
													<path d="m255.417 65.563c4.143 0 7.5-3.357 7.5-7.5v-50.563c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v50.563c0 4.143 3.357 7.5 7.5 7.5z"></path>
													<path d="m115.487 119.132c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607l-35.754-35.755c-2.929-2.927-7.677-2.929-10.607 0-2.929 2.93-2.929 7.678 0 10.607z"></path>
													<path d="m65.027 240.953h-50.564c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.499-7.5z"></path>
													<path d="m115.487 377.775-35.754 35.754c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.754c2.929-2.93 2.929-7.678 0-10.607-2.929-2.927-7.677-2.927-10.607 0z"></path>
													<path d="m395.346 377.775c-2.93-2.928-7.678-2.928-10.607 0-2.929 2.93-2.929 7.678 0 10.607l35.754 35.754c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607z"></path>
													<path d="m496.37 240.953h-50.563c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"></path>
													<path d="m420.493 72.77-35.754 35.755c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.755c2.929-2.93 2.929-7.678 0-10.607-2.93-2.928-7.678-2.928-10.607 0z"></path>
													<path d="m403.579 233.293c0-82.872-66.465-150.293-148.162-150.293-28.618 0-56.394 8.293-80.324 23.982-23.282 15.265-41.842 36.74-53.673 62.103-1.751 3.754-.127 8.217 3.626 9.968 3.757 1.753 8.217.127 9.968-3.626 21.939-47.035 69.2-77.427 120.403-77.427 73.426 0 133.162 60.692 133.162 135.293 0 35.434-13.39 68.934-37.702 94.327-14.013 14.635-25.941 28.619-29.01 48.213h-130.939c-3.867-20.341-17.742-34.612-30.21-47.425-24.803-25.49-38.463-59.27-38.463-95.115 0-8.851.847-17.703 2.516-26.312.789-4.066-1.868-8.002-5.935-8.791-4.066-.783-8.002 1.869-8.791 5.936-1.852 9.547-2.79 19.36-2.79 29.167 0 39.775 15.169 77.27 42.713 105.576 15.819 16.256 27.017 29.232 27.017 48.251 0 .066.001.134.003.2l.43 44.013c0 14.279 7.617 26.812 19 33.752v8.248c0 20.678 16.822 37.5 37.5 37.5h46c20.678 0 37.5-16.822 37.5-37.5v-8.248c11.383-6.94 19-19.473 19-33.752 0 0-.053-49.026-.153-49.515 1.423-17.382 11.959-29.737 25.447-43.825 26.997-28.199 41.867-65.383 41.867-104.7zm-101.162 240.04c0 12.406-10.094 22.5-22.5 22.5h-46c-12.406 0-22.5-10.094-22.5-22.5v-2.891c1.799.252 3.633.391 5.5.391h80c1.867 0 3.701-.14 5.5-.391zm-5.5-17.5h-80c-13.51 0-24.5-10.99-24.5-24.5v-.5h76c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-76v-25h128.768l.232 25h-18c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h18v.5c0 13.51-10.991 24.5-24.5 24.5z"></path>
													<path d="m304.177 234.285c-2.54 3.271-1.947 7.983 1.324 10.523 3.27 2.541 7.982 1.949 10.523-1.324l14.848-19.121c3.932-5.063 5.656-11.354 4.856-17.715-.801-6.361-4.03-12.029-9.094-15.961-5.063-3.932-11.358-5.666-17.715-4.855-6.361.8-12.029 4.029-15.96 9.093l-48.439 62.384-27.8-30.759c-8.875-9.816-24.082-10.582-33.897-1.711-4.756 4.299-7.553 10.192-7.877 16.595-.323 6.402 1.866 12.547 6.164 17.304l47.001 52c9.461 10.974 28.156 10.301 36.761-1.374l29.685-38.23c2.54-3.271 1.947-7.983-1.324-10.523s-7.982-1.949-10.523 1.324l-29.685 38.23c-3.222 4.378-10.253 4.633-13.785.515l-47-52c-3.328-3.682-3.04-9.384.643-12.712 3.68-3.324 9.383-3.04 12.711.643l33.8 37.396c2.946 3.416 8.802 3.22 11.488-.43l53.927-69.451c2.887-3.882 8.873-4.635 12.628-1.589 3.883 2.888 4.635 8.869 1.589 12.629z"></path>
												</g>
											</g>
										</svg>
									</div>
									<h4 className="section-subheading mt-3 pt-2">
										Our Vision
									</h4>
									<p>
										Our vision is to provide exceptional
										business process outsourcing services to
										our clients by combining cutting-edge
										technology, talented and dedicated
										professionals, and a commitment to
										quality. We aim to become a trusted
										partner to businesses around the world,
										helping them to streamline operations,
										improve efficiency, and achieve their
										goals. Our ultimate goal is to provide
										unparalleled value to our clients and
										gain more business to grow and become a
										much larger organization with diverse
										sectors and world-class administration
										by 2025.
									</p>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="ourvalue_container about-center">
									<div className="icon">
										<svg
											version="1.1"
											viewBox="0 0 752 752"
											xmlns="http://www.w3.org/2000/svg"
											width={165}
										>
											<g>
												<path d="m546.49 248.13c-2.3867-3.1289-6.8516-3.7422-9.9961-1.3711l-0.71094 0.66406h0.003906c-2.6328 2.5117-2.918 6.6133-0.66406 9.4688 30.93 41.047 45.148 92.297 39.781 143.41-5.3633 51.113-29.91 98.293-68.688 132.02-38.781 33.73-88.906 51.5-140.27 49.73-51.367-1.7695-100.15-22.953-136.51-59.273-36.363-36.32-57.605-85.078-59.434-136.44-1.832-51.363 15.879-101.51 49.562-140.33 33.684-38.816 80.836-63.422 131.94-68.844 51.109-5.4258 102.38 8.7305 143.46 39.613 2.8086 2.1328 6.7617 1.8477 9.2344-0.66406l0.71094-0.71094c2.3828-3.0391 1.9219-7.4219-1.043-9.8984-46.027-34.66-103.94-49.617-160.99-41.578-57.055 8.0391-108.58 38.41-143.24 84.438s-49.617 103.94-41.578 160.99c8.0352 57.051 38.41 108.58 84.438 143.24 37.445 28.34 83.129 43.672 130.09 43.664 54.176 0.058594 106.42-20.109 146.5-56.555 40.082-36.445 65.105-86.547 70.176-140.48 5.0664-53.934-10.184-107.82-42.77-151.1z" />
												<path d="m461.25 261.77c0.32422-0.32422 0.625-0.67188 0.89844-1.043 2.1953-3.2578 1.3516-7.6797-1.8945-9.8984-40.648-27.637-92.008-34.34-138.39-18.062-46.383 16.277-82.289 53.602-96.754 100.58-14.469 46.984-5.7734 98.039 23.422 137.59 29.195 39.547 75.43 62.891 124.59 62.906 46.141-0.035156 89.867-20.633 119.27-56.191 29.402-35.559 41.426-82.375 32.793-127.7-3.8203-20.32-11.734-39.656-23.254-56.828-1.0391-1.5742-2.668-2.6641-4.5195-3.0273-1.8516-0.36719-3.7695 0.023438-5.332 1.0859-0.375 0.24219-0.72656 0.52734-1.0391 0.85156-2.418 2.3984-2.7969 6.1719-0.90234 9 10.465 15.633 17.648 33.227 21.125 51.715 8.1914 42.953-4.0625 87.273-33.152 119.92-29.09 32.648-71.707 49.91-115.32 46.707-43.613-3.2031-83.25-26.508-107.26-63.059-24.008-36.547-29.648-82.18-15.266-123.48 14.379-41.293 47.148-73.551 88.664-87.281 41.516-13.73 87.059-7.3711 123.22 17.211 2.8711 1.9219 6.707 1.5039 9.0938-0.99609z" />
												<path d="m346.97 310.79c18.07-6.957 38.176-6.3945 55.832 1.5625 2.6289 1.168 5.6992 0.625 7.7695-1.3711 0.67969-0.62891 1.2266-1.3828 1.6094-2.2266 0.80859-1.7305 0.88281-3.7148 0.20312-5.5-0.67578-1.7852-2.0508-3.2188-3.8047-3.9727-24.723-11.023-53.168-9.918-76.969 2.9844-23.797 12.902-40.242 36.137-44.5 62.871-4.2539 26.734 4.1641 53.93 22.781 73.582 18.617 19.652 45.312 29.535 72.238 26.734 26.926-2.8008 51.02-17.965 65.191-41.031 14.176-23.062 16.816-51.406 7.1484-76.691-0.66797-1.7695-2.0117-3.1992-3.7383-3.9727-1.7227-0.76953-3.6836-0.82422-5.4492-0.14844-0.86328 0.32812-1.6523 0.82812-2.3203 1.4688-2.1055 1.9727-2.8008 5.0273-1.75 7.7188 6.9102 18.078 6.3555 38.16-1.5391 55.832-7.8945 17.668-22.488 31.48-40.562 38.391-18.078 6.9141-38.164 6.3633-55.832-1.5312-17.672-7.8945-31.484-22.484-38.398-40.562-6.9141-18.078-6.3633-38.16 1.5273-55.832 7.8945-17.672 22.484-31.484 40.562-38.398z" />
												<path d="m589.11 187.89h-14.965l19.984-19.984c2.6094-2.8008 2.5312-7.1602-0.17578-9.8672-2.7031-2.7031-7.0664-2.7812-9.8633-0.17188l-19.984 19.984v-14.965c0-3.9258-3.1836-7.1055-7.1055-7.1055s-7.1055 3.1797-7.1055 7.1055v29.172l-169.92 169.73c-5.0703-2.0391-10.789-1.7109-15.59 0.89453-4.8047 2.6094-8.1914 7.2227-9.2422 12.59-1.0508 5.3633 0.34766 10.914 3.8164 15.141 3.4648 4.2266 8.6367 6.6836 14.102 6.707 2.2344 0.007812 4.4531-0.41016 6.5352-1.2344 4.543-1.7383 8.207-5.207 10.195-9.6445 1.9883-4.4414 2.1367-9.4844 0.41016-14.035v-0.37891l169.73-169.73h29.172c3.9219 0 7.1016-3.1797 7.1016-7.1016 0-3.9258-3.1797-7.1055-7.1016-7.1055z" />
											</g>
										</svg>
									</div>
									<h4 className="section-subheading  pt-2">
										Our Mission
									</h4>
									<p>
										Our mission is to provide top-notch BPO
										services to our clients at an affordable
										price. We have been in the industry for
										more than 12 years, and we are
										well-versed in all aspects of BPO. We
										believe that our experience and
										expertise will help you achieve your
										business goals.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bg-gray">
					<div className="container py-4">
						<div className="ourvalue_container">
							<h4 className="section-subheading p-0 m-0 mb-4">
								Our Core Values
							</h4>
							<div className="row val_container">
								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													width="752pt"
													height="752pt"
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m375.72 153.19c25.719 0 46.406 20.688 46.406 46.406 0 20.688-13.418 38.02-31.871 44.172l1.1172 27.398 65.977 3.9141c2.2383-6.1523 8.3867-10.625 15.098-10.625 9.5039 0 16.773 7.8281 16.773 16.773 0 8.9453-7.2695 16.773-16.773 16.773-6.7109 0-12.859-4.4727-15.098-10.625l-64.859 3.9141 10.062 202.96c61.504-7.2695 110.15-40.258 110.15-96.73l-38.02 12.301 47.527-35.227 48.086 34.664-37.461-11.742c0 138.66-116.3 122.45-157.11 201.29-39.699-77.16-157.11-64.301-157.11-200.17l-36.902 11.184 47.527-35.227 48.086 34.664-43.055-11.184c0 57.031 54.793 88.902 115.18 96.168l10.062-202.96-64.859-3.9141c-2.2383 6.1523-8.3867 10.625-15.098 10.625-9.5039 0-16.773-7.8281-16.773-16.773 0-8.9453 7.2695-16.773 16.773-16.773 6.7109 0 12.859 4.4727 15.098 10.625l65.977-3.9141 1.1172-27.398c-18.453-6.1523-32.43-23.484-32.43-44.172 0-25.719 20.688-46.406 46.406-46.406zm0 27.957c10.062 0 18.453 8.3867 18.453 18.453 0 10.062-8.3867 17.891-18.453 17.891-10.062 0-17.891-7.8281-17.891-17.891s7.8281-18.453 17.891-18.453z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">
											Customer Centricity
										</h6>
										<ul>
											<li>
												At Radical Minds, Fostering a
												positive customer experience at
												every stage of the customer
												journey is what we aim for.
											</li>
											<li>
												All decisions in our
												organization are based on
												customer-centricity, by
												considering the effects its
												outcome will have on our
												customers.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m241.33 206.73-75.773 104.19c-3.7891 5.2461-3.332 13.133 1.0352 17.906l198.9 217.85c5.3086 5.832 15.707 5.832 21.016 0l198.9-217.85c4.3672-4.7734 4.8242-12.66 1.0352-17.906l-75.773-104.19c-2.6523-3.5938-7.0781-5.8047-11.543-5.7734h-246.26c-4.8711-0.046875-8.8867 2.5352-11.543 5.7734zm32.262 22.645h72.516l-49.133 60.086zm132.45 0h72.223l-23.234 59.938zm-30.191 8.1406 55.348 67.633h-110.55zm-126.68 6.957 23.531 60.676h-67.633zm253.66 0 44.102 60.676h-67.484zm-293.47 89.094h74.293l54.906 141.48zm104.63 0h123.87l-61.859 159.53zm154.36 0h74.293l-129.05 141.33z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">
											Excellence
										</h6>
										<ul>
											<li>
												At Radical Minds, we will
												continuously strive for better
												ways of doing the things we do.
											</li>
											<li>
												As a team, we will expect more
												of ourselves than our clients do
												and strive to exceed
												expectations in every project we
												undertake.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m522.65 180.32h-28.414c-0.99219-6.5469-3.8633-12.738-8.3516-17.77-6.5195-7.3086-15.879-11.5-25.676-11.5h-168.42c-9.7969 0-19.156 4.1914-25.68 11.5-4.4883 5.0312-7.3594 11.223-8.3516 17.77h-28.414c-24.086 0-43.684 19.594-43.684 43.68v17.746c0 60.203 48.98 109.18 109.18 109.18h0.10547c12.996 14.969 30.035 26.234 49.324 32.195l-3.4375 77.066c-17.016 2.3203-30.172 16.945-30.172 34.59v8.1641h-10.523c-25.324 0-45.926 20.602-45.926 45.926v17.668c0 18.977 15.438 34.414 34.414 34.414h174.73c18.977 0 34.414-15.438 34.414-34.414v-17.668c0-25.324-20.602-45.926-45.926-45.926h-10.523v-8.1641c0-17.656-13.176-32.293-30.211-34.594l-3.3789-77.066c19.289-5.9609 36.324-17.227 49.316-32.191h0.10547c60.203 0 109.18-48.98 109.18-109.18v-17.746c0-24.086-19.598-43.68-43.688-43.68zm-315.79 61.426v-17.746c0-12.398 10.09-22.488 22.492-22.488h29.633l10.434 91.285c1.457 12.75 5.1055 24.758 10.543 35.672-41.453-7.0898-73.102-43.277-73.102-86.723zm244.99 282.39c13.641 0 24.734 11.094 24.734 24.734v17.668c0 7.2891-5.9297 13.219-13.219 13.219h-174.73c-7.2891 0-13.219-5.9297-13.219-13.219v-17.668c0-13.641 11.094-24.734 24.734-24.734zm-31.715-29.355v8.1641h-88.27v-8.1641c0-7.5625 6.1523-13.719 13.719-13.719h60.836c7.5625 0 13.715 6.1523 13.715 13.719zm-58.062-34.914 3.2305-72.504c3.5234 0.35156 7.0898 0.53125 10.695 0.53125 3.6094 0 7.1797-0.17969 10.703-0.53125l3.1875 72.504zm13.926-93.164c-43.781 0-80.551-32.809-85.523-76.312l-11.82-103.43c-0.43359-3.793 0.72656-7.4492 3.2695-10.301 2.5391-2.8477 6.043-4.418 9.8633-4.418h168.42c3.8203 0 7.3203 1.5703 9.8633 4.418 2.543 2.8516 3.7031 6.5078 3.2695 10.301l-11.82 103.43c-4.9688 43.504-41.734 76.312-85.523 76.312zm169.14-124.96c0 43.445-31.648 79.633-73.102 86.727 5.4375-10.914 9.0859-22.926 10.543-35.672l10.434-91.285h29.633c12.402 0 22.492 10.09 22.492 22.488z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">
											Accountability
										</h6>
										<ul>
											<li>
												At Radical Minds, we believe in
												building a culture of
												accountability across the
												organization.
											</li>
											<li>
												Accountability, therefore, is
												not an individual
												department-to-team thing but a
												value that flows through the
												entire organisation.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<defs>
														<clipPath id="a">
															<path d="m320 139.21h111v221.79h-111z" />
														</clipPath>
													</defs>
													<g clipPath="url(#a)">
														<path d="m370.66 360.89 60.316-125.94-45.996-6.2812v-93.348l-64.473 127.45 48.711 3.9961zm-0.48438-163.52v44.219l38.688 5.2852-24.363 50.871-0.67969-44.625-40.176-3.2891z" />
													</g>
													<path d="m376 593.06c74.926 0 135.88-64.152 135.88-143.01v-114.46h-86.176v114.46c0 31.34-22.297 56.836-49.695 56.836-27.398 0-49.695-25.496-49.695-56.836l-0.003906-114.46h-86.176v114.46c0 78.859 60.953 143.01 135.87 143.01zm64.496-242.66h56.578v25.051h-56.578zm-185.57 0h56.578v25.051h-56.578zm0 39.848h56.578v59.809c0 39.5 28.934 71.633 64.496 71.633s64.496-32.133 64.496-71.633v-59.809h56.578v59.809c0 70.695-54.312 128.21-121.07 128.21-66.758 0-121.07-57.516-121.07-128.21z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">
											Collaboration
										</h6>
										<ul>
											<li>
												One of the biggest contributors
												to the success of Radical Minds
												is in nurturing the ability of
												our employees to perform
												together as a team.
											</li>
											<li>
												We can say confidently that
												through collaboration we
												multiply our contribution.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 co-12"></div>

								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													width="752pt"
													height="752pt"
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m376 158.15c-120.14 0-217.85 97.703-217.85 217.85s97.703 217.85 217.85 217.85 217.85-97.703 217.85-217.85-97.703-217.85-217.85-217.85zm0 28.414c104.79 0 189.43 84.645 189.43 189.43s-84.645 189.43-189.43 189.43-189.43-84.645-189.43-189.43 84.645-189.43 189.43-189.43zm-75.773 94.715c-18.309 0-33.152 14.844-33.152 33.152s14.84 33.152 33.152 33.152c18.309 0 33.152-14.844 33.152-33.152s-14.844-33.152-33.152-33.152zm151.55 0c-18.309 0-33.152 14.844-33.152 33.152s14.84 33.152 33.152 33.152c18.309 0 33.152-14.844 33.152-33.152s-14.844-33.152-33.152-33.152zm-181.44 137.34c-4.5664 0.26562-8.7344 2.8438-11.246 7.3984-2.3984 4.582-2.0742 10.414 0.88672 14.652 25.395 37.988 67.941 63.195 116.03 63.195s90.633-25.203 116.03-63.195c4.1445-6.1992 2.3516-15.539-3.8477-19.684-6.1992-4.1445-15.684-2.3516-19.832 3.8477-20.512 30.684-54.137 50.613-92.348 50.613s-71.836-19.93-92.348-50.613c-3.8477-4.5117-8.7539-6.4805-13.32-6.2148z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">Fun</h6>
										<ul>
											<li>
												At Radical Minds, using humour
												and having fun is an important
												part of our company's culture
												hence work and fun goes
												together.
											</li>
											<li>
												All tasks allocated are designed
												to challenge individuals and
												teams to help realise their true
												potential.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-12 valueContainer">
									<div className="val__wrapper">
										<div className="top_icon">
											<div className="icon">
												<svg
													width="752pt"
													height="752pt"
													version="1.1"
													viewBox="0 0 752 752"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="m395.74 186.55c-10.055 1.25-15.578 7.4023-20.129 14.945-10.344 19.68-16.398 41.652-21.164 62.75l-60.383 68.227h-51.059c-11.688 0-22.902 3.0938-30.93 11.098-8.0312 8.0039-11.246 19.328-11.246 31.078v117.8c0 11.859 3.043 23.348 11.395 31.375 8.1367 7.8203 19.664 10.395 31.375 9.9141 40.328 0.71875 81.918 9.9219 116.18 19.238 32.07 8.7148 63.508 16.426 102.41 10.211 17.395-2.3008 33.535-12.594 42.918-23.383 10.16-11.934 17.016-28.949 14.801-47.359 11.305-11.973 15.363-29.066 10.953-46.324 7.4414-7.0625 11.949-16.242 13.172-25.309 1.1953-8.8633-0.68359-17.242-3.8477-25.16 10.695-15.371 13.426-34.75 8.8789-52.094-5.3672-20.473-23.371-39.516-48.84-39.516h-48.984c12.543-28.52 18.707-53.348 13.762-75.031-3.0586-13.406-11.449-25.262-23.68-32.41-12.227-7.1523-27.34-10.062-45.582-10.062zm4.5859 28.859c12.211 0.54297 21.324 2.6641 26.641 5.7734 6.0469 3.5352 8.7852 7.3008 10.359 14.207 5.3281 21.117-13.609 63.852-31.523 97.23h94.422c12.586 0 18.5 6.918 21.461 18.203 2.918 19.043-3.8867 29.109-15.984 40.996 5.2773 7.418 11.223 16.457 10.211 25.16-3.1602 10.996-10.453 12.312-20.57 17.316 8.8828 11.785 14.453 33.301-6.8086 47.359 6.2188 21.266 3.125 29.828-5.0312 39.664-2.9609 5.2695-14.801 12.754-23.68 13.469-34.141 5.9609-60.23-0.37109-92.645-9.1758-19.488-5.2969-41.008-11.445-66.598-15.539v-149.18h6.3633c25.965-29.227 50.742-57.688 73.406-83.023 5.4766-21.383 10.207-45.039 19.98-62.453zm-165.75 260.02c7.8477 0 14.207 6.3594 14.207 14.207 0 7.8477-6.3594 14.207-14.207 14.207-7.8477 0-14.207-6.3594-14.207-14.207 0-7.8477 6.3594-14.207 14.207-14.207z" />
												</svg>
											</div>
										</div>

										<h6 className="val__title">
											Caring & Honesty
										</h6>
										<ul>
											<li>
												As a company, we pledge to act
												with compassion, integrity,
												honesty, and high ethics, we
												will listen to others with the
												utmost respect and will value
												differences.
											</li>
											<li>
												The Company is also committed to
												caring about the environment we
												exist in, strengthening the
												social bond, and positively
												contributing to the communities
												around us.
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 co-12"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default OurValues;
