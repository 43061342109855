import React from "react";
import { dots, Favicon } from "../../assets";

const TeamModal = ({ team, active, closeModal }) => {
	const toogleClass = active ? "active" : "";

	return (
		<>
			<div className={`modal teamModal-container ${toogleClass}`}>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button
							className="btn-close"
							aria-label="Close"
							onClick={() => closeModal(false)}></button>

						<div className="modal-body teamModal">
							<div className="teamProfile">
								<div className="team-img">
									<img
										src={team.img}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div className="d-flex">
									<div className="">
										<h4 className="team-name">
											{team.name}
										</h4>
										<p className="des">{team.designaton}</p>
									</div>
									{/* <div className="linkdicon">
										<a
											href={team.linkedin}
											className="social-icon">
											<GrLinkedin />
										</a>
									</div> */}
								</div>
							</div>
							<div className="team-content">
								<div className="d-flex">
									<img
										src={Favicon}
										alt=""
										className="img-fluid logo-icon"
									/>
									<img
										src={dots}
										alt=""
										className="img-fluid dots-icon"
									/>
									{/* <div className="">
										<h4 className="team-name">
											{team.name}
										</h4>
										<p className="des">{team.designaton}</p>
									</div>
									<div className="">
										<a
											href={team.linkedin}
											className="social-icon">
											<GrLinkedin />
										</a>
									</div> */}
								</div>
								<p className="para">{team.bio}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TeamModal;
