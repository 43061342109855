import { Link } from "react-router-dom";
import {
	MakeMyTrip,
	bsfi1,
	Logi1,
	travel3,
	travel2,
	Logi4,
	hel5,
} from "../../../assets";
export const ProductsPages = [
	// "cloud-contact-center
	{
		slag: "cloud-contact-center",
		pagedata: [
			{
				metaDescription:
					"We offer professional cloud contact center solutions for seamless 24/7 virtual customer support across multiple channels, boosting customer satisfaction.",
				sectitle: "Call Center Solution",
				title: "Cloud Contact Center Services | Remote Call Center Solutions India",
				subtitle: "Cloud Contact Center",
				BannerTitle: ["Cloud Contact Center"],
				bannerImg: "../assets/product/header/cloud-contact-center.jpg",
				benfits: [
					{
						list: [
							{
								title: "Omnichannel communication",
							},
							{
								title: "Better uptime",
							},
							{
								title: "Lower costs",
							},
						],
					},
					{
						list: [
							{
								title: "Reduced burden on IT",
							},
							{
								title: "Scalability",
							},
							{
								title: "Business continuity",
							},
						],
					},
					{
						list: [
							{
								title: "Agility",
							},
							{
								title: "Improved customer experience",
							},
							{
								title: "Improved agent experience",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Cloud Contact Center is a customer service solution that operates on a cloud computing infrastructure. It provides businesses with the ability to manage and route customer interactions through multiple communication channels such as voice, chat, email, and social media. The solution is hosted on remote servers and accessed through a web browser, allowing organizations to access their contact center from anywhere with an internet connection.",
							<br />,
							<br />,
							"Cloud Contact Center software offers many benefits, such as increased scalability, reliability, and accessibility, as well as lower costs and increased agility compared to traditional on-premises contact center solutions. Cloud Contact Center software for contact centers provides a comprehensive solution for businesses looking to improve customer experience and streamline customer service operations.",
						],
						deskImg:
							"../../assets/product/desktop/cloud-contact-center.jpg",
						alt: "Cloud Contact Center Software",
						mobImg: "../../assets/product/mobile/cloud-contact-center.jpg",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "blended-call-center
	{
		slag: "blended-call-center",
		pagedata: [
			{
				metaDescription:
					"We provide ease of communication between agents and customers with intelligent dialers through our blended call center software solutions as per their requirements.",
				sectitle: "Call Center Solution",
				title: "Intelligent Dialer Software | Blended Call Center Solutions India",
				subtitle: "Blended Call Center",
				BannerTitle: ["Blended Call Center"],
				bannerImg: "../assets/product/header/blended-call-center.jpg",
				benfits: [
					{
						list: [
							{
								title: "Intelligent Dialer Function",
							},
							{
								title: "Call Barge-In",
							},
							{
								title: "Live Reporting and Analytics",
							},
						],
					},
					{
						list: [
							{
								title: "Call Recording",
							},
							{
								title: "Agents Extensions",
							},
							{
								title: "Call Transfer",
							},
						],
					},
					{
						list: [
							{
								title: "Support for Work-at-Home Agents",
							},
							{
								title: " More flexibility",
							},
							{
								title: "High efficiency and teamwork",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"A Blended Call Center is a contact center in which customer service representatives can handle both incoming calls from customers and make outgoing calls. The goal of a Blended Call Center is to provide customers with a seamless and convenient experience by offering them multiple options to connect with stakeholders. With a Blended Call Center, customers can choose the channel they prefer, whether it be a voice call, email, or live chat.",
							<br />,
							<br />,
							"Our Blended Call Center solutions are equipped with advanced technology that allows agents to handle multiple interactions at once, providing customers with quick and efficient support. By offering multiple channels, a Blended Call Center software ensures that customers can reach out at their convenience, regardless of the time of day or their location.",
						],
						deskImg:
							"../../assets/product/desktop/blend-call-center.jpg",
						alt: "Blended Call Center Software",
						mobImg: "../../assets/product/mobile/blended-call-center.jpg",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: [
									"A Blended Call Center is a contact center in which customer service representatives can handle both incoming calls from customers and make outgoing calls. The goal of a Blended Call Center is to provide customers with a seamless and convenient experience by offering them multiple options to connect with stakeholders. With a Blended Call Center, customers can choose the channel they prefer, whether it be a voice call, email, or live chat.",
									<br />,
									<br />,
									"Our Blended Call Center solutions are equipped with advanced technology that allows agents to handle multiple interactions at once, providing customers with quick and efficient support. By offering multiple channels, a Blended Call Center software ensures that customers can reach out at their convenience, regardless of the time of day or their location.",
								],
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "Outbound Call Center
	{
		slag: "outbound-call-center",
		pagedata: [
			{
				metaDescription:
					"Our team engages customers with outbound call center solutions, reaching out for business prospects through outbound contact center services.",
				sectitle: "Call Center Solution",
				title: "Outbound Call Center Services | Contact Center Software | Calling Solutions India",
				subtitle: "Outbound Call Center",
				BannerTitle: ["Outbound Call Center"],
				bannerImg: "../assets/product/header/outbound-call-center.jpg",
				benfits: [
					{
						list: [
							{
								title: "Reach the Customers on their Schedule",
							},
							{
								title: "Improve customer acquisition ",
							},
						],
					},
					{
						list: [
							{
								title: "Customer loyalty will surge",
							},
							{
								title: "Enhance the productivity of employees",
							},
						],
					},
					{
						list: [
							{
								title: "Lead generation and sales calls to prospects",
							},
							{
								title: "Scheduled customer callbacks",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"An Outbound Call Center is a type of call center that makes outgoing calls to customers, clients, or prospects to offer products or services, conduct surveys, or follow up on previous purchases. This type of center operates on a predetermined script and call list, reaching out to potential customers or clients to provide them with information and offers. The goal of an Outbound Call Center service is to increase sales, generate leads, and improve customer relationships.",
							<br />,
							<br />,
							"Our Outbound Call Center software is equipped with the latest technology and tools to effectively reach and engage with customers, including automatic dialing systems, call monitoring and recording, and customer relationship management software.",
						],
						deskImg:
							"../../assets/product/desktop/outbound-call-center.jpg",
						mobImg: "../../assets/product/mobile/outbound-call-center.jpg",
						alt: "Outbound Call Center Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "inbound-call-center
	{
		slag: "inbound-call-center",
		pagedata: [
			{
				metaDescription:
					"We are providing exceptional 24/7 customer support outsourcing, our AI-powered inbound call center & contact center software delivers enhanced customer experience for various industries in Overseas.",
				sectitle: "Call Center Solution",
				title: "Inbound Outsourcing Call Center Services | AI-Powered Contact Center Software India",
				subtitle: "Inbound Call Center",
				BannerTitle: ["Inbound Call Center"],
				bannerImg: "../assets/product/header/blended-call-center.jpg",
				benfits: [
					{
						list: [
							{
								title: "Improve Productivity",
							},
							{
								title: "Cost Savings",
							},
							{
								title: "Increases Sales",
							},
						],
					},
					{
						list: [
							{
								title: "Customer-Focused",
							},
							{
								title: "Customer Satisfaction",
							},
							{
								title: "Win Back Lost Customers",
							},
						],
					},
					{
						list: [
							{
								title: "Free Up Resources",
							},
							{
								title: "Managing Higher Call Volumes",
							},
							{
								title: "Connecting To Global Time Zones",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Inbound Call Centers are customer service departments that handle incoming calls from customers seeking assistance or information about a company's products or services and solving their issues. They play a critical role in improving customer experience and building customer loyalty by providing timely and effective solutions to customer inquiries.",
							<br />,
							<br />,
							"Inbound Call Center software typically employs a team of trained customer service representatives who are equipped with Domain expertise with latest technology and tools to handle customer calls efficiently. Some of the common services offered by Inbound Call Centers include order taking, technical support, customer service inquiries, and complaint resolution.",
							<br />,
							<br />,
							"Our Inbound Call Center service operates 24/7 to ensure that customers can get the help they need whenever they need it. These centers improve the customer experience and reduce the workload on other departments, such as sales and marketing.",
						],
						deskImg:
							"../../assets/product/desktop/inbound-call-center.jpg",
						mobImg: "../../assets/product/mobile/inbound-call-center.jpg",
						alt: "Inbound Call Center Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "cti-solutions
	{
		slag: "cti-solutions",
		pagedata: [
			{
				metaDescription:
					"CTI solutions allow seamless communication between a computer and a telephone system to improve the customer experience.",
				sectitle: "Call Center Solution",
				title: "CTI Solutions | CTI Integration Services India",
				subtitle: "CTI Solutions",
				BannerTitle: ["CTI Solutions"],
				bannerImg: "../assets/product/header/cti.jpg",
				benfits: [
					{
						list: [
							{
								title: "Reduce Costs & Increase Productivity",
							},
							{
								title: "Deliver Personalized Customer Experiences",
							},
							{
								title: "Deliver Personalized Customer Experiences",
							},
						],
					},
					{
						list: [
							{
								title: "Informed and More Efficient Users",
							},
							{
								title: "Call Reporting",
							},
							{
								title: "Improved Call Handling",
							},
						],
					},
					{
						list: [
							{
								title: "Enhanced Caller Experience",
							},
							{
								title: "Increased Professionalism",
							},
							{
								title: "Improves Remote Working",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Computer Telephony Integration (CTI) solutions for websites are software systems that allow for the seamless integration of telephony functions within a website. CTI Solutions provide a unified and efficient experience for customers by enabling them to initiate voice calls or interact with ",
							<Link to="/services/ivr" className="interLink">
								Interactive Voice Response (IVR) systems
							</Link>,
							" directly from their web browsers.",
							<br />,
							<br />,
							"Our CTI Solution services can significantly enhance the customer experience and improve the efficiency of call center operations. By providing access to customer information and call controls directly from a computer, CTI Integration services can help customers and agents work more effectively and provide faster, more personalized service to customers.",
						],
						deskImg: "../../assets/product/desktop/cti.jpg",
						mobImg: "../../assets/product/mobile/cti.jpg",
						alt: "CTI Solutions, CTI Solutions Service, CTI Integration Services",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "cloud-telephony
	{
		slag: "cloud-telephony",
		pagedata: [
			{
				metaDescription:
					"Cloud telephony solution service in India helps businesses in managing calls, voicemails & faxes through a web-based interface for increased flexibility & scalability.",
				sectitle: "Call Center Solution",
				title: "Cloud Telephony Solutions | Cloud Telephony Services India",
				subtitle: "Cloud Telephony",
				BannerTitle: ["Cloud Telephony"],
				bannerImg: "../assets/product/header/cloud-telephony.jpg",
				benfits: [
					{
						list: [
							{
								title: "Easy Implementation",
							},
							{
								title: "Flexible",
							},
							{
								title: "Secure & Reliable",
							},
						],
					},
					{
						list: [
							{
								title: "Scalable",
							},
							{
								title: "Real-Time Notification",
							},
							{
								title: "Cost-effective",
							},
						],
					},
					{
						list: [
							{
								title: "24*7 Access",
							},
							{
								title: "Maximum Uptime",
							},
							{
								title: "Create Brand Reputation",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Cloud Telephony refers to a technology that enables businesses to manage their telephone systems and communications through the internet. Instead of using traditional telecommunication systems, Cloud Telephony solutions rely on cloud-based infrastructure to route and process phone calls. giving businesses the flexibility and scalability they need to manage their communications effectively.",
							<br />,
							<br />,
							"Our Cloud Telephony is widely used by organizations of all sizes, from small businesses to large enterprises. Cloud Telephony solution for businesses offers a flexible, scalable, and cost-effective solution for managing telephone systems and communications.",
						],
						deskImg:
							"../../assets/product/desktop/cloud-telephony.jpg",
						mobImg: "../../assets/product/mobile/cloud-telephony.jpg",
						alt: "Cloud Telephony Solution for Businesses",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "automated-telemarketing
	{
		slag: "automated-telemarketing",
		pagedata: [
			{
				metaDescription:
					"Automated telemarketing software services allow call center solutions to provide automatic assistance for customers via IVR services without agent intervention.",
				sectitle: "Call Center Solution",
				title: "Telemarketing Services | Automated Call Center Services India",
				subtitle: "Automated Telemarketing",
				BannerTitle: ["Automated Telemarketing"],
				bannerImg:
					"../assets/product/header/automated-telemarketing.jpg",
				benfits: [
					{
						list: [
							{
								title: "A more efficient way to find clients",
							},
							{
								title: "Reduce the need for expensive staff",
							},
							{
								title: "Up-to-date marketing statistics",
							},
						],
					},
					{
						list: [
							{
								title: "Accurate and trustworthy data about your customers",
							},
							{
								title: "Less risk of losing business due to false information",
							},
							{
								title: "More interaction with clients and therefore more sales",
							},
						],
					},
					{
						list: [
							{
								title: "Full control over your marketing approach",
							},
							{
								title: "Efficient communication with all necessary users",
							},
							{
								title: "Accurate communication and targeting through automation",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Automated Telemarketing is a type of telemarketing that uses technology to make sales calls, usually through a computerized system. The system automates the dialing process and delivers pre-recorded messages to prospective customers. The technology can also collect information about the prospects, such as their response to the call, and provide real-time updates to sales agents.",
							<br />,
							<br />,
							"Our Automated Telemarketing software can save businesses time and resources while still reaching a large audience. Automated call center services personalize the customer experience by providing relevant information and offering personalized solutions. Additionally, Automated Call Center solutions can allow for quick and efficient follow-up calls to prospects and can also gather data from each call, allowing businesses to track their performance and adjust their strategies as needed.",
						],
						deskImg:
							"../../assets/product/desktop/automated-telemarketing.jpg",
						mobImg: "../../assets/product/mobile/automated-telemarketing.jpg",
						alt: "Automated Telemarketing Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "crm-integration
	{
		slag: "crm-integration",
		pagedata: [
			{
				metaDescription:
					"Cloud CRM integration software service solution with WhatsApp and other platforms benefits businesses by monitoring and analyzing customer data across various channels.",
				sectitle: "Call Center Solution",
				title: "CRM Software Development Services | CRM With WhatsApp Integration India",
				subtitle: "CRM Integration",
				BannerTitle: ["CRM Integration"],
				bannerImg: "../assets/product/header/crm.jpg",
				benfits: [
					{
						list: [
							{
								title: "It simplifies recordkeeping",
							},
							{
								title: "Helps with productivity and workflow",
							},
							{
								title: "Helps with customer satisfaction",
							},
						],
					},
					{
						list: [
							{
								title: "Fast implementation time",
							},
							{
								title: "Provides insights that can improve your sales",
							},
							{
								title: "Saves time and money",
							},
						],
					},
					{
						list: [
							{
								title: "Improves customer experience",
							},
							{
								title: "Increases your market share",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our CRM Integration refers to the process of integrating a dialer with any CRM system to streamline and automate customer interactions and data management. CRM Integration for call centers allows the seamless transfer of customer data. The data collected through this integration can then be used to personalize the customer experience, create targeted marketing campaigns, and improve overall business efficiency.",
							<br />,
							<br />,
							"CRM Integration software helps automate various tasks and processes, reducing manual efforts and improving efficiency. Businesses can centralize customer information and streamline their customer interactions through CRM Integration services, leading to improved customer satisfaction and increased sales.",
						],
						deskImg: "../../assets/product/desktop/crm.jpg",
						mobImg: "../../assets/product/mobile/crm.jpg",
						alt: "CRM integration software solutions",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "ivr
	{
		slag: "ivr",
		pagedata: [
			{
				metaDescription:
					"IVR call center software services automate customer interactions by providing self-service options and routing calls to the right agent, enhancing efficiency and CX.",
				sectitle: "Dialer Solution",
				title: "IVR Software | Interactive Voice Response Calling Software India",
				subtitle: "Interactive Voice Response (IVR)",
				BannerTitle: ["Interactive Voice Response (IVR)"],
				bannerImg: "../../assets/product/header/ivr.jpg",
				benfits: [
					{
						list: [
							{
								title: "Better customer experience",
							},
							{
								title: "Increase customer service efficiency",
							},
							{
								title: "Reduce operational costs",
							},
						],
					},
					{
						list: [
							{
								title: "Increase professionalism",
							},
							{
								title: "No more connection errors",
							},
							{
								title: "Faster processing",
							},
						],
					},
					{
						list: [
							{
								title: "Intelligent Call Routing",
							},
							{
								title: "Measuring Customer Satisfaction",
							},
							{
								title: "Non-stop availability",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"IVR software services enable organizations to automate customer interactions and provide self-service options for common inquiries, reducing the need for live agents. Our IVR systems are typically integrated into call centers, allowing customers to access information, resolve issues, and complete transactions through automated menus and prompts.",
							<br />,
							<br />,
							"The system uses pre-recorded or synthesized speech to guide customers through the various options, and can also route calls to live agents when necessary. It can be customized to meet the specific needs of a business and can be integrated with other technologies such as CRM, IVR scripting software, and voice recognition software.",
							<br />,
							<br />,
							"IVR software is a valuable tool for all business sizes to improve customer service, streamline operations, and gain valuable insights into customer behavior.",
						],
						deskImg: "../../assets/product/desktop/ivr.jpg",
						mobImg: "../../assets/product/mobile/ivr.jpg",
						alt: "IVR software services",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "click-to-call
	{
		slag: "click-to-call",
		pagedata: [
			{
				metaDescription:
					"Customers can initiate phone calls directly from the website through click-to-call software solutions for businesses to improve CX and sales potential.",
				sectitle: "Dialer Solution",
				title: "Click To Call Services for Business | Click to Call Software Solutions India",
				subtitle: "Click to Call",
				BannerTitle: ["Click to Call"],
				bannerImg: "../assets/product/header/click-to-call.jpg",
				benfits: [
					{
						list: [
							{
								title: "Gather customer data",
							},
							{
								title: "It can drive more conversions",
							},
							{
								title: "It captures mobile users",
							},
						],
					},
					{
						list: [
							{
								title: "Improve customer segmentation",
							},
							{
								title: "Customers more access points to you",
							},
							{
								title: "Increases Customer Loyalty",
							},
						],
					},
					{
						list: [
							{
								title: "Opens Door for More Sales and Enquiry",
							},
							{
								title: "It provides a tailored experience",
							},
							{
								title: "It aligns with your industry",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Click to Call is a feature that allows website visitors to initiate a phone call to a specific number by clicking a button or a link on a website. The feature uses either Voice Over Internet Protocol (VoIP) technology or the device's native phone capabilities to initiate the call.",
							<br />,
							<br />,
							"The main benefit of our Click to Call software is convenience, as it eliminates the need for a user to manually dial the phone number, which can be especially useful on mobile devices where typing can be difficult. Click to Call solutions also provide a quick and easy way for customers to contact a business directly from their website.",
							<br />,
							<br />,
							"Click to Call software for business is useful to improve customer engagement and customer service, as it provides a quick and convenient way for customers to reach out for help or support. The feature can increase conversions, and customer satisfaction and reduce customer service costs by allowing quick and easy communication with a business.",
						],
						deskImg:
							"../../assets/product/desktop/click-to-call.jpg",
						mobImg: "../../assets/product/mobile/click-to-call.jpg",
						alt: "Click-to-Call Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "auto-dialer
	{
		slag: "auto-dialer",
		pagedata: [
			{
				metaDescription:
					"Auto Dialer Software - By automating the process of placing call center calls, auto dialer software service improves CX by reducing wait times and ensuring that calls are answered promptly.",
				sectitle: "Dialer Solution",
				title: "Power Auto Dialer Software Service Provider | Automated Call Center Dialing for Telemarketing",
				subtitle: "Auto Dialer",
				BannerTitle: ["Auto Dialer"],
				bannerImg: "../assets/product/header/auto-dialer.jpg",
				benfits: [
					{
						list: [
							{
								title: "Save time",
							},
							{
								title: "Immediate results",
							},
							{
								title: "No high costs",
							},
						],
					},
					{
						list: [
							{
								title: "Easy to set up and operate",
							},
							{
								title: "High quality results",
							},
							{
								title: "Speed Dialer",
							},
						],
					},
					{
						list: [
							{
								title: "Call Recording and Logging features",
							},
							{
								title: "Customer friendly",
							},
							{
								title: "Auto-dial a list of numbers, a range of phone numbers in seconds",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Auto Dialer is a software that automatically dials phone numbers from a list or database and connects callers with live agents or pre-recorded messages. It is commonly used in telemarketing, lead generation, and customer service operations.",
							<br />,
							<br />,
							"Auto Dialer software integrates with the website to automatically dial out to leads or customers who have expressed interest in the business. The dialer uses the customer's phone number that was provided on the website to initiate the call, eliminating the need for manual dialing. This can save time and increase the efficiency of the sales or customer service process.",
							<br />,
							<br />,
							"Our Auto Dialer software for businesses typically offers features such as call prioritization, call scheduling, call reporting, and call analytics. Some systems also can play pre-recorded messages, transfer calls to live agents or leave voicemail messages. The integration with the website can be done through APIs or by adding a button or a link on the website. Auto Dialer can help businesses to reach more customers, improve communication, and increase efficiency while providing a better customer experience.",
						],
						deskImg: "../../assets/product/desktop/auto-dialer.jpg",
						mobImg: "../../assets/product/mobile/auto-dialer.jpg",
						alt: "Auto Dialer Software for Businesses",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "auto-predictive-dialer
	{
		slag: "auto-predictive-dialer",
		pagedata: [
			{
				metaDescription:
					"Using auto-predictive dialer solutions, businesses can make multiple outbound calls at once and predict which calls are most likely to be answered to boost efficiency.",
				sectitle: "Dialer Solution",
				title: "Auto Predictive Dialer Solutions| Auto Predictive Dialer Solutions India",
				subtitle: "Auto Predictive Dialer",
				BannerTitle: ["Auto Predictive Dialer"],
				bannerImg:
					"../assets/product/header/auto-predictive-dialer.jpg",
				benfits: [
					{
						list: [
							{
								title: "Increased Productivity",
							},
							{
								title: "Boost in Sales",
							},
							{
								title: "Increased Lead Generation",
							},
						],
					},
					{
						list: [
							{
								title: "Enhanced Customer Service",
							},
							{
								title: "Smarter and Stronger Lead Management",
							},
							{
								title: "Reduced Agent Idle Time",
							},
						],
					},
					{
						list: [
							{
								title: "Reduced Cost of Operations",
							},
							{
								title: "Improved Quality Monitoring",
							},
							{
								title: "Increased Agent Efficiency",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"An Auto Predictive Dialer is a type of automated dialing system that predicts the next call outcome and optimizes the calling process accordingly. This dialer uses algorithms to analyze call data, including call response time, talk time, and call disconnect rates, to determine the best time to dial a number and connect with a live person.",
							<br />,
							<br />,
							"By using predictive dialing, our Auto Predictive Dialer solutions can significantly increase the efficiency and productivity of outbound call centers. The dialer can make multiple calls at the same time and eliminate the need for manual dialing, freeing up agents to focus on other tasks.",
							<br />,
							<br />,
							"Additionally, Auto Predictive Dialer services can be integrated with a CRM or database to access customer information, personalize calls, and improve the customer experience. The dialer can also be configured to play pre-recorded messages, transfer calls, and track call data for analysis and performance tracking. Furthermore, Auto Predictive Dialer software can be configured to avoid dialing numbers on the National Do Not Call Registry, helping businesses to comply with telemarketing regulations.",
						],
						deskImg:
							"../../assets/product/desktop/auto-predictive-dialer.jpg",
						mobImg: "../../assets/product/mobile/auto-predictive-dialer.jpg",
						alt: "Best Auto Predictive Dialer software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "predictive-dialer
	{
		slag: "predictive-dialer",
		pagedata: [
			{
				metaDescription:
					"Cloud-based virtual predictive dialer system software solution improves productivity and CX by making multiple calls at once possible and immediate routing.",
				sectitle: "Dialer Solution",
				title: "Cloud-Based Virtual Predictive Dialer Solution | Hosted Predictive Dialing Technology India",
				subtitle: "Predictive Dialer",
				BannerTitle: ["Predictive Dialer"],
				bannerImg: "../../assets/product/header/predictive-dialer.jpg",
				benfits: [
					{
						list: [
							{
								title: "Increased Productivity",
							},
							{
								title: "Boost in Sales",
							},
							{
								title: "Increased Lead Generation",
							},
						],
					},
					{
						list: [
							{
								title: "Enhanced Customer Service",
							},
							{
								title: "Smarter and Stronger Lead Management",
							},
							{
								title: "Reduced Agent Idle Time",
							},
						],
					},
					{
						list: [
							{
								title: "Reduced Cost of Operations",
							},
							{
								title: "Improved Quality Monitoring",
							},
							{
								title: "Increased Agent Efficiency",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"A Predictive Dialer is a type of automated dialing system used by call centers to optimize the outbound calling process. The dialer uses algorithms to analyze call data and predict the next call outcome, such as the likelihood of reaching a live person or a busy signal. Based on this analysis, the dialer can automatically dial numbers and route calls to agents in real time, reducing the time agents spend waiting for calls and increasing the number of live connections made per hour.",
							<br />,
							<br />,
							"Our Predictive Dialer software can also be integrated with a CRM or database to access customer information, personalize calls, and improve the customer experience. They can also play pre-recorded messages, transfer calls, and track call data for analysis and performance tracking.",
							<br />,
							<br />,
							"Overall, Predictive Dialer software solutions can significantly increase the efficiency and productivity of ",
							<Link
								to="/services/outbound-call-center"
								className="interLink"
							>
								outbound call centers
							</Link>,
							", reducing manual dialing time and freeing up agents to focus on other tasks.",
						],
						deskImg:
							"../../assets/product/desktop/predictive-dialer.jpg",
						mobImg: "../../assets/product/mobile/predictive-dialer.jpg",
						alt: "Predictive Dialer software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "acd-smart-routing
	{
		slag: "acd-smart-routing",
		pagedata: [
			{
				metaDescription:
					"Businesses utilize automatic call routing software to route incoming calls to the appropriate person or department for a better customer experience.",
				sectitle: "Dialer Solution",
				title: "Automatic Call Distributor Software | Call Routing Software Services for Business",
				subtitle: "ACD/Smart Routing",
				BannerTitle: ["ACD/Smart Routing"],
				bannerImg: "../../assets/product/header/acd-smart-routing.jpg",
				benfits: [
					{
						list: [
							{
								title: "Improves customer experience",
							},
							{
								title: "Reduces time spent on calls",
							},
							{
								title: "Increases staff performance",
							},
						],
					},
					{
						list: [
							{
								title: "Reduces cost per call sold",
							},
							{
								title: "Improves service delivery times",
							},
							{
								title: "Eliminates the need for manual handling of calls",
							},
						],
					},
					{
						list: [
							{
								title: "Integrates with CRM and ticketing systems",
							},
							{
								title: "Maintains control and management over phone calls",
							},
							{
								title: "Reduces staff costs",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"ACD (Automatic Call Distribution) is a call center technology that routes incoming calls to a specific group of agents based on various factors such as skills, availability, and priority. The goal of automatic call distributor software is to ensure that calls are handled efficiently and effectively by the right agents.",
							<br />,
							<br />,
							"Smart Routing is an advanced feature that enhances the capabilities of ACD. It uses sophisticated algorithms to analyze real-time data and dynamically route calls to the best available agent based on a variety of factors such as call volume, wait time, and agent skill level. This results in a more efficient and streamlined call center operation.",
							<br />,
							<br />,
							"Smart Routing can be configured to prioritize certain call types, such as high-priority customer inquiries, and route them to specific agents or groups of agents. Call routing software services can also consider agent availability, skills, and workload, allowing for a balanced distribution of call volume and minimizing wait times for customers.",
							<br />,
							<br />,
							"In addition to improving call handling, call routing software for businesses can also provide valuable insights and analytics into call center performance, such as average wait times, call volume trends, and agent utilization. This information can be used to optimize the call center operation and improve the customer experience. However, ACD with Smart Routing is an effective solution for organizations to improve call center efficiency and customer satisfaction.",
							<br />,
							<br />,
						],
						deskImg:
							"../../assets/product/desktop/acd-smart-routing.jpg",
						mobImg: "../../assets/product/mobile/acd-smart-routing.jpg",
						alt: "Automatic call distributor software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "screen-recording
	{
		slag: "screen-recording",
		pagedata: [
			{
				metaDescription:
					"To facilitate training, presentations, and troubleshooting, screen recording software captures and records the action on a computer screen.",
				sectitle: "Dialer Solution",
				title: "Screen Recording | Screen Recording Software India",
				subtitle: "Screen Recording",
				BannerTitle: ["Screen Recording"],
				bannerImg: "../../assets/product/header/screen-recording.jpg",
				benfits: [
					{
						list: [
							{
								title: "Attractive to new clients",
							},
							{
								title: "Keeps team members focused",
							},
							{
								title: "Saves time",
							},
						],
					},
					{
						list: [
							{
								title: "Tightens security",
							},
							{
								title: "Monitor in real time",
							},
							{
								title: "Convenient tool",
							},
						],
					},
					{
						list: [
							{
								title: "Instructions and training",
							},
							{
								title: "Low cost solution",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Screen Recording feature is a process of capturing a video of what is happening on a computer screen. It is used to create tutorials, demonstrations, and other instructional videos. Screen Recording can be done with dedicated software, built-in tools on some operating systems, or online tools available on websites.",
							<br />,
							<br />,
							"The recording process typically involves selecting the recording area, adjusting recording settings such as audio capture, and starting the recording process. Once the recording is complete, the video can be edited, trimmed, and saved in the desired format. Screen Recording software is useful for creating engaging and informative videos for websites and other digital media platforms.",
						],
						deskImg:
							"../../assets/product/desktop/screen-recording.jpg",
						mobImg: "../../assets/product/mobile/screen-recording.jpg",
						alt: "screen recording software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "toll-free-number-service
	{
		slag: "toll-free-number-service",
		pagedata: [
			{
				metaDescription:
					"Provide customers with a free and convenient way to contact them by phone, typically using a 1-800 number with a toll-free number service solution for businesses.",
				sectitle: "Dialer Solution",
				title: "Toll Free Services For Businesses | Toll Free Number Solutions India",
				subtitle: "Toll Free Number Service",
				BannerTitle: ["Toll Free Number Service"],
				bannerImg: "../../assets/product/header/toll-free.jpg",
				benfits: [
					{
						list: [
							{
								title: "Increased revenue",
							},
							{
								title: "Improved customer service",
							},
							{
								title: "Reduced operation costs",
							},
						],
					},
					{
						list: [
							{
								title: "Better customer relations",
							},
							{
								title: "Higher client retention",
							},
							{
								title: "More profits for your business",
							},
						],
					},
					{
						list: [
							{
								title: "Enhanced customer satisfaction levels",
							},
							{
								title: "Simplifies business transactions",
							},
							{
								title: "Attracts more customers to your company",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"A Toll-Free Number is a telephone number with a distinct multi-digit code that can be dialed from landlines at no charge to the person placing the call. Toll-Free Numbers are usually provided by businesses to customers as a convenient and cost-free method to contact them. The cost of the call is borne by the calling party (the toll-free subscriber) instead of the calling party.",
							<br />,
							<br />,
							"Toll-Free Number service can increase the credibility and professionalism of a business, as well as improve customer satisfaction by providing a convenient way for customers to reach the company. They can also be used for tracking marketing campaigns, directing call traffic to specific departments, and for customer service purposes.",
							<br />,
							<br />,
							"Our Toll-Free services for businesses provide a cost-effective and convenient way to communicate with customers and improve their overall customer experience.",
						],
						deskImg:
							"../../assets/product/desktop/toll-free-number-service.jpg",
						mobImg: "../../assets/product/mobile/toll-free-number-service.jpg",
						alt: "Toll Free Number Service",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "conversational-ai
	{
		slag: "conversational-ai",
		pagedata: [
			{
				metaDescription:
					"Conversational AI platform software provides customers with natural language-based interactions through chatbots or virtual assistants to improve customer experience.",
				sectitle: "Customer Connect",
				title: "Best Conversational AI Platforms | Conversational AI Software for Windows",
				subtitle: "Conversational AI",
				BannerTitle: ["Conversational AI"],
				bannerImg: "../assets/product/header/converstaional-ai.jpg",
				benfits: [
					{
						list: [
							{
								title: "Enhanced Customer Experience",
							},
							{
								title: "Speed and Efficiency",
							},
							{
								title: "Improves Service Quality",
							},
						],
					},
					{
						list: [
							{
								title: "Decreases Capital Expenditure",
							},
							{
								title: "Increased Productivity",
							},
							{
								title: "Accelerated Time to Market",
							},
						],
					},
					{
						list: [
							{
								title: "Reduces IT Maintenance Costs",
							},
							{
								title: "Access to Expanded Talent Pool",
							},
							{
								title: "Lower Total Cost of Ownership",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Conversational AI refers to the use of Natural Language Processing (NLP) and related technologies to enable computers to have human-like conversations with users. This technology is designed to allow individuals to interact with computers through chatbots, voice assistants, and other conversational interfaces. These interfaces can be used for a variety of purposes, such as customer service, personal assistance, and even entertainment.",
							<br />,
							<br />,
							"One of the main benefits of our Conversational AI software is that it can provide users with quick and convenient access to information and services. By using simple and intuitive language, Conversational AI can help to bridge the gap between people and technology, making it easier for individuals to complete tasks and find the information they need.",
							<br />,
							<br />,
							"To provide a seamless and effective user experience, Conversational AI systems typically use a combination of NLP, machine learning, and other artificial intelligence technologies. These systems can be programmed to understand and respond to a wide range of human language patterns, allowing them to provide relevant and accurate responses to user inquiries.",
							<br />,
							<br />,
							"Conversational AI platforms represent a significant advancement in the field of artificial intelligence and have the potential to revolutionize the way that people interact with computers and other technology-driven systems.",
						],
						deskImg:
							"../../assets/product/desktop/converstaional-ai.jpg",
						mobImg: "../../assets/product/mobile/converstaional-ai.jpg",
						alt: "Conversational AI Platform Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "omnichannel-communication
	{
		slag: "omnichannel-communication",
		pagedata: [
			{
				metaDescription:
					"Omnichannel communication platform software assists contact centers by integrating multiple channels like WhatsApp business messaging chatbot for improved CX.",
				sectitle: "Customer Connect",
				title: "Omnichannel Contact Center Software | Omnichannel Contact Center Software India",
				subtitle: "Omnichannel Communication",
				BannerTitle: ["Omnichannel Communication"],
				bannerImg:
					"../assets/product/header/omnichannel-routing-automation.jpg",
				benfits: [
					{
						list: [
							{
								title: "Increased revenue",
							},
							{
								title: "Improved customer experience",
							},
							{
								title: "Growing interest from consumers",
							},
						],
					},
					{
						list: [
							{
								title: "Efficient working practices and processes",
							},
							{
								title: "Increased cross-channel collaboration",
							},
							{
								title: "Greater adaptability to customer preferences, buying behaviour, and expectations",
							},
						],
					},
					{
						list: [
							{
								title: "Cultural alignment among all employees in the organisation",
							},
							{
								title: "Stronger customer relationships and loyalty",
							},
							{
								title: "Better brand recognition and recall by customers",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Omnichannel Communication is a seamless integration of multiple channels like WhatsApp, email, phone, websites, etc. to provide a consistent customer experience across all touchpoints.",
							<br />,
							<br />,
							"The goal of Omnichannel Communication software is to provide a seamless, consistent, and personalized customer experience, no matter how the customer chooses to engage with a brand. By utilizing multiple channels, businesses can reach customers where they are, at the time they prefer, and with the information they need. This helps to increase customer satisfaction, build brand loyalty and drive sales.",
							<br />,
							<br />,
							"Omnichannel Communication platform also allows businesses to collect valuable customer data, such as purchase history and engagement patterns, which can be used to inform personalized marketing campaigns and improve the overall customer experience.",
							<br />,
							<br />,
							"In conclusion, Omnichannel software for contact centers provides a consistent, individualized customer experience and helps them to stay competitive in today's fast-paced, customer-centric market.",
						],
						deskImg:
							"../../assets/product/desktop/omnichannel-routing-automation.jpg",
						mobImg: "../../assets/product/mobile/omnichannel-routing-automation.jpg",
						alt: "Omnichannel Communication CRM Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "chatbot
	{
		slag: "chatbot",
		pagedata: [
			{
				metaDescription:
					"Boost customer experience and automate repetitive tasks by using AI chatbot software and live chat support for businesses, helping to increase efficiency and reduce costs.",
				sectitle: "Customer Connect",
				title: "AI-Powered Chatbot Software | Live Chat Support India",
				subtitle: "Chatbot",
				BannerTitle: ["Chatbot"],
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/003/187/467/large_2x/young-woman-using-smartphone-typing-chatting-conversation-technology-free-photo.jpg",
				benfits: [
					{
						list: [
							{
								title: "Save time",
							},
							{
								title: "Save Money",
							},
							{
								title: "Improve Customer Experience",
							},
						],
					},
					{
						list: [
							{
								title: "Gain New Customers",
							},
							{
								title: "Keep Customers Engaged",
							},
						],
					},
					{
						list: [
							{
								title: "Reduce calls to Support Center",
							},
							{
								title: "Analyze data from Chatbot conversations",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Chatbots can be integrated into websites as a means of providing quick and convenient customer service or support. Chatbot utilizes Natural Language Processing (NLP) and Artificial Intelligence (AI) algorithms to understand and respond to user inquiries in a human-like manner.",
							<br />,
							<br />,
							"AI-Powered Chatbot software can also be designed to perform specific tasks, such as booking appointments, providing product recommendations, or answering frequently asked questions (FAQs).",
							<br />,
							<br />,
							"By implementing AI Chatbot software on a website, businesses can improve their customer experience, increase efficiency and reduce response time. It can handle simple, routine inquiries 24/7, freeing up human customer service representatives to handle more complex issues. Additionally, Chatbots can gather valuable data and insights about customer preferences and behavior, which can inform future business decisions.",
						],
						deskImg: "../../assets/product/desktop/chatbot.jpg",
						mobImg: "../../assets/product/mobile/chatbot.jpg",
						alt: "AI-Powered Chatbot Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "Customer-journey-experience
	{
		slag: "customer-journey-experience",
		pagedata: [
			{
				metaDescription:
					"Track and analyze customer interactions and behavior with the customer journey management system, providing insights to optimize and personalize the customer experience.",
				sectitle: "Customer Connect",
				title: "Customer Journey Management System | Customer Journey Management Solutions",
				subtitle: "Customer Journey Experience",
				BannerTitle: ["Customer Journey Experience"],
				bannerImg:
					"../../assets/product/header/Customer-journey-experience.jpg",
				benfits: [
					{
						list: [
							{
								title: "Helps focus efforts",
							},
							{
								title: "Identifies weak points",
							},
							{
								title: "Builds loyalty",
							},
						],
					},
					{
						list: [
							{
								title: "Increase revenue",
							},
							{
								title: "Improved customer experience",
							},
							{
								title: "Competitive advantage",
							},
						],
					},
					{
						list: [
							{
								title: "Align with business goals",
							},
							{
								title: "New Service innovation opportunities",
							},
							{
								title: "Better customer experience measurement",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Customer experience is a totality of cognitive, affective, sensory, and behavioral consumer responses during all stages of the consumption process including pre-purchase, consumption, and post-purchase stages.",
							<br />,
							<br />,
							"The goal of enhancing our customer journey management is to create a positive, seamless, and memorable experience that leads to increased customer loyalty and retention. Companies can improve the Customer Journey Experience by understanding their target audience, identifying their pain points, and continuously gathering feedback to personalize and improve the experience.",
						],
						deskImg:
							"../../assets/product/desktop/Customer-journey-experience.jpg",
						mobImg: "../../assets/product/mobile/Customer-journey-experience.jpg",
						alt: "Customer journey management system",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "omnichannel-ticketing
	{
		slag: "omnichannel-ticketing",
		pagedata: [
			{
				metaDescription:
					"Customers can submit or track support requests via multiple channels with Omnichannel helpdesk customer service software for a convenient and efficient experience.",
				sectitle: "Customer Connect",
				title: "Helpdesk Services | Omnichannel Customer Service Software",
				subtitle: "Omnichannel Ticketing",
				BannerTitle: ["Omnichannel Ticketing"],
				bannerImg:
					"../../assets/product/header/ticketing-experience.jpg",
				benfits: [
					{
						list: [
							{
								title: "Reduce User Frustration",
							},
							{
								title: "Enhance Customer Experience",
							},
							{
								title: "Secure Revenue Streams",
							},
						],
					},
					{
						list: [
							{
								title: "Boost Coach Performance",
							},
							{
								title: "Create Seamless Experiences",
							},
							{
								title: "Mitigate Fraud Risks",
							},
						],
					},
					{
						list: [
							{
								title: "Drive Improved Analyses and Reports",
							},
							{
								title: "Reap Cost Savings",
							},
							{
								title: "Improve SaaS Productivity and Security",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Omnichannel helpdesk software is an efficient ticketing or case management system, that offers unified customer experience across multiple channels. The conversation always starts with the customer and is carried forward to whichever channel the customer prefers.",
							<br />,
							<br />,
							"With Omnichannel helpdesk software, customers have the flexibility to switch between channels mid-conversation without having to repeat themselves, and all communication is organized in one place for better tracking and management by support teams. This results in a more consistent and personal experience for the customer, while also providing the support team with a comprehensive view of the customer's history and needs.",
							<br />,
							<br />,
							"Our Omnichannel helpdesk ticketing system helps support teams prioritize and allocate resources effectively, as they have a complete overview of all incoming requests and their status. This leads to faster resolution times and increased customer satisfaction. Omnichannel Ticketing is a crucial aspect of modern customer support, enabling businesses to provide a seamless and integrated experience across multiple channels and devices.",
						],
						deskImg:
							"../../assets/product/desktop/ticketing-experience.jpg",
						mobImg: "../../assets/product/mobile/ticketing-experience.jpg",
						alt: "Omnichannel Customer Service Software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "sms-whatsapp
	{
		slag: "sms-whatsapp",
		pagedata: [
			{
				metaDescription:
					"Communicate with customers, promote products and services, and automate messaging through the popular app with the help of Whatsapp SMS marketing software in India.",
				sectitle: "Customer Connect",
				title: "Whatsapp Marketing Software | SMS Service Provider India",
				subtitle: "SMS/Whatsapp",
				BannerTitle: ["SMS/Whatsapp"],
				bannerImg: "../../assets/product/header/sms-whatsup.jpg",
				benfits: [
					{
						list: [
							{
								title: "Keep your customers informed",
							},
							{
								title: "Build relationships",
							},
							{
								title: "Improve customer interactions",
							},
						],
					},
					{
						list: [
							{
								title: "Send offers and deals",
							},
							{
								title: "Keep your business brand in mind",
							},
							{
								title: "Track your customer purchases",
							},
						],
					},
					{
						list: [
							{
								title: "Get more sales prospects",
							},
							{
								title: "Improve customer service",
							},
							{
								title: "Direct with the customer",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"SMS is a standard feature on most mobile phones and works by sending text messages through a cellular network. SMS marketing software is a simple and effective way to communicate with friends, family, and colleagues who also have mobile phones.",
							<br />,
							<br />,
							"WhatsApp, on the other hand, is a cross-platform instant messaging app that allows users to send text messages, and voice messages, make voice and video calls, and share images and videos with other WhatsApp users. WhatsApp marketing software uses an internet connection (Wi-Fi or mobile data) to send messages and make calls, so it does not require a cellular network.",
							<br />,
							<br />,
							"For businesses, both SMS and WhatsApp offer a quick and convenient way to stay in touch with others, but WhatsApp offers more features and capabilities compared to SMS.",
						],
						deskImg: "../../assets/product/desktop/sms-whatsup.jpg",
						mobImg: "../../assets/product/mobile/sms-whatsup.jpg",
						alt: "SMS/whatsapp marketing software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "auto-attendent
	{
		slag: "auto-attendant",
		pagedata: [
			{
				metaDescription:
					"Auto-attendant phone systems automatically route calls to the right department or extension, providing a professional and efficient way to manage incoming calls.",
				sectitle: "Business Solution",
				title: "Best Auto Attendant Phone Systems | Auto Attendant Systems India",
				subtitle: "Auto Attendant",
				BannerTitle: ["Auto Attendant"],
				bannerImg: "../../assets/product/header/auto-attendent.jpg",
				benfits: [
					{
						list: [
							{
								title: "Multi-lingual options",
							},
							{
								title: "Diverse range of languages",
							},
							{
								title: "Voiced announcements",
							},
						],
					},
					{
						list: [
							{
								title: "Customised greetings",
							},
							{
								title: "Background music and sound effects",
							},
							{
								title: "Record your own greetings and background music",
							},
						],
					},
					{
						list: [
							{
								title: "Easy to use interface",
							},
							{
								title: "Multiple designs and themes",
							},
							{
								title: "Gain valuable information on your callers",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Auto Attendant, also known as a virtual receptionist, is a voice-based phone system that automates the process of answering and routing incoming calls. It is a cost-effective solution that replaces the need for a physical receptionist, providing a professional greeting and routing callers to the desired department or extension.",
							<br />,
							<br />,
							"It works by presenting callers with a menu of options, allowing them to choose the department they need to reach by pressing a number on their phone keypad. Auto Attendant phone systems then route the call to the selected extension, saving time and streamlining the call-handling process. This can significantly improve the customer experience, as callers can get the help they need quickly and efficiently.",
							<br />,
							<br />,
							"Apart from call routing, our Auto Attendants can also provide other features such as call forwarding, call transfer, call holding, and voicemail. Some systems even offer advanced features such as integration with Customer Relationship Management (CRM) software, allowing businesses to track customer interactions and provide personalized experiences.",
							<br />,
							<br />,
							"Our Auto Attendant systems are an essential tool for any business looking to improve the way they handle incoming calls. With its ability to provide a professional greeting, route calls efficiently, and offer a range of advanced features, an Auto Attendant can help businesses operate more smoothly, enhance customer satisfaction, and increase productivity.",
						],
						deskImg:
							"../../assets/product/desktop/auto-attendent.jpg",
						mobImg: "../../assets/product/mobile/auto-attendent.jpg",
						alt: "Best Auto Attendant Phone Systems",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "contact-manager
	{
		slag: "contact-manager",
		pagedata: [
			{
				metaDescription:
					"Contact management software services organize, manage and automate communication by providing tools for storing and updating contact information and tracking interactions.",
				sectitle: "Business Solution",
				title: "Contact Management Software | Contact Management Software Services India",
				subtitle: "Contact Manager",
				BannerTitle: ["Contact Manager"],
				bannerImg: "../../assets/product/header/contact-manger.jpg",
				benfits: [
					{
						list: [
							{
								title: "Save time",
							},
							{
								title: "Save money",
							},
							{
								title: "Streamline workflow",
							},
						],
					},
					{
						list: [
							{
								title: "Speed up customer service",
							},
							{
								title: "Make better business decisions",
							},
							{
								title: "Meet your target deadlines",
							},
						],
					},
					{
						list: [
							{
								title: "Increase client satisfaction",
							},
							{
								title: "Create better internal relationships",
							},
							{
								title: "Reduce the risk of error",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Contact Manager is a software program that keeps track of contacts' communication and informational activities using a contact-centric database. Advanced Contact Managers are referred to as Contact Management (CM) or Individual Resource Management (IRM) solutions in management technology in this integrated approach.",
							<br />,
							<br />,
							"Contact Management software helps users organize and manage all prospects, contacts, and customer data from a single application. Additionally, Contact Managers make it simple to build up and track direct marketing programs.",
							<br />,
							<br />,
							"The user can save, store, and later locate contact information with the help of the Contact Manager. It could be the contact's name, phone number, or address. Contact Management software services offer a completely integrated procedure to monitor the information and communication activities associated with preserved contacts.",
						],
						deskImg:
							"../../assets/product/desktop/contact-manger.jpg",
						mobImg: "../../assets/product/mobile/contact-manger.jpg",
						alt: "Contact Management Software, Contact management software services, Contact management software India",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "Knowledge-management-platform
	{
		slag: "knowledge-management-platform",
		pagedata: [
			{
				metaDescription:
					"Store, organize, and share information and knowledge with knowledge base management system software to improve efficiency, productivity, and decision-making process.",
				sectitle: "Business Solution",
				title: "Knowledge Management System Software | Knowledge Base Software in India",
				subtitle: "Knowledge Management",
				BannerTitle: ["Knowledge Management"],
				bannerImg:
					"../../assets/product/header/Knowledge-management-platform.jpg",
				benfits: [
					{
						list: [
							{
								title: "Increase Collaboration",
							},
							{
								title: "Increase employee satisfaction",
							},
							{
								title: "Improve business leadership and decision making",
							},
						],
					},
					{
						list: [
							{
								title: "Provide a way to handle stress",
							},
							{
								title: "Decrease employee turnover",
							},
							{
								title: "Avoid losing employees' knowledge and experience",
							},
						],
					},
					{
						list: [
							{
								title: "Align with the corporate goals",
							},
							{
								title: "Create more effective workgroups",
							},
							{
								title: "Attract, retain and engage employees",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"A knowledge management system is any kind of IT system that stores and retrieves knowledge to improve understanding, collaboration, and process alignment. Knowledge management systems can exist within organizations or teams, but they can also be used to center your knowledge base for your users or customers.",
							,
							<br />,
							<br />,
							"Knowledge Management system software covers a wide range of activities and processes, including knowledge creation, knowledge storage and retrieval, knowledge sharing and dissemination, and knowledge utilization. The process starts by identifying and capturing valuable knowledge, either through formal documentation or informal conversations and interactions among employees. This knowledge is then organized and stored in a manner that makes it easily accessible and retrievable.",
							<br />,
							<br />,
							"One of the key benefits of KM is that it helps organizations to avoid reinventing the wheel and to leverage their collective experiences and expertise. By sharing knowledge across the organization, KM can help to reduce duplication of effort, improve decision-making, and increase the overall efficiency and effectiveness of the organization.",
							<br />,
							<br />,
							"Knowledge Management is an essential aspect of modern organizations and is critical for maximizing the value of an organization's intellectual and knowledge-based assets. By leveraging the power of KM, organizations can improve their performance, competitiveness, and overall effectiveness, and foster a culture of continuous learning and development.",
						],
						deskImg:
							"../../assets/product/desktop/Knowledge-management-platform.jpg",
						mobImg: "../../assets/product/mobile/Knowledge-management-platform.jpg",
						alt: "Knowledge management system software",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "help-desk-routing
	{
		slag: "help-desk-routing",
		pagedata: [
			{
				metaDescription:
					"Help desk ticketing system software solution manages support requests, by providing tools to track, prioritize and respond to customer inquiries and issues efficiently.",
				sectitle: "Business Solution",
				title: "Help Desk Ticketing Systems | Call Center Ticket Routing Software Solutions India",
				subtitle: "Help Desk Routing",
				BannerTitle: ["Help Desk Routing"],
				bannerImg:
					"../../assets/product/header/help-desk-with-routing-rules.jpg",
				benfits: [
					{
						list: [
							{
								title: "Help desk routing can be automated",
							},
							{
								title: "No more emails to follow up emails",
							},
							{
								title: "Now available as a web based system",
							},
						],
					},
					{
						list: [
							{
								title: "Eliminate manual tasks and reduce errors",
							},
							{
								title: "Improve workflows and increase sales",
							},
							{
								title: "More efficient with teams across the globe",
							},
						],
					},
					{
						list: [
							{
								title: "Self-help capabilities for your app users",
							},
							{
								title: "Cost effective to all size businesses",
							},
							{
								title: "Contact and information management",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Our Help Desk solution integrates with Exchange Server to generate automated tickets. The goal of Help Desk Routing is to ensure that every customer issue is addressed efficiently and effectively. This process is usually managed through a Help Desk software or a ",
							<Link
								to="/services/omnichannel-ticketing"
								className="interLink"
							>
								ticketing system
							</Link>,
							", which automates the routing process based on predefined rules and priority levels.",
							<br />,
							<br />,
							"When a customer submits a support request, the Help Desk Ticketing software will automatically categorize the issue and assign it to the appropriate team or agent. The routing process is guided by a set of rules and priorities that are established by the organization, such as the type of request, the urgency of the issue, the skills and availability of the support staff, and other factors.",
							<br />,
							<br />,
							"Help Desk Ticketing solution helps to streamline the support process, reduce response times, and improve customer satisfaction. By ensuring that support requests are routed to the right person, the Help Desk can resolve issues more quickly and provide a better customer experience.",
							<br />,
							<br />,
							"Help Desk Ticketing solution helps to streamline the support process, reduce response times, and improve customer satisfaction. By ensuring that support requests are routed to the right person, the Help Desk can resolve issues more quickly and provide a better customer experience.",
						],
						deskImg:
							"../../assets/product/desktop/help-desk-with-routing-rules.jpg",
						mobImg: "../../assets/product/mobile/help-desk-with-routing-rules.jpg",
						alt: "Call Center Ticket Routing",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
	// "unified-agent-solution
	{
		slag: "unified-agent-solution",
		pagedata: [
			{
				metaDescription:
					"Integrate multiple business applications and tools into a single interface using a unified agent desktop call center solution, improving agent productivity.",
				sectitle: "Business Solution",
				title: "Unified Agent Call Center | Unified Agent Desktop Solutions",
				subtitle: "Unified Agent Solution",
				BannerTitle: ["Unified Agent Solution"],
				bannerImg:
					"../../assets/product/header/unified-agent-solution.jpg",
				benfits: [
					{
						list: [
							{
								title: "Helps prevent mistakes and miscommunication",
							},
							{
								title: "Improved workflow",
							},
							{
								title: "High-quality support",
							},
						],
					},
					{
						list: [
							{
								title: "Increase revenue",
							},
							{
								title: "Enhanced agent performance",
							},
							{
								title: "Real-time management",
							},
						],
					},
					{
						list: [
							{
								title: "Better customer experience",
							},
							{
								title: "Improved productivity with automated tasks & reminders",
							},
							{
								title: "Easy to use and install",
							},
						],
					},
				],
				section: [
					{
						title: "A Simpler, faster way to service.",
						feature: [
							{
								title: "Faster services",
								para: "Simplify and streamline with partial or full automation.",
							},
							{
								title: "Happier customers",
								para: "Allow customers to quickly self-solve and save money at the same time.",
							},
							{
								title: "Happier agents",
								para: "Boost engagement by automating repetitive tasks and offer more challenging, high-value work for your contact center agents.",
							},
						],
					},
					{
						title: "What is a Cloud Contact Center Solution?",
						para: [
							"Arm your agents to deliver customer support across channels like phone, email, chat, and social media platforms such as Facebook, Twitter, and much more from one place. The agent does not need to separately attend to all the different communication channels via multiple login screens. This improves agent productivity to serve a number of customer queries faster.",
							<br />,
							<br />,
							"One of the key features of Unified Agent desktop solutions is its advanced routing and prioritization capabilities. This enables businesses to route inquiries to the most appropriate agent based on their skills and availability, ensuring that customers receive a quick and accurate response.",
							<br />,
							<br />,
							"Unified Agent Solution also includes a powerful reporting and analytics module, which provides businesses with real-time insights into the performance of their support team and the customer experience they are providing. This information can be used to identify areas for improvement and optimize support processes, ensuring that businesses can continue to meet the evolving needs of their customers.",
							<br />,
							<br />,
							"Unified Agent Desktop for contact centers is the ideal choice to streamline their customer support operations and provide an exceptional customer experience. With its comprehensive range of features and flexible customization options, this solution is designed to meet the needs of businesses of all sizes and industries.",
						],
						deskImg:
							"../../assets/product/desktop/unified-agent-solution.jpg",
						mobImg: "../../assets/product/mobile/unified-agent-solution.jpg",
						alt: "Unified Agent Desktop for Contact Centres",
					},
					{
						clientfeed: [
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
							{
								logo: MakeMyTrip,
								para: "Knowlarity has proven to be a great support in the times of Covid-19. Their click-to-Call solution enabled us to conduct business seamlessly by working from home, which kept the business on track. We highly recommend Knowlarity’s team.",
								name: "Deepak Tripathi",
								degisnation: "CEO",
							},
						],
					},
					{
						clientelLogo: [
							bsfi1,
							Logi1,
							travel3,
							travel2,
							Logi4,
							hel5,
						],
					},
				],
			},
		],
	},
];
