import React, { useRef, useState } from "react";
import { TeamBanner } from "../../assets";
import PageLayout from "../../Components/PageLayout";
import "./Partnership.scss";
import { bsfi1, Logi1, travel3, travel2, Logi4, hel5 } from "../../assets";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { BiCheck } from "react-icons/bi";
import { BsAwardFill } from "react-icons/bs";
// import { FaAward } from "react-icons/fa";
import { api } from "./../../helper/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "../../Components/InputFeild/InputFeild";
import InquiryFormStandalone from "../../Components/inquiryFormStandalone";
const Partnership = () => {
	const partnerShip = useRef(null);
	const [isValidated, setIsValidated] = useState(false);

	const executeScroll = () => partnerShip.current.scrollIntoView();
	const [formData, updateFormData] = useState({});

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	let toastObj = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};
	const Brandlogo = [bsfi1, Logi1, travel3, travel2, Logi4, hel5];

	const SubmitButton = document.querySelector(".btn-primary");

	const handleSubmit = async (e) => {
		setIsValidated(true);
		e.preventDefault();
		SubmitButton.innerText = "Please Wait.....";
		try {
			const response = await api({
				method: "post",
				url: "inquiry",
				data: {
					...formData,
					partnershipInquiry: "Inquiry for Partnership",
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			if (response.status === 200) {
				toast.success("Your Inquiry Submitted Successfully", toastObj);
				var reset = setInterval(() => {
					window.location.reload();
					clearInterval(reset);
				}, 1500);
			}
		} catch (error) {
			if (error.response.status === 400) {
				toast.error("Please try Again", toastObj);
			}
		}
	};
	return (
		<PageLayout
			title="Become Partner | Partnerships & Alliances – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Explore the benefits and requirements of becoming a partner with us and take the first step towards a mutually beneficial partnership."
			}
			className="parnter-page"
		>
			<div className="becomepartner_page">
				<BannerImage
					Image={TeamBanner}
					BannerTitle={"Partner & Alliances"}
					PageName={"Become Partner"}
				/>
				<ToastContainer />
				<div className="pb-0">
					<div className="container mb-lg-4 mb-0 py-4">
						<div className="row my-4 col-sm-reverse">
							<div className="col-md-5 col-12 mb-lg-0 mb-4">
								<div className="client-content d-flex h-100  justify-content-center flex-column ps-0 pe-lg-20 pe-0">
									<h4 className="para-heading">
										Why Partner with us
									</h4>
									<p className="m-0">
										Thank you for showing your interest in
										becoming a Radical Minds partner.
										<br />
										<br />
										At Radical Minds, towards our mission of
										“Customer success is our success”, our
										partnerships help in delivering a
										complete suite of contact center
										solutions to unlock value.
										<br />
										<br />
										Our Partners are across the Globe and we
										would love to get connected with any
										organization, that shares our common
										values. Radical Minds' world-class
										experience in Contact Center Business
										along with high-end technology products
										deliver and realize the value to their
										customers.
										<br />
										<br />
										Together, we always win and make an
										impact on our customers, which they
										always look for, and make the customer
										journey easy from a decision to success.
									</p>
								</div>
							</div>
							<div className="col-md-1 col-12"></div>
							<div className="col-md-6 col-12">
								<div className="customer ms-auto d-flex align-items-center h-100">
									<img
										src="./assets/image/partner-1.jpg"
										className="img-fluid rounde-img shadow-img"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className="section-space bg-gray py-2">
					<div className="container position-relative">
						<h2 className="text-center section-heading">
							Explore Our Partner Program
						</h2>
						<div className="row brand__logo">
							<div className="col-md-6 col-12">
								<div className="main_cont_wrap">
									<div className="text-left">
										<img
											src="./assets/consultant-partner.png"
											alt="consultant-partner"
											className="img-fluid rounde-img shadow-img"
										/>
									</div>
									<h5 className="text-left mini-para-heading">
										Consultant Partner
									</h5>
									<div className="text-left">
										<p>
											The Consultant Partner program is a
											unique opportunity for consultants
											to contribute meaningfully to the
											growth and success of their clients
											by offering them access to
											industry-leading thought leadership,
											resources, and expertise.
										</p>
									</div>
									<button
										className="btn btn-primary btn-b"
										onClick={executeScroll}
									>
										Join Our Consultant Partner
									</button>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="main_cont_wrap">
									<div className="text-left">
										<img
											src="./assets/referrals-partner.jpg"
											alt="consultant-partner"
											className="img-fluid rounde-img shadow-img"
										/>
									</div>
									<h5 className="text-left mini-para-heading">
										Referral Partner
									</h5>
									<div className="text-left">
										<p>
											As our partner, you'll be able to
											refer your customers to us and earn
											a commission on the sales we make.
											We'll also be able to help you with
											our sales process, marketing
											strategies, and client retention. If
											this sounds like something you'd be
											interested in, please click on the
											button.
										</p>
									</div>
									<button
										className="btn btn-primary btn-b"
										onClick={executeScroll}
									>
										Join Our Referral Partner
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-space py-md-4 py-0">
					<div className="container position-relative">
						<h2 className="text-center section-heading mb-0">
							Levels of Partnership
						</h2>
						<p className="w-1000 mx-auto text-center">
							Radical Minds L3 is so adaptable that it allows
							different partners to join at a suitable level for
							their overall business and contribution to business
							goals. Radical Minds L3 also includes a fast-track
							programme that allows partners to move quickly
							through the stages as they see best suited.
						</p>
						<div className="row my-4 level_partners">
							<div className="col-md-4 col-12 my-lg-1 my-2">
								<div className="rmt-card rmt-card-white">
									<div className="card_header">
										<span className="award_icon platinum">
											<BsAwardFill />
										</span>
										<h5>Platinum Partner</h5>
									</div>
									<p>
										A partner can start this programme by
										meeting all of the precondition
										criteria, or they can progress to this
										level through a staged development
										throughout the curriculum. Platinum
										Partners work collaboratively with
										regional heads of business and thus are
										entitled to benefits.
									</p>
									<ul className="check-list">
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>
												Dedicated Inside Sales
												Assistance
											</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>PreSales Support</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Executive Support</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Access to sales Report</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Training</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Lead Management</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4 col-12 my-lg-1 my-2">
								<div className="rmt-card rmt-card-white">
									<div className="card_header">
										<span className="award_icon gold">
											<BsAwardFill />
										</span>
										<h5>Gold Partner</h5>
									</div>
									<p>
										A partner can begin this programme by
										meeting all of the precondition
										criteria, or they can progress through
										the programme in levels. Gold Partners
										collaborate with regional heads of
										business and are given
									</p>
									<ul className="check-list">
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Executive Support</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Access to sales Report</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Training</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Lead Management</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4 col-12 my-lg-1 my-2">
								<div className="rmt-card rmt-card-white">
									<div className="card_header">
										<span className="award_icon sliver">
											<BsAwardFill />
										</span>
										<h5>Sliver Partner</h5>
									</div>
									<p>
										A partner can start this programme by
										meeting all of the precondition
										criteria, or they can advance beyond
										this level by following a staged future
										growth throughout the programme. Silver
										Partners work collaboratively with
										regional heads of business and are given
									</p>
									<ul className="check-list">
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>PreSales Support</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Access to sales Report</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Training</p>
										</li>
										<li>
											<span className="icon">
												<BiCheck />
											</span>{" "}
											<p>Lead Management</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-space bg-gray">
					<div className="container position-relative">
						<h2 className="text-center section-heading">
							Our Partners
						</h2>
						<div className="row brand__logo">
							<div className="col-md-1 col-12"></div>
							<div className="col-md-10 col-12 row mx-auto">
								{/* {Brandlogo.map((logo, idx) => (
									<div className="col-md-2 col-4">
										<img
											src={logo}
											alt=""
											className="img-fluid"
										/>
									</div>
								))} */}
							</div>
							<div className="col-md-1 col-12"></div>
						</div>
					</div>
				</section>
				<div id="form" className="partners-bg-img" ref={partnerShip}>
					<div className="container">
						<div className="row align-items-center align-self-center">
							<div className="col-md-6 col-12">
								<h2 className="text-left">Become Partner</h2>
							</div>
							<div className="col-md-6 col-12">

							<InquiryFormStandalone/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default Partnership;
