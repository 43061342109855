import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CustomerBanner } from "../../../assets";
import BannerImage from "../../../Components/BannerImge/BannerImage";
import PageLayout from "../../../Components/PageLayout";
import { api } from "../../../helper/api";
import "./MediaDetails.scss";
import { useNavigate, useParams } from "react-router";
import { BsImageFill } from "react-icons/bs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const MediaDetails = () => {
	const { state } = useLocation();
	const location = useParams();
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [MediaImages, setMediaImages] = useState([]);
	const [loading, setloading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);

	useEffect(() => {
		const loadCareerData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch",
					data: {
						media_url: state?.uni_url
							? state?.uni_url
							: location.slag,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				console.log(jsonData.data[1].images);
				if (jsonData.status === 200) {
					setloading(true);
					setData(jsonData.data[0]);
					setMediaImages(jsonData.data[1].images);
				}
			} catch (err) {
				if (err.response === 400) {
					navigate("/media");
				}
			}
		};
		loadCareerData();
	}, [location.slag, navigate, state?.uni_url]);

	return (
		<>
			<PageLayout title="Media">
				<div className="media_page">
					{/* <BannerImage
            Image={CustomerBanner}
            PageName="Media"
            BannerTitle="Media Coverage"
            SubTitle="An archive of our media features, appearances, and mentions."
          /> */}
					<BannerImage
						Image={"../assets/banner/media.jpg"}
						PageName="Media"
						BannerTitle="Business news, trends and insights"
					/>
					<div className="container">
						<div className="d-flex flex-row justify-content-between mb-3">
							<div className="header__navigate">
								<Link
									to="/media"
									className="btn btn-primary me-2"
								>
									Back
								</Link>
							</div>
						</div>
						{loading ? (
							data && (
								<div className="rmt-card details-page rmt-card-white">
									<div className="row mb-4 g-0 galleryAward">
										{MediaImages.length > 0 &&
											MediaImages.map(
												(img, id) =>
													id < 3 && (
														<div
															className="pos-r col-md-4 col-12 mb-2"
															key={id}
														>
															<div
																className="pos-r w-100"
																style={{
																	paddingTop:
																		"calc(35000%/460)",
																}}
															>
																<img
																	src={`${img.src}`}
																	className="bg_img pos-a t-0 l-0 img-fluid w-100 h-100"
																	alt=""
																/>
															</div>
														</div>
													)
											)}
										{MediaImages.length &&
											MediaImages.length - 3 != 0 && (
												<button
													className="btn btn-primary viewButton"
													onClick={() =>
														setIsOpen(true)
													}
												>
													<span>
														+
														{MediaImages.length - 3}{" "}
													</span>
													<BsImageFill />
												</button>
											)}
									</div>
									<h2 className="section-heading">
										{data && data.title}
									</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: data["content"]
												? JSON.parse(data["content"])[0]
												: "",
										}}
									></div>
								</div>
							)
						) : (
							<>Loading.... </>
						)}
					</div>
				</div>
				{isOpen && (
					<Lightbox
						mainSrc={MediaImages[photoIndex].src}
						nextSrc={
							MediaImages[(photoIndex + 1) % MediaImages.length]
								.src
						}
						prevSrc={
							MediaImages[
								(photoIndex + MediaImages.length - 1) %
									MediaImages.length
							].src
						}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() =>
							setPhotoIndex(
								(photoIndex + MediaImages.length - 1) %
									MediaImages.length
							)
						}
						onMoveNextRequest={() =>
							setPhotoIndex((photoIndex + 1) % MediaImages.length)
						}
					/>
				)}
			</PageLayout>
		</>
	);
};

export default MediaDetails;
