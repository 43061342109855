import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageLayout from '../../Components/PageLayout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PrivacyPolicy.css'; 

const CookiePolicy = () => {
    return (
        <>
            <PageLayout
                title={
                    "Multilingual BPO | Outsourced Virtual Call Center Solutions | Offshore Customer Support Services India"
                }
            >
                <div className='bg-gray'>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} className='bg-white'>
                            <h1 className="my-4  privacy-policy-title ">Cookie Policy</h1>
                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Introduction</h2>
                                <p>
                                    Radical Minds Technologies Pvt. Ltd ("we," "our," "us") is committed to safeguarding your privacy. This Cookie Policy explains how we use cookies and similar technologies to collect, store, and use personal information when you visit our website <a href="http://www.radicalminds.in" target="_blank" rel="noopener noreferrer">www.radicalminds.in</a>. It also outlines the measures we take to protect your personal information (PI) and your rights to manage our use of cookies.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>What Are Cookies?</h2>
                                <p>
                                    Cookies are small text files placed on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work, enhance user experience, and provide information to the site owners.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Types of Cookies We Use</h2>
                                <h3>1. Essential Cookies</h3>
                                <p>
                                    These cookies are necessary for the website to function correctly and cannot be turned off in our systems. They are usually set in response to actions you take, such as setting your privacy preferences, logging in, or filling out forms.
                                </p>
                                <h3>2. Performance Cookies</h3>
                                <p>
                                    These cookies collect information about how visitors use our website, such as which pages are visited most often and if they receive error messages. This information is aggregated and anonymous, and it helps us improve the website's performance.
                                </p>
                                <h3>3. Functionality Cookies</h3>
                                <p>
                                    These cookies allow the website to remember choices you make (such as your username, language, or region) and provide enhanced, personalized features. They may be set by us or third-party providers whose services we have added to our pages.
                                </p>
                                <h3>4. Targeting/Advertising Cookies</h3>
                                <p>
                                    These cookies track your browsing habits to enable us to show advertising that is more likely to be of interest to you. They also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.
                                </p>
                                <h3>5. Social Media Cookies</h3>
                                <p>
                                    These cookies are set by a range of social media services that we have added to the site to enable you to share our content with your friends and networks. They can track your browser across other sites and build a profile of your interests.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Third-Party Cookies</h2>
                                <p>
                                    Our website may also use cookies from third-party services, such as advertising networks and analytics providers. These third parties may use cookies to collect information about your online activities over time and across different websites.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Legal Basis for Processing</h2>
                                <p>
                                    We process personal data collected through cookies based on user consent, which is obtained through our cookie consent banner, and for our legitimate interests in maintaining the functionality and performance of our website.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Managing Cookies</h2>
                                <p>
                                    You have the right to control the use of cookies. You can manage cookies through your browser settings. Most browsers allow you to:
                                </p>
                                <ul>
                                    <li>View the cookies that are set on your device and delete them individually.</li>
                                    <li>Block third-party cookies.</li>
                                    <li>Block cookies from specific sites.</li>
                                    <li>Block all cookies from being set.</li>
                                    <li>Delete all cookies when you close your browser.</li>
                                </ul>
                                <p>
                                    Please note that if you choose to block or delete cookies, some parts of our website may not function properly.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Protecting Personal Information</h2>
                                <p>
                                    We are committed to protecting your personal information collected through cookies and similar technologies. Our data protection measures include:
                                </p>
                                <ul>
                                    <li>Data Encryption: Encrypting the data collected to prevent unauthorized access.</li>
                                    <li>Anonymization: Anonymizing data wherever possible to protect your identity.</li>
                                    <li>Access Controls: Restricting access to personal data to authorized personnel only.</li>
                                    <li>Regular Audits: Conducting regular audits of our data collection and storage practices to ensure compliance with applicable laws and regulations.</li>
                                    <li>Cookie Misuse Prevention: Implementing security measures to prevent misuse or exploitation of cookies.</li>
                                </ul>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Data Protection and Security Compliance</h2>
                                <p>
                                    We follow the ISO/IEC 27001 standard for data protection to ensure the highest level of security for your personal information.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Your Rights</h2>
                                <p>
                                    You have the following rights regarding your personal information collected through cookies:
                                </p>
                                <ul>
                                    <li>Access: The right to request access to your personal data.</li>
                                    <li>Rectification: The right to request correction of any inaccurate or incomplete data.</li>
                                    <li>Erasure: The right to request deletion of your personal data.</li>
                                    <li>Restriction: The right to request restriction of processing your personal data.</li>
                                    <li>Objection: The right to object to the processing of your personal data.</li>
                                </ul>
                            </section>

                            <section className="privacy-policy-section">
                                <h2>Changes to This Policy</h2>
                                <p>
                                    We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date. We will notify you of significant changes through our website or via email.
                                </p>
                            </section>

                            <section className="privacy-policy-section">
                                <h2 className='intro-heading'>Contact Us</h2>
                                <p>
                                    If you have any questions related to this Cookie Policy, or to report any data privacy concerns, please email us at <a href="mailto:info@radicalminds.in">info@radicalminds.in</a>.
                                </p>
                            </section>
                        </Col>
                    </Row>
                </Container>
                </div>
            </PageLayout>
            <ToastContainer />
        </>
    );
}

export default CookiePolicy;
