import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import { api } from "../../helper/api";
import "./Media.scss";


const PressRelase = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [prImag, setprImag] = useState();
  const [filter, setFilter] = useState();

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const loadPressRelease = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: { fetch_all_press: true,year : filter?.year ?? "", },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        console.log(jsonData);
        if (jsonData.status === 200) {
          setloading(true);
          setData(jsonData.data);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setloading(false);
        }
      }
    };
    loadPressRelease();
  }, [filter]);

  return (
    <>
      <PageLayout title="Press Release – Radical Minds Technologies Pvt. Ltd">
        <div className="media_page">
          <BannerImage
            Image="./assets/banner/press.jpeg"
            PageName="Press Release"
            BannerTitle="Business Press Release"
          />
          <div className="container">
            <div className="d-flex flex-row justify-content-between mb-flex-col-with-10gap">
              <div className="media_filter_section align-items-center">
                <p className="pt-2">Fliter By :</p>
                <select
                  className="form-select  media_filter media_year"
                  aria-label="Default select example"
                  name="year"
                  onChange={handleChange}
                >
                  <option selected value={""}>
                    All Years
                  </option>
                  <option defaultValue={"2023"}>
                    2023
                  </option>
                  <option defaultValue="2022">2022</option>
                  <option defaultValue="2021">2021</option>
                  <option defaultValue="2020">2020</option>
                  <option defaultValue="2019">2019</option>
                  <option defaultValue="2018">2018</option>
                </select>
              </div>
              <div className="header__navigate">
                <Link to="/media" className="btn btn-primary me-2">
                  Media
                </Link>
                <Link to="/our-award" className="btn btn-primary">
                  Awards
                </Link>
              </div>
            </div>
            <div className="row media_section">
              {loading ? (
                data.length > 0 ? (
                  data.map((news) => (
                    <div className="col-md-6 col-12">
                      <div className="article pt-50 pb-50 bdb-707070-op30p">
                        <p className="d-f article_bradcamb">
                          <span>{news.date}</span>
                          <span className="ms-lg-4 ms-2">{news.pr_from}</span>
                        </p>
                        <h2 className="mt-2">{news.title}</h2>
                        { news.pr_type ==="PDF" && 
                        
                        <a
                          href={news.pr_file}
                          target="_blank"
                          rel="noreferrer"
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </a>
                         }
                        { news.pr_type ==="Link" && 
                        <a
                          href={news.link}
                          target="_blank"
                          rel="noreferrer"
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </a>
                         }
                        { news.pr_type ==="Image" && 
                        
                        <div
                          onClick={()=>{setprImag(news.pr_file);setIsOpen(true)}}
                          target="_blank"
                          rel="noreferrer"
                          className="mt-16 readMoreBtn"
                        >
                          Read more
                        </div>
                         }
                      </div>
                    </div>
                  ))
                ) : (
                  <>Loading....</>
                )
              ) : (
                <>Press Release not Found</>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
        <Lightbox
          mainSrc={prImag}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      </PageLayout>
    </>
  );
};

export default PressRelase;
