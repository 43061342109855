import React, { useEffect, useState } from "react";
import { CSRBanner, CSRICON } from "../../assets";
import { api } from "../../helper/api";
import PageLayout from "../../Components/PageLayout";
import "./CSR.scss";
import BannerImage from "../../Components/BannerImge/BannerImage";

const CSR = () => {
	const [data, setData] = useState([]);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		const loadPressRelease = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch",
					data: { fetch_csr: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				console.log(jsonData);
				if (jsonData.status === 200) {
					setloading(true);
					setData(jsonData.data);
				}
			} catch (err) {
				if (err.response.status === 400) {
					setData([]);
					setloading(false);
				}
			}
		};
		loadPressRelease();
	}, []);

	return (
		<PageLayout
			title="Corporate Social Responsibility – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Discover our commitment to corporate social responsibility and the initiatives and programs we have in place to make a positive impact in our community and the world."
			}
		>
			<BannerImage
				Image={CSRBanner}
				PageName={"Corporate Social Responsibility"}
				BannerTitle={["Powering Social Good"]}
			/>

			<div className="customer-sec bg-gray">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="csrimg mb-md-0 mb-4">
								<img
									src={CSRICON}
									className="img-fluid"
									alt=""
								/>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="client-content  d-flex align-items-center flex-column justify-content-center h-100">
								<h2 className="para-heading">
									Our Commitment to the Community and
									Environment
								</h2>
								<p>
									Radical Minds is guided by a deep commitment
									to social contribution, environmental
									sustainability, and corporate citizenship
									that is ingrained in the company's core
									values. To learn more about Radical Mind's
									ESG strategy and performance, click to read
									our full 2020 ESG Report.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="customer-sec">
				<div className="container">
					<h2 className="section-heading">Our Responsibilities</h2>
					{loading &&
						data.length > 0 &&
						data.map((csr, id) => (
							<div
								className={`row mb-lg-0 my-4  mb-0 ${
									id % 2 === 0
										? "col-sm-reverse"
										: "desktop-reverse"
								}`}
								key={id}
							>
								<div className="col-md-6 col-12">
									<div className="client-content p-0 d-flex flex-column justify-content-center h-100">
										<div>
											<h2 className="para-heading text-left">
												{csr.title}
											</h2>
										</div>
										<p>{csr.content}</p>
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="article_img mb-md-0 mb-4">
										<img
											src={csr.img}
											className="img-fluid rounde-img shadow-img"
											alt=""
										/>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</PageLayout>
	);
};

export default CSR;
