import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { useLocation } from 'react-router-dom'


const PageLayout = ({ children, title, notice, metaDescription }) => {
  document.title = title ? title : "Multilingual BPO | Offshore Call Center Solutions | Live Chat Support Services India";
  const location = useLocation();
  console.log(location.pathname,'location');
 
  document.querySelector("link[rel='canonical']").href = "https://www.radicalminds.in"+location.pathname;
  document.getElementsByTagName("meta").title.content= title ? title : "BPO, KPO, LPO Solutions for BFSI | Call Center Software Development Company India";
  document.getElementsByTagName("meta").description.content = metaDescription ? metaDescription :"Radical Minds is a global leading transformational outsourcing company which provides BPO solutions, CRM solutions, sales & marketing, lead generation, and BFSI services.";

  return (
    <>
      <Header homePage={notice} />
      <main>{children}</main>
      <Footer />
    </>
  );
};
 
export default PageLayout;
