import React from "react";
import PageLayout from "../../Components/PageLayout";
import "./Process-Security.scss";
import { BiCheck } from "react-icons/bi";
import BannerImage from "../../Components/BannerImge/BannerImage";

const ProcessSecurity = () => {
  return (
    <PageLayout
      title="Process Security – Radical Minds Technologies Pvt. Ltd"
      metaDescription={
        "Learn about our strict security protocols and measures to ensure the safety and confidentiality of your data."
      }
    >
      <BannerImage
        Image={"./assets/banner/process.jpg"}
        PageName={"Process Security"}
        BannerTitle={"Zero Data Breach till Date"}
      />

      <div className="customer-sec">
        <div className="container">
          <h2 className="para-heading text-center">
            Technology & information Security Compliance
          </h2>
          <div className="">
            <img
              src="./assets/image/process-1.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
          </div>
          <hr className="my-4" />
          <div className="">
            <img
              src="./assets/image/process-2.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div className="w-1000 mx-auto">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Multi-site Dialler Architecture</td>
                  <td>World Class NOC to manage IT Infrastructure</td>
                </tr>
                <tr>
                  <td>VoIP Telephony System</td>
                  <td>Network Management System</td>
                </tr>
                <tr>
                  <td>Disaster Recovery & BCP in place</td>
                  <td>Alarms & Fault Management system</td>
                </tr>
                <tr>
                  <td>Tier 3 Data Centre</td>
                  <td>MIS Reporting</td>
                </tr>
                <tr>
                  <td>Tier 3 Data Centre</td>
                  <td>MIS Reporting</td>
                </tr>
                <tr>
                  <td>Remote Secure Access to Troubleshooting</td>
                  <td>Multisite equipment full redundancy</td>
                </tr>
                <tr>
                  <td>24×7 Support at all locations</td>
                  <td>
                    Policy, procedures in place to adhere to security compliance
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="csrimg mb-md-0 mb-5">
                <img
                  src="./assets/image/process-3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="client-content  d-flex flex-column justify-content-center h-100">
                <h2 className="para-heading">
                  RM Touch- Blend Dialer and
                  <br className="custome_desktop" /> Unified CRM Platform
                </h2>
                <ul className="check-list">
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p> Auto rostering (historical &amp; forecasting data)</p>
                  </li>
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p> Skill based routing</p>
                  </li>
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p> Screen recording, call/screen barge-in</p>
                  </li>
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p> Remote monitoring.</p>
                  </li>
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p> Extensive Reporting features and</p>
                  </li>
                  <li>
                    <span className="icon">
                      <BiCheck />
                    </span>{" "}
                    <p>
                      {" "}
                      API integration which ensures seamless integration with
                      any other CTI platform.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProcessSecurity;
