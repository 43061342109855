import { Link } from "react-router-dom";
import {
	Logi1,
	Logi2,
	Logi3,
	Logi4,
	retail1,
	retail2,
	retail3,
	retail4,
	bsfi1,
	bsfi2,
	bsfi3,
	bsfi4,
	gov1,
	gov2,
	gov3,
	hel1,
	hel2,
	hel3,
	hel4,
	hel5,
	travel1,
	travel2,
	travel3,
	travel4,
	travel5,
	travel6,
	travel8,
	bsfi5,
	bsfi6,
	retail7,
	bsfi7,
	edu1,
	edu2,
	edu3,
	edu4,
} from "../../../assets";
export const SolutionsPages = [
	//bpo
	{
		slag: "bpo",
		pagedata: [
			{
				metaDescription:
					"Explore top-notch offshore Business Process Outsourcing (BPO) Services with our expert team. Based in Gurugram, India, we offer efficient and economical BPO solutions to streamline operations and enhance your bottom line.",
				title: "BPO Services in Gurugram | Offshore Business Process Outsourcing Solutions India",
				subtitle: "BPO",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/001/226/972/large_2x/professional-woman-providing-support-free-photo.jpg",
				BannerTitle: ["On call - fingertips Solutions"],
				sec1Title: "Make the right choice for BPO",
				para: [
					"The business workflow is the key for the BPO industry and choosing the right (Cloud Vs In-premise) solution always takes the business to next level.",
					<br />,
					<br />,
					"Radical Minds ensures that no dots get missed while configuring the workflow process in a cost-efficient & robust solution for your business.",
				],
				alt: "BPO Services in India",
				img: "../../assets/solution/bpo.jpg",
				leadingLogo: [Logi1, Logi3, travel3, travel2, Logi4, Logi2],
				section: [
					{
						title: "New Era Customer Service in BPO",
						feature: [
							{
								icon: "../../assets/solution/icons/bpo-01.svg",
								title: "World Class Features",
								para: "Inbound and outbound features like Predictive Dialing, Intelligent IVR, CTI Integration, CRM Integration, Reports to match all requirements, routing algorithm, auto dialer, click-to-call, Monitoring on real-time and above all Dashboard to make the business information on single click make you different.",
							},
							{
								icon: "../../assets/solution/icons/bpo-02.svg",
								title: "Omnichannel",
								para: "We understand that each customer needs a personalised experience and to achieve this, our platform gets integrated with world-class CRM with an Open API concept.",
							},
							{
								icon: "../../assets/solution/icons/bpo-03.svg",
								title: "Seamless Integration across platforms",
								para: "We understand that each customer needs personalised experience and to achieve this, our platform gets integrated with world class CRM with Open API concept.",
							},
						],
					},
					{
						title: [
							"Empower Agents, Administrator &  all stakeholders on single click",
						],
						subtitle:
							"Information is the key to success and the Radical Minds platform ensures the same with a world-class platform, which provides all relevant information with a single click to avoid any data loss and customer dissatisfaction.",
						feature: [
							{
								icon: "../../assets/solution/icons/bpo-04.svg",
								title: "Enterprise-grade Features",
								para: [
									"To stay on top of your contact centre operations, supercharge your call centre for BPO with extensive inbound and outbound features such as an IVR system, intelligent routing algorithm, automated dialers, click-to-call, CRM integration, real-time monitoring, extensive reports & dashboards.",
								],
							},
							{
								icon: "../../assets/solution/icons/bpo-05.svg",
								title: "Management and optimization of performance",
								para: [
									"Maintain control of your contact centre operations by utilizing flexible agent management features such as queue to user mapping to ensure that your agents are productive at all times. Supervisors can keep track of idle agents and shuffle them between queues to maximize their productivity.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell, or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Auto Predictive Dialer",
								para: "Auto predictive dialer future provides an easy and affordable way to grow your business.",
								link: "/services/auto-predictive-dialer",
							},
							{
								title: "Smart Routing",
								para: "Smart Routing is a smart and robust routing algorithm that balances the need for fast response against the correct answers",
								link: "/services/acd-smart-routing",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	// edu
	{
		slag: "education",
		pagedata: [
			{
				metaDescription:
					"We provide next-generation BPO services for the education sector while offering effective customer support through our education call center software.",
				title: "Education BPO Services | Education Call Center Software Solutions",
				subtitle: "Education",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/003/334/181/non_2x/close-up-graduation-holding-graduation-cap-education-concept-free-photo.jpg",

				BannerTitle: ["Secure learning Excellence"],
				sec1Title: "Institutional data into a strategic advantage",
				para: [
					"Radical Minds offers next-generation solutions for education, which help you stay agile, cost reduction and integration across institution to get all time real and correct data to avoid any discrepancy.",
				],
				img: "../../assets/solution/edu.jpg",
				alt: "Education Call Center Software",
				leadingLogo: [edu1, edu2, edu3, edu4],
				section: [
					{
						title: "Response Time Reduction",
						feature: [
							{
								icon: "../../assets/solution/icons/edu-01.svg",
								title: "Tailored Tech Support",
								para: "Its been more than a decade, since when Radical Minds is providing call center services in the education sector to deliver a better contact center, aligning services with your needs.",
							},
							{
								icon: "../../assets/solution/icons/edu-02.svg",
								title: "Financial aid services",
								para: "Radical Minds technology and Agents respond quickly to the needs of students regarding Registration, Financial aid, and Admissions, which gets integrated with your CRM & ERP system.",
							},
							{
								icon: "../../assets/solution/icons/edu-03.svg",
								title: "Enrollment and retention services",
								para: "We understand that to Remain competitive and understand your admissions and recruiting processes with complete enrollment management services.",
							},
						],
					},
					{
						title: ["Empower Students with real time Information"],
						subtitle:
							"Information is the key to success and the Radical Minds platform ensures the same with a world-class platform, which provides all relevant information with a single click to avoid any data loss and student and parent dissatisfaction.",
						feature: [
							{
								icon: "../../assets/solution/icons/edu-04.svg",
								title: "Leaders have faith in us",
								para: [
									"Radical Minds is a full-service digital agency that creates content for 120+ brands across the globe, including marquee brands like Unlu, Vedantu, and Topper. We've helped clients communicate with their audiences in a way that makes them feel empowered and confident in their leadership skills—and we're constantly looking for ways to do better.",
								],
							},
							{
								icon: "../../assets/solution/icons/edu-05.svg",
								title: "Connect with Students on Any Channel with This All-in-One Suite",
								para: [
									"An omnichannel customer engagement platform allows you to optimize the customer experience throughout the entire customer lifecycle, including voice calls, video chats, social media engagement, bots, and so on. Education contact center software also allows you to track historical or previous interactions with customers across touchpoints in order to preserve the context of conversations.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	// finance
	{
		slag: "financial-services",
		pagedata: [
			{
				metaDescription:
					"Radical Minds offers a seamless customer experience through multiple channels with the BPO call center software for the banking and financial sector.",
				title: "Contact Center Software for Banking | Call center software for Financial",
				subtitle: "Financial Services",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/007/389/770/large_2x/woman-hand-holding-credit-card-and-using-laptop-at-home-businessman-or-entrepreneur-working-online-shopping-e-commerce-internet-banking-spending-money-working-from-home-concept-free-photo.jpg",
				BannerTitle: ["Next generation banking"],
				sec1Title:
					"Connecting with the financial industry with enhanced CX",
				para: [
					"No customer issues are left unturned with our AI-driven Contact Center Solution.",
					<br />,
					<br />,
					"Radical Minds makes a possible approach to make every customer happy and reason, we have been loved by so many banks, which ensures seamless experience across channels in a simplified way possible.",
				],
				img: "../../assets/solution/finance.jpg",
				alt: "Contact Center Software for Banking and financial",
				leadingLogo: [bsfi1, bsfi2, bsfi3, bsfi4, bsfi5, bsfi6],
				section: [
					{
						title: "Banking Experience Simplified",
						feature: [
							{
								icon: "../../assets/solution/icons/finance-01.svg",
								title: "Redefine Banking Experience",
								para: "Real-time, fast, and secure financial needs get addressed to make the customer delighted through multiple channels.",
							},
							{
								icon: "../../assets/solution/icons/finance-02.svg",
								title: "Follow Right Path",
								para: "Guiding the customer right and the perfect option to move forward ensures customers re-engage with our services and platform.",
							},
							{
								icon: "../../assets/solution/icons/finance-03.svg",
								title: "All platform under one roof",
								para: "A seamless experience for customer ensures that all the right information is in one place with all platform information getting integrated under one roof and Radical Minds ensure this always.",
							},
						],
					},
					{
						title: ["Digital Banking Redefined"],
						subtitle:
							"Radical Minds strongly believes that customer delight could happen with personalized experience only and this is what we are known for. Fast, Secure, Agile, and Nimble make us different.",
						feature: [
							{
								icon: "../../assets/solution/icons/finance-04.svg",
								title: "Banking Industry Remote Solutions",
								para: [
									"The banking contact center software from Radical Minds enables agents to work from anywhere with ease. While working remotely, streamline banking processes such as loans, customer relationships, payment reminders, and so on. The robust solution provided by Radical Minds enables banks to combat security threats and establish centralised control over banking operations.",
								],
							},
							{
								icon: "../../assets/solution/icons/finance-05.svg",
								title: "CRM Integrations That Work",
								para: [
									"Ensure that all of your departments are in real-time sync to provide a seamless customer journey. Radical Minds banking customer service software is easily integrated with a third-party or in-house CRM application, allowing different departments within banks to map customer information within the CRM in real-time. This eliminates the need to switch between tabs by providing information in a unified interface.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	// tele
	{
		slag: "telecommunication",
		pagedata: [
			{
				metaDescription:
					"We offer a complete integrated BPO call center software service for telecommunications to assist customers more efficiently for an enhanced customer experience.",
				title: "Telecom BPO Services | Call Center Software for Telecommunications",
				subtitle: "Telecommunication",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/002/928/560/non_2x/network-connection-social-media-with-telecommunication-technology-on-cityscape-background-free-photo.jpg",
				BannerTitle: ["Where boundary get connected"],
				sec1Title: "Global Security Standards getting followed here",
				para: [
					"We follow the completed Dev-Ops cycle with agility in the center.",
					<br />,
					<br />,
					"We are HIPPA, PCI, and CMMi level 5 compliant, which ensures that every call that comes to us meets Global Security Standards. We are also VAPT Certified product and service-based offering organization.",
				],
				img: "../../assets/solution/tele.jpg",
				alt: "Call center software for telecom",
				leadingLogo: [
					retail1,
					retail2,
					retail3,
					retail4,
					retail7,
					travel8,
				],
				section: [
					{
						title: "Explore the option of winning experience",
						feature: [
							{
								icon: "../../assets/solution/icons/tele-01.svg",
								title: "Accrediated by Global Leaders",
								para: "Its been more than a decade, since when Radical Minds is providing call center services in Travel and Hospitality businesses to represent your brand.",
							},
							{
								icon: "../../assets/solution/icons/tele-02.svg",
								title: "Flexibility and Scalability",
								para: [
									"Radical Minds always work towards, what is best for them, and selecting the correct deployment which suits the business model of Partner always makes them satisfied.",
									<br />,
									"Radical Minds offer private, public, and hybrid cloud environments without any operational overheads.",
								],
							},
							{
								icon: "../../assets/solution/icons/tele-03.svg",
								title: "CRM Integration Open API",
								para: "Radical Minds ensures that agents are familiar with your industry and the services you provide. We take hard to train them in the industry domain to get a competitive advantage. We are 24/7 operational.",
							},
						],
					},
					{
						title: ["Customer Delight with seamless Integration"],
						subtitle: [
							"Resolving any customer complaint or query is the first and foremost priority of any agent. Radical Minds offer a completely integrated platform, which can be integrated with any ",
							<Link
								to="/services/crm-integration"
								className="interLink"
							>
								CRM
							</Link>,
							" with Open API in no time to achieve customer delight.",
						],
						feature: [
							{
								icon: "../../assets/solution/icons/tele-04.svg",
								title: "Dashboards and comprehensive reporting",
								para: [
									"To make an effective and well-informed decision, keep track of relevant key performance indicators such as CSAT score, average handling time, first contact resolution rate, NPS, cost of acquisition, repeated queries, and so on. Allow your supervisors and managers to analyze call quality adherence and agent efficiency with detailed reports to optimize business strategies.",
								],
							},
							{
								icon: "../../assets/solution/icons/tele-05.svg",
								title: "Deployment Options that are Highly Scalable and Flexibles",
								para: [
									"Choose any solution from a flexible range of private, public, and hybrid cloud environments to completely remote contact centre solutions and get the flexibility of scaling your contact centre operations without worrying about operational overheads.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	//travel
	{
		slag: "travel-and-hospitality",
		pagedata: [
			{
				metaDescription:
					"We are committed to providing 24*7 help desk service with our travel and hospitality call center BPO services and ensuring a prompt and accurate response.",
				title: "Travel Industry BPO | Travel Process Outsourcing Gurugram, India",
				subtitle: "Travel and Hospitality",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/007/095/314/non_2x/the-sky-above-the-clouds-on-a-plane-free-photo.jpg",
				BannerTitle: ["Secure best memories with us"],
				sec1Title: "Make Travel Experience Worldclass",
				para: [
					"Radical Minds understand that the hospitality industry works 365 days a year. Irrespective of the season, people find a good reason to travel. So, with millions of travelers crisscrossing the globe every year, 24-hour customer service is essential.",
					<br />,
					<br />,
					"For last 12 Years, we are providing our services to Travel and Hospitality sectors.",
				],
				alt: "Call center services in Travel and Hospitality",
				img: "../../assets/solution/travel.jpg",
				leadingLogo: [
					travel1,
					travel2,
					travel3,
					travel4,
					travel5,
					travel6,
				],
				section: [
					{
						title: "Make Traveller at the center of every touchpoint",
						feature: [
							{
								icon: "../../assets/solution/icons/travel-01.svg",
								title: "Boost your Booking",
								para: "Its been more than a decade, since when Radical Minds is providing call center services in Travel and Hospitality businesses to represent your brand.",
							},
							{
								icon: "../../assets/solution/icons/travel-02.svg",
								title: "Multi-Channel Support",
								para: "Radical Minds technology offers a dedicated agent program so that you can receive support via phone, email, text, or managed chat. Our success is your success, and being available over any communication channel makes for very happy customers.",
							},
							{
								icon: "../../assets/solution/icons/travel-03.svg",
								title: "Travel Industry Specific Knowledge",
								para: "Radical Minds ensures that agents are familiar with your industry and the services you provide. We take hard to train them in the industry domain to get a competitive advantage. We are 24/7 operational.",
							},
						],
					},
					{
						title: ["Relax, we've got you covered"],
						subtitle: [
							"Radical Minds' ",
							<Link
								to="/services/cloud-contact-center"
								className="interLink"
							>
								contact center
							</Link>,
							" platform and professional agents will flawlessly represent your brand 24 hours a day, 365 days a year to ensure customers' needs are met promptly and courteously encouraging repeat business and increase revenue.",
						],
						feature: [
							{
								icon: "../../assets/solution/icons/travel-04.svg",
								title: "Engage With Your Customers",
								para: [
									"Customers must have a thorough understanding of their trip before making reservations. With Radical Minds Omnichannel Software, agents can now engage with customers proactively while also managing data. Customers should receive SMS, timely alerts, and exciting offers for their preferred destinations.",
								],
							},
							{
								icon: "../../assets/solution/icons/travel-05.svg",
								title: "Selling Through Personas",
								para: [
									"Persona-based packages could be created based on customer history and booking experiences. Implement personalized cross-selling and up-selling strategies using a 360-degree view of the customer.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Auto Predictive Dialer",
								para: "Auto predictive dialer future provides an easy and affordable way to grow your business.",
								link: "/services/auto-predictive-dialer",
							},
							{
								title: "Smart Routing",
								para: "Smart Routing is a smart and robust routing algorithm that balances the need for fast response against the correct answers",
								link: "/services/acd-smart-routing",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	// health
	{
		slag: "healthcare",
		pagedata: [
			{
				metaDescription:
					"With our healthcare call center software, we are dedicated to offering customer support and ensuring a timely and accurate response.",
				title: "Healthcare Call Center | Contact Center Software Healthcare",
				subtitle: "Healthcare",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/001/397/346/non_2x/nurse-taking-blood-pressure-free-photo.jpg",
				BannerTitle: ["A transforming Healing Presence"],
				sec1Title: "Adopted interaction to transform Patient Journey",
				para: [
					"Every Patient is a key to our success so be the journey as well. This can be achieved with adopted interaction, which provides ultimate satisfaction and provides them security.",
					<br />,
					<br />,
					"We are HIPPA, PCI, and CMMi level 5 compliant, which ensures that every patient comes to us with peace of mind and goes with complete satisfaction, which also supports every stakeholder in the journey of the patient.",
				],
				img: "../../assets/solution/hel.jpg",
				alt: "Healthcare Call Center Software",
				leadingLogo: [hel2, hel1, hel3, hel4, hel5],
				section: [
					{
						title: "Patient is at center in every Interaction",
						feature: [
							{
								icon: "../../assets/solution/icons/hel-01.svg",
								title: "Digitize Patient Journey",
								para: "Everyone demands 360-degree support across communication platforms as per their comfort and ease. With world-class resources, we achieve this.",
							},
							{
								icon: "../../assets/solution/icons/hel-02.svg",
								title: "What Matters Most",
								para: "Patient satisfaction is the foremost thing, which we focus on. It comes with past records with an integrated platform to get information handy.",
							},
							{
								icon: "../../assets/solution/icons/hel-03.svg",
								title: "Patient care is the key to success",
								para: "Quality delivery is the only factor, which we focus on and strive for, be it patience, member, or any other stakeholder. Connecting all different teams is our motto.",
							},
						],
					},
					{
						title: [
							"Convenience for everyone is the Key to Success",
						],
						subtitle:
							"Radical Minds believes that the platform should provide a world-class experience to patients and related teams, so provide the integrated platform with the latest engineering design to achieve this, which is being used by leading healthcare organizations.",
						feature: [
							{
								icon: "../../assets/solution/icons/hel-04.svg",
								title: "Contact Patients via their Preferred Channels",
								para: [
									"It allows your patients to engage with you via their preferred channel for booking appointments, making payments, opting for consultation reminders, and more. To preserve the context of discussions, healthcare contact center solutions allow you to monitor historical or previous interactions with patients across touchpoints including Voice Call, Video Chat, social media, Voice Bot, and Chatbot.",
								],
							},
							{
								icon: "../../assets/solution/icons/hel-05.svg",
								title: "Solutions for Working from Anywhere",
								para: [
									"The Radical Minds omnichannel consumer engagement platform is designed for a 'work from anywhere' surroundings, designed to allow healthcare brands to work from any device, browser, or location. It allows for the hiring of a diverse workforce and the streamlining of communication processes across all departments, and all the while establishing security features.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	//gov
	{
		slag: "government",
		pagedata: [
			{
				metaDescription:
					"The government contact center solution gives the required assistance to the customers across channels for a personalized experience.",
				title: "Government Contact Center Software | Government Call Center Solutions",
				subtitle: "Government",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/010/824/183/non_2x/male-lawyer-working-with-contract-papers-and-wooden-gavel-on-tabel-in-courtroom-justice-and-law-attorney-court-judge-concept-free-photo.jpg",
				BannerTitle: ["Empowering Nation Digitally"],
				sec1Title: "Make a step towards digital government",
				para: [
					"Globe is getting digitalized, so make a step towards a partner, which understand your needs and gives a new way of digital experience, which could enable your customers/partners/people to get benefitted from your offerings.",
					<br />,
					<br />,
					"All stakeholders like Business, People, and Control Staff get the support on time with zero delays. Effusiveness is another important aspect, that Radical Minds full fill all the time.",
				],
				img: "../../assets/solution/gov.jpg",
				alt: "Contact Center Software for Government",
				leadingLogo: [gov1, gov2, gov3],
				section: [
					{
						title: "New Era Customer Service for Government",
						feature: [
							{
								icon: "../../assets/solution/icons/gov-01.svg",
								title: "Enhance People Experience",
								para: "In today's fast life, pushing the right and correct information to all people/business make it easy for them to perform better.",
							},
							{
								icon: "../../assets/solution/icons/gov-02.svg",
								title: "Vision and Mission",
								para: "Radical Minds technology ensures that people should not be overburdened with loads of information and get what is required to achieve Government Vision with short-term and long-term goals.",
							},
							{
								icon: "../../assets/solution/icons/gov-03.svg",
								title: "Maximize your reach in no time",
								para: "Information and People is the key to any mission to be succeeded. Radical Minds ensures that reaching the information on the go irrespective of scale makes it different.",
							},
						],
					},
					{
						title: ["Government Offering to all"],
						subtitle:
							"Gaining Public support has always been required by any Govt and our Contact Center delivers the same to make it a reality for Government's Vision to be achieved.",
						feature: [
							{
								icon: "../../assets/solution/icons/gov-04.svg",
								title: "Exceed modern citizens expectations",
								para: [
									"The volume and urgency of citizen needs have increased. Assist constituents with benefits disbursement, employment compensation, health, and human services, income assistance, and other issues.",
								],
							},
							{
								icon: "../../assets/solution/icons/gov-05.svg",
								title: "Self-service options will make it easier to access information",
								para: [
									"You do not need to hire more people to meet constituent demand. Self-service channels reduce the need for live agent support. Clear FAQs and knowledge base articles help citizens find information at any time — without having to speak with a representative; you can also use AI and bots to handle simpler interactions that don't require an employee.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle: [
							"Provide secure, personalised ",
							<Link
								to="/services/customer-journey-experience"
								className="interLink"
							>
								customer experiences
							</Link>,
							" and engage employees with an all-in-one solution",
						],
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	// capital
	{
		slag: "capital-market",
		pagedata: [
			{
				metaDescription:
					"Through innovative capital market software, our cloud contact center assists the customer in providing the best possible service for a satisfactory experience.",
				title: "Capital Markets Software | Call Center for Capital Market",
				subtitle: "Capital Market",
				bannerImg:
					"https://static.vecteezy.com/system/resources/previews/003/202/279/non_2x/stock-chart-businessman-investment-chart-growth-free-photo.jpg",
				BannerTitle: ["Discover investment opportunities"],
				sec1Title: "Enhanced CX always wins customer accolades",
				para: [
					"Radical Minds strive for exceeding customer expectation by providing world-class ",
					<Link
						to="/services/cloud-contact-center"
						className="interLink"
					>
						Contact Center Service
					</Link>,
					" with their niche product, which always intends to have an excellent customer experience.",
				],
				img: "../../assets/solution/capital.jpg",
				alt: "Capital Markets Software",
				leadingLogo: [bsfi1, bsfi2, bsfi3, bsfi4, bsfi5, bsfi7],
				section: [
					{
						title: "Redefine Contact Center Experience",
						feature: [
							{
								icon: "../../assets/solution/icons/capital-01.svg",
								title: "Unified Customer Interaction",
								para: "Every person always looks for correct information on the go and as a contact center, the responsibility lies with Agent, how well the customer has been informed about his/her information. Radical Minds' unified platform solves this industry problem with rich experience in the same industry.",
							},
							{
								icon: "../../assets/solution/icons/capital-02.svg",
								title: "Offer what suits the customer financial need",
								para: "Often Customer demands don't get filled due to the wrong offering. Radical Minds train their Agents across domain and meet domain-specific agents who get onboarded for that process to have customer gets the right product as per need.",
							},
							{
								icon: "../../assets/solution/icons/capital-03.svg",
								title: " Integrated information under single platform",
								para: "The industry is big, so the information is. Radical Minds offers seamless information integration across the platform to get this deliver to the customer with zero error and in turn delight the customer and win more customers.",
							},
						],
					},
					{
						title: ["Personalized Experience"],
						subtitle:
							"Radical Minds strongly believes that customer delight could happen with personalized experience only and this is what we are known for. Fast, Secure, Agile, and Nimble make us different. We offer a personalized experience in the field of Capital markets with dedicated Agents having expertise in the same domain.",
						feature: [
							{
								icon: "../../assets/solution/icons/capital-04.svg",
								title: "CRM Integrations",
								para: [
									"Capital Market CRM Integration is a comprehensive, end-to-end solution for managing the entire exchange process, from the initial market listing through ongoing management of the largest and most sophisticated corporate securities. The platform handles everything from trade reporting and settlement to compliance, risk management, investor relations, regulatory reporting, and more.",
								],
							},
							{
								icon: "../../assets/solution/icons/capital-05.svg",
								title: "Delivery excellence",
								para: [
									"Capital markets are characterized by high volatility and fast-changing conditions. In such a dynamic environment, firms must be able to meet their customers' needs quickly and efficiently, while at the same time ensuring that their products are of the highest quality. Firms must also be able to manage costs effectively, focusing on delivery excellence as a key part of their business strategy.",
								],
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
];
