import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageLayout from '../../Components/PageLayout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Policy/PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <>
            <PageLayout
                title={
                    "Multilingual BPO | Outsourced Virtual Call Center Solutions | Offshore Customer Support Services India"
                }
            >
                <div className='bg-gray'>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={10} className=' bg-white'>
                                <h1 className="my-4  privacy-policy-title">Privacy Policy</h1>
                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Introduction</h2>
                                    <p>
                                        Welcome to Radical Minds Technologies Pvt. Ltd. ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website <a href="http://www.radicalminds.in" target="_blank" rel="noopener noreferrer">www.radicalminds.in</a> (the “Website”). We adhere to national & international standards, including GDPR, to the best of our abilities to ensure your data is protected.
                                    </p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Information We Collect</h2>
                                    <h3>Personal Information You Provide</h3>
                                    <p>We collect personal information that you voluntarily provide when you:</p>
                                    <ul>
                                        <li>Sign up for our newsletter</li>
                                        <li>Create an account</li>
                                        <li>Make a purchase</li>
                                        <li>Contact customer service</li>
                                        <li>Participate in surveys or promotions</li>
                                    </ul>
                                    <p>This information may include:</p>
                                    <ul>
                                        <li>Your name</li>
                                        <li>Email address</li>
                                        <li>Phone number</li>
                                        <li>Payment information</li>
                                    </ul>
                                </section>

                                <section className="privacy-policy-section">
                                    <h3>Automatically Collected Information</h3>
                                    <p>When you visit our Website, we automatically collect certain information through cookies and other tracking technologies. This may include:</p>
                                    <ul>
                                        <li>IP address</li>
                                        <li>Browser type</li>
                                        <li>Device information</li>
                                        <li>Pages visited</li>
                                        <li>Time spent on our Website</li>
                                    </ul>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Use of Your Information</h2>
                                    <p>We use your personal information to:</p>
                                    <ul>
                                        <li>Provide and improve our services</li>
                                        <li>Process transactions</li>
                                        <li>Communicate with you about your account or transactions</li>
                                        <li>Send you promotional materials</li>
                                        <li>Conduct research and analysis</li>
                                        <li>Enhance your experience on our Website</li>
                                        <li>Comply with legal obligations</li>
                                    </ul>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Sharing Your Information</h2>
                                    <p>
                                        We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties who assist us in operating our Website, conducting our business, or providing services to you, under strict confidentiality agreements. These third parties are obligated to protect your information and use it solely for the purposes we specify.
                                    </p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>International Data Transfers</h2>
                                    <p>
                                        Your personal information may be transferred to, stored, and processed in a country other than your own. We ensure that such transfers comply with applicable data protection laws and that your personal information is protected as described in this Privacy Policy.
                                    </p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Data Security</h2>
                                    <p>We implement a variety of security measures to ensure the safety of your personal information. These measures include:</p>
                                    <ul>
                                        <li>Encryption of data during transfer and at rest</li>
                                        <li>Regular security audits</li>
                                        <li>Access controls to limit access to your personal information</li>
                                    </ul>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Data Retention</h2>
                                    <p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law. When your information is no longer needed, we will securely delete or anonymize it.</p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Your Rights</h2>
                                    <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                                    <ul>
                                        <li><strong>Right to access</strong> - You can request a copy of your personal information.</li>
                                        <li><strong>Right to rectification</strong> - You can request correction of inaccurate or incomplete data.</li>
                                        <li><strong>Right to erasure</strong> - You can request deletion of your personal information under certain circumstances.</li>
                                        <li><strong>Right to restrict processing</strong> - You can request to limit the processing of your data.</li>
                                        <li><strong>Right to data portability</strong> - You can request transfer of your data to another organization.</li>
                                        <li><strong>Right to object</strong> - You can object to the processing of your data in certain situations.</li>
                                    </ul>
                                    <p>To exercise these rights, please contact us at <a href="mailto:info@radicalminds.in">info@radicalminds.in</a></p>
                                </section>


                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Cookies</h2>
                                    <p>We use cookies to enhance your experience on our Website. You can choose to accept or decline cookies through your browser settings. However, disabling cookies may affect the functionality of our Website.</p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Changes to This Privacy Policy</h2>
                                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. Your continued use of the Website after any changes constitutes your acceptance of the new Privacy Policy.</p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Contact Us</h2>
                                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                    <p>Email: <a href="mailto:info@radicalminds.in">info@radicalminds.in</a></p>
                                    <p>Last Updated: 10-July-2024</p>
                                    <p>By using our Website, you consent to our Privacy Policy and agree to its terms. We are committed to protecting your privacy and ensuring the security of your personal information. Thank you for trusting Radical Minds Technologies Pvt. Ltd.</p>
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </PageLayout>
            <ToastContainer />
        </>
    );
}

export default PrivacyPolicy;
