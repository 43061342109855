import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";

import PageLayout from "../../Components/PageLayout";
import { api } from "../../helper/api";
import "./Award.scss";
const Award = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const loadCareerData = async () => {
      try {
        const response = await api({
          method: "post",
          url: "fetch",
          data: { fetch_all_awards: true },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        if (jsonData.status === 200) {
          setloading(true);
          setData(jsonData.data);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setloading(false);
        }
      }
    };
    loadCareerData();
  }, []);

  return (
    <PageLayout title="Awards RMT">
      <div className="award_page">
        <BannerImage
          BannerTitle={"Inspired to be best"}
          PageName="Our Awards"
          Image={"./assets/banner/award.jpg"}
        />
        <div className="award_section1">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="ms-d-auto header__navigate">
                <Link to="/media" className="btn btn-primary me-2">
                  Media
                </Link>
                <Link to="/press-relase" className="btn btn-primary me-4">
                  Press Release
                </Link>
              </div>
            </div>
            <div className="row">
              {loading ? (
                data.length > 0 ? (
                  data.map((aw) => {
                    return (
                      <div className="col-md-6 col-12 col-awards">
                        <div className="row-awards">
                          <div
                            className="blog_image pos-r w-100 d-block overflow-hidden"
                            style={{ paddingTop: "calc(24600%/416)" }}
                          >
                            <img
                              src={`${aw.images[0].src}`}
                              className="bg_img pos-a t-0 l-0 w-100 h-100"
                              alt="Awards"
                              style={{ width: "100%", height: "100%", objectFit: "cover"}}
                            />
                          </div>
                          <div className="col-12">
                            <label className="mb-2 mt-4 p-0">{aw.title}</label>
                            <p className="mt-1 line-limit-5">{aw.short_content.replace(/\\r\\n/g, ' ')}</p>
                            <Link
                              to={`/award/${aw.uni_url}`}
                              state={aw}
                              className="mt-16 readMoreBtn"
                            >
                              Read more
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>Not Found</>
                )
              ) : (
                <>Loading....</>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Award;
