// import React from "react";
// import PageLayout from "../../Components/PageLayout";
// import "./About.scss";
// import { AiFillBank, AiFillHome } from "react-icons/ai";
// import BannerImage from "../../Components/BannerImge/BannerImage";
// import { HiShoppingCart } from "react-icons/hi";
// import { MdSecurity } from "react-icons/md";
// import { GiHealthNormal } from "react-icons/gi";
// import { BsFillBagFill, BsGraphUp } from "react-icons/bs";
// import { FaUserFriends } from "react-icons/fa";
// import { IoMdAirplane } from "react-icons/io";
// import { AboutBanner2 } from "../../assets";

// const About = () => {
// 	return (
// 		<PageLayout
// 			title="About Us – Radical Minds Technologies Pvt. Ltd"
// 			metaDescription={
// 				"Radical Minds is one of the top business process outsourcing organizations providing BPO solutions, BFSI services, and CRM solutions."
// 			}
// 			className="career-page"
// 		>
// 			<BannerImage
// 				Image={AboutBanner2}
// 				PageName={"About Us"}
// 				BannerTitle={["Your experience starts here"]}
// 			/>
// 			<div className="bg-gray fact about-page">
// 				<div className="container pb-lg-0 pb-2">
// 					<h2 className=" text-md-center text-left section-subheading mb-lg-4 mb-2 w-700">
// 						About Radical Minds
// 					</h2>
// 					<p className="text-md-center text-left mt-md-0 mt-4">
// 						Radical Minds is a leading transformational outsourcing
// 						(BPO)company. We combine our deep industry knowledge
// 						with technology and business expertise to co-create
// 						innovative, digital-led transformational solutions. We
// 						care for our customers so we have conceived, designed,
// 						and also planned service with the aim to serve various
// 						industries. Our company offers result-driven services
// 						like lead generation, sales & marketing, customer
// 						retention management service, BFSI (BPO, KPO, LPO), etc.
// 						that empower business as well as enhance the credibility
// 						and profitability of the company.
// 					</p>
// 				</div>
// 				<div className="container py-4 p-relative">
// 					<div className="facts_fig about-fact">
// 						<div>
// 							<h2 className=" text-center section-subheading mb-lg-4 mb-2 w-700">
// 								You have reached the right place to increase
// 								your ROI
// 							</h2>
// 							<div className="row">
// 								<div className="col-md-4 col-12">
// 									<div className="facts_f red">
// 										<h2 className="facts_num">32+</h2>
// 										<h4 className="fact_title">
// 											Languages Support
// 										</h4>
// 									</div>
// 								</div>
// 								<div className="col-md-4 col-12">
// 									<div className="facts_f green">
// 										<h2 className="facts_num">7000+</h2>
// 										<h4 className="fact_title">
// 											Employees
// 										</h4>
// 									</div>
// 								</div>
// 								<div className="col-md-4 col-12">
// 									<div className="facts_f blue">
// 										<h2 className="facts_num">100+</h2>
// 										<h4 className="fact_title">
// 											{" "}
// 											Global Clients
// 										</h4>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 			<div className="container mb-md-4 mb-0">
// 				<div className="row">
// 					<div className="col-md-6 col-12">
// 						<div className="ourvalue_container about-center">
// 							<div className="icon">
// 								<svg
// 									xmlns="http://www.w3.org/2000/svg"
// 									version="1.1"
// 									viewBox="0 0 510.833 510.833"
// 									width={150}
// 								>
// 									<g>
// 										<g>
// 											<path d="m255.417 65.563c4.143 0 7.5-3.357 7.5-7.5v-50.563c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v50.563c0 4.143 3.357 7.5 7.5 7.5z"></path>
// 											<path d="m115.487 119.132c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607l-35.754-35.755c-2.929-2.927-7.677-2.929-10.607 0-2.929 2.93-2.929 7.678 0 10.607z"></path>
// 											<path d="m65.027 240.953h-50.564c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.499-7.5z"></path>
// 											<path d="m115.487 377.775-35.754 35.754c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.754c2.929-2.93 2.929-7.678 0-10.607-2.929-2.927-7.677-2.927-10.607 0z"></path>
// 											<path d="m395.346 377.775c-2.93-2.928-7.678-2.928-10.607 0-2.929 2.93-2.929 7.678 0 10.607l35.754 35.754c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607z"></path>
// 											<path d="m496.37 240.953h-50.563c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"></path>
// 											<path d="m420.493 72.77-35.754 35.755c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.755c2.929-2.93 2.929-7.678 0-10.607-2.93-2.928-7.678-2.928-10.607 0z"></path>
// 											<path d="m403.579 233.293c0-82.872-66.465-150.293-148.162-150.293-28.618 0-56.394 8.293-80.324 23.982-23.282 15.265-41.842 36.74-53.673 62.103-1.751 3.754-.127 8.217 3.626 9.968 3.757 1.753 8.217.127 9.968-3.626 21.939-47.035 69.2-77.427 120.403-77.427 73.426 0 133.162 60.692 133.162 135.293 0 35.434-13.39 68.934-37.702 94.327-14.013 14.635-25.941 28.619-29.01 48.213h-130.939c-3.867-20.341-17.742-34.612-30.21-47.425-24.803-25.49-38.463-59.27-38.463-95.115 0-8.851.847-17.703 2.516-26.312.789-4.066-1.868-8.002-5.935-8.791-4.066-.783-8.002 1.869-8.791 5.936-1.852 9.547-2.79 19.36-2.79 29.167 0 39.775 15.169 77.27 42.713 105.576 15.819 16.256 27.017 29.232 27.017 48.251 0 .066.001.134.003.2l.43 44.013c0 14.279 7.617 26.812 19 33.752v8.248c0 20.678 16.822 37.5 37.5 37.5h46c20.678 0 37.5-16.822 37.5-37.5v-8.248c11.383-6.94 19-19.473 19-33.752 0 0-.053-49.026-.153-49.515 1.423-17.382 11.959-29.737 25.447-43.825 26.997-28.199 41.867-65.383 41.867-104.7zm-101.162 240.04c0 12.406-10.094 22.5-22.5 22.5h-46c-12.406 0-22.5-10.094-22.5-22.5v-2.891c1.799.252 3.633.391 5.5.391h80c1.867 0 3.701-.14 5.5-.391zm-5.5-17.5h-80c-13.51 0-24.5-10.99-24.5-24.5v-.5h76c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-76v-25h128.768l.232 25h-18c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h18v.5c0 13.51-10.991 24.5-24.5 24.5z"></path>
// 											<path d="m304.177 234.285c-2.54 3.271-1.947 7.983 1.324 10.523 3.27 2.541 7.982 1.949 10.523-1.324l14.848-19.121c3.932-5.063 5.656-11.354 4.856-17.715-.801-6.361-4.03-12.029-9.094-15.961-5.063-3.932-11.358-5.666-17.715-4.855-6.361.8-12.029 4.029-15.96 9.093l-48.439 62.384-27.8-30.759c-8.875-9.816-24.082-10.582-33.897-1.711-4.756 4.299-7.553 10.192-7.877 16.595-.323 6.402 1.866 12.547 6.164 17.304l47.001 52c9.461 10.974 28.156 10.301 36.761-1.374l29.685-38.23c2.54-3.271 1.947-7.983-1.324-10.523s-7.982-1.949-10.523 1.324l-29.685 38.23c-3.222 4.378-10.253 4.633-13.785.515l-47-52c-3.328-3.682-3.04-9.384.643-12.712 3.68-3.324 9.383-3.04 12.711.643l33.8 37.396c2.946 3.416 8.802 3.22 11.488-.43l53.927-69.451c2.887-3.882 8.873-4.635 12.628-1.589 3.883 2.888 4.635 8.869 1.589 12.629z"></path>
// 										</g>
// 									</g>
// 								</svg>
// 							</div>
// 							<h4 className="section-subheading mt-3 pt-2">
// 								Our Vision
// 							</h4>
// 							<p>
// 								Our vision is to provide exceptional business
// 								process outsourcing services to our clients by
// 								combining cutting-edge technology, talented and
// 								dedicated professionals, and a commitment to
// 								quality. We aim to become a trusted partner to
// 								businesses around the world, helping them to
// 								streamline operations, improve efficiency, and
// 								achieve their goals. Our ultimate goal is to
// 								provide unparalleled value to our clients and
// 								gain more business to grow and become a much
// 								larger organization with diverse sectors and
// 								world-class administration by 2025.
// 							</p>
// 						</div>
// 					</div>
// 					<div className="col-md-6 col-12">
// 						<div className="ourvalue_container about-center">
// 							<div className="icon">
// 								<svg
// 									version="1.1"
// 									viewBox="0 0 752 752"
// 									xmlns="http://www.w3.org/2000/svg"
// 									width={165}
// 								>
// 									<g>
// 										<path d="m546.49 248.13c-2.3867-3.1289-6.8516-3.7422-9.9961-1.3711l-0.71094 0.66406h0.003906c-2.6328 2.5117-2.918 6.6133-0.66406 9.4688 30.93 41.047 45.148 92.297 39.781 143.41-5.3633 51.113-29.91 98.293-68.688 132.02-38.781 33.73-88.906 51.5-140.27 49.73-51.367-1.7695-100.15-22.953-136.51-59.273-36.363-36.32-57.605-85.078-59.434-136.44-1.832-51.363 15.879-101.51 49.562-140.33 33.684-38.816 80.836-63.422 131.94-68.844 51.109-5.4258 102.38 8.7305 143.46 39.613 2.8086 2.1328 6.7617 1.8477 9.2344-0.66406l0.71094-0.71094c2.3828-3.0391 1.9219-7.4219-1.043-9.8984-46.027-34.66-103.94-49.617-160.99-41.578-57.055 8.0391-108.58 38.41-143.24 84.438s-49.617 103.94-41.578 160.99c8.0352 57.051 38.41 108.58 84.438 143.24 37.445 28.34 83.129 43.672 130.09 43.664 54.176 0.058594 106.42-20.109 146.5-56.555 40.082-36.445 65.105-86.547 70.176-140.48 5.0664-53.934-10.184-107.82-42.77-151.1z" />
// 										<path d="m461.25 261.77c0.32422-0.32422 0.625-0.67188 0.89844-1.043 2.1953-3.2578 1.3516-7.6797-1.8945-9.8984-40.648-27.637-92.008-34.34-138.39-18.062-46.383 16.277-82.289 53.602-96.754 100.58-14.469 46.984-5.7734 98.039 23.422 137.59 29.195 39.547 75.43 62.891 124.59 62.906 46.141-0.035156 89.867-20.633 119.27-56.191 29.402-35.559 41.426-82.375 32.793-127.7-3.8203-20.32-11.734-39.656-23.254-56.828-1.0391-1.5742-2.668-2.6641-4.5195-3.0273-1.8516-0.36719-3.7695 0.023438-5.332 1.0859-0.375 0.24219-0.72656 0.52734-1.0391 0.85156-2.418 2.3984-2.7969 6.1719-0.90234 9 10.465 15.633 17.648 33.227 21.125 51.715 8.1914 42.953-4.0625 87.273-33.152 119.92-29.09 32.648-71.707 49.91-115.32 46.707-43.613-3.2031-83.25-26.508-107.26-63.059-24.008-36.547-29.648-82.18-15.266-123.48 14.379-41.293 47.148-73.551 88.664-87.281 41.516-13.73 87.059-7.3711 123.22 17.211 2.8711 1.9219 6.707 1.5039 9.0938-0.99609z" />
// 										<path d="m346.97 310.79c18.07-6.957 38.176-6.3945 55.832 1.5625 2.6289 1.168 5.6992 0.625 7.7695-1.3711 0.67969-0.62891 1.2266-1.3828 1.6094-2.2266 0.80859-1.7305 0.88281-3.7148 0.20312-5.5-0.67578-1.7852-2.0508-3.2188-3.8047-3.9727-24.723-11.023-53.168-9.918-76.969 2.9844-23.797 12.902-40.242 36.137-44.5 62.871-4.2539 26.734 4.1641 53.93 22.781 73.582 18.617 19.652 45.312 29.535 72.238 26.734 26.926-2.8008 51.02-17.965 65.191-41.031 14.176-23.062 16.816-51.406 7.1484-76.691-0.66797-1.7695-2.0117-3.1992-3.7383-3.9727-1.7227-0.76953-3.6836-0.82422-5.4492-0.14844-0.86328 0.32812-1.6523 0.82812-2.3203 1.4688-2.1055 1.9727-2.8008 5.0273-1.75 7.7188 6.9102 18.078 6.3555 38.16-1.5391 55.832-7.8945 17.668-22.488 31.48-40.562 38.391-18.078 6.9141-38.164 6.3633-55.832-1.5312-17.672-7.8945-31.484-22.484-38.398-40.562-6.9141-18.078-6.3633-38.16 1.5273-55.832 7.8945-17.672 22.484-31.484 40.562-38.398z" />
// 										<path d="m589.11 187.89h-14.965l19.984-19.984c2.6094-2.8008 2.5312-7.1602-0.17578-9.8672-2.7031-2.7031-7.0664-2.7812-9.8633-0.17188l-19.984 19.984v-14.965c0-3.9258-3.1836-7.1055-7.1055-7.1055s-7.1055 3.1797-7.1055 7.1055v29.172l-169.92 169.73c-5.0703-2.0391-10.789-1.7109-15.59 0.89453-4.8047 2.6094-8.1914 7.2227-9.2422 12.59-1.0508 5.3633 0.34766 10.914 3.8164 15.141 3.4648 4.2266 8.6367 6.6836 14.102 6.707 2.2344 0.007812 4.4531-0.41016 6.5352-1.2344 4.543-1.7383 8.207-5.207 10.195-9.6445 1.9883-4.4414 2.1367-9.4844 0.41016-14.035v-0.37891l169.73-169.73h29.172c3.9219 0 7.1016-3.1797 7.1016-7.1016 0-3.9258-3.1797-7.1055-7.1016-7.1055z" />
// 									</g>
// 								</svg>
// 							</div>
// 							<h4 className="section-subheading  pt-2">
// 								Our Mission
// 							</h4>
// 							<p>
// 								Our mission is to provide top-notch BPO services
// 								to our clients at an affordable price. We have
// 								been in the industry for more than 12 years, and
// 								we are well-versed in all aspects of BPO. We
// 								believe that our experience and expertise will
// 								help you achieve your business goals.
// 							</p>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 			<div className="bg-gray">
// 				<div className="container">
// 					<h2 className="section-heading text-center">
// 						Radical Minds Journey last 12+ years
// 					</h2>
// 					<div className="story__row">
// 						<div className="row">
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<FaUserFriends />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2010</div>
// 										<p>50 Employees</p>
// 										<p className="subtitle">
// 											The Beginning
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<BsGraphUp />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2011</div>
// 										<p>200 Employees </p>
// 										<p className="subtitle">
// 											{" "}
// 											+telesales process
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<MdSecurity />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2013</div>
// 										<p>500 Employees</p>
// 										<p className="subtitle">
// 											+Employement service process
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<IoMdAirplane />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2015</div>
// 										<p>1800 Employees </p>
// 										<p className="subtitle">
// 											+E-commerce travel company
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<GiHealthNormal />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2016</div>
// 										<p>2200 Employees </p>
// 										<p className="subtitle">
// 											Health & wellness process and online
// 											food aggregator
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<BsFillBagFill />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2017</div>
// 										<p>3000 Employees </p>
// 										<p className="subtitle">
// 											+International travel client
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<AiFillHome />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2019</div>
// 										<p>4500 Employees</p>
// 										<p className="subtitle">
// 											Health & wellness process and <br />
// 											BFSI Process
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<HiShoppingCart />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2021</div>
// 										<p>5000+ Employees</p>
// 										<p className="subtitle">
// 											Focus on doubling the current count
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-lg-4 col-md-6 col-12">
// 								<div className="story__sec">
// 									<div className="icon">
// 										<AiFillBank />
// 									</div>
// 									<div className="story__content">
// 										<div className="year">2022</div>
// 										<p>7000+ Employees</p>
// 										<p className="subtitle">
// 											Focus on doubling the current count
// 										</p>
// 										<p className="subtitle">
// 											Accquired GAMMA BPO
// 										</p>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</PageLayout>
// 	);
// };

// export default About;

import React from "react";
import PageLayout from "../../Components/PageLayout";
import "./About.scss";
import { AiFillBank, AiFillHome } from "react-icons/ai";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { HiShoppingCart } from "react-icons/hi";
import { MdSecurity } from "react-icons/md";
import { GiHealthNormal } from "react-icons/gi";
import { BsFillBagFill, BsGraphUp } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { IoMdAirplane } from "react-icons/io";
import { AboutBanner2 } from "../../assets";

const About = () => {
	return (
		<PageLayout
			title="About Us – Radical Minds Technologies Pvt. Ltd"
			metaDescription={
				"Radical Minds is one of the top business process outsourcing organizations providing BPO solutions, BFSI services, and CRM solutions."
			}
			className="career-page"
		>
			<BannerImage
				Image={AboutBanner2}
				PageName={"About Us"}
				BannerTitle={["Your experience starts here"]}
			/>
			<div className="bg-gray fact about-page">
				<div className="container pb-lg-0 pb-2">
					<h2 className=" text-md-center text-left section-subheading mb-lg-4 mb-2 w-700">
						About Radical Minds
					</h2>
					<p className="text-md-center text-left mt-md-0 mt-4">
						Radical Minds is a leading transformational outsourcing
						(BPO)company. We combine our deep industry knowledge
						with technology and business expertise to co-create
						innovative, digital-led transformational solutions. We
						care for our customers so we have conceived, designed,
						and also planned service with the aim to serve various
						industries. Our company offers result-driven services
						like lead generation, sales & marketing, customer
						retention management service, BFSI (BPO, KPO, LPO), etc.
						that empower business as well as enhance the credibility
						and profitability of the company.
					</p>
				</div>
				<div className="container py-4 p-relative">
					<div className="facts_fig about-fact">
						<div>
							<h2 className=" text-center section-subheading mb-lg-4 mb-2 w-700">
								You have reached the right place to increase
								your ROI
							</h2>
							<div className="row">
								<div className="col-md-4 col-12">
									<div className="facts_f red">
										<h2 className="facts_num">32+</h2>
										<h4 className="fact_title">
											Languages Support
										</h4>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="facts_f green">
										<h2 className="facts_num">7000+</h2>
										<h4 className="fact_title">
											Employees
										</h4>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="facts_f blue">
										<h2 className="facts_num">100+</h2>
										<h4 className="fact_title">
											{" "}
											Global Clients
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container mb-md-4 mb-0">
				<div className="row">
					<div className="col-md-6 col-12">
						<div className="ourvalue_container about-center">
							<div className="icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									viewBox="0 0 510.833 510.833"
									width={150}
								>
									<g>
										<g>
											<path d="m255.417 65.563c4.143 0 7.5-3.357 7.5-7.5v-50.563c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v50.563c0 4.143 3.357 7.5 7.5 7.5z"></path>
											<path d="m115.487 119.132c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607l-35.754-35.755c-2.929-2.927-7.677-2.929-10.607 0-2.929 2.93-2.929 7.678 0 10.607z"></path>
											<path d="m65.027 240.953h-50.564c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.499-7.5z"></path>
											<path d="m115.487 377.775-35.754 35.754c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.754c2.929-2.93 2.929-7.678 0-10.607-2.929-2.927-7.677-2.927-10.607 0z"></path>
											<path d="m395.346 377.775c-2.93-2.928-7.678-2.928-10.607 0-2.929 2.93-2.929 7.678 0 10.607l35.754 35.754c2.93 2.928 7.677 2.928 10.607 0 2.929-2.93 2.929-7.678 0-10.607z"></path>
											<path d="m496.37 240.953h-50.563c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h50.563c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"></path>
											<path d="m420.493 72.77-35.754 35.755c-2.929 2.93-2.929 7.678 0 10.607 2.93 2.928 7.677 2.928 10.607 0l35.754-35.755c2.929-2.93 2.929-7.678 0-10.607-2.93-2.928-7.678-2.928-10.607 0z"></path>
											<path d="m403.579 233.293c0-82.872-66.465-150.293-148.162-150.293-28.618 0-56.394 8.293-80.324 23.982-23.282 15.265-41.842 36.74-53.673 62.103-1.751 3.754-.127 8.217 3.626 9.968 3.757 1.753 8.217.127 9.968-3.626 21.939-47.035 69.2-77.427 120.403-77.427 73.426 0 133.162 60.692 133.162 135.293 0 35.434-13.39 68.934-37.702 94.327-14.013 14.635-25.941 28.619-29.01 48.213h-130.939c-3.867-20.341-17.742-34.612-30.21-47.425-24.803-25.49-38.463-59.27-38.463-95.115 0-8.851.847-17.703 2.516-26.312.789-4.066-1.868-8.002-5.935-8.791-4.066-.783-8.002 1.869-8.791 5.936-1.852 9.547-2.79 19.36-2.79 29.167 0 39.775 15.169 77.27 42.713 105.576 15.819 16.256 27.017 29.232 27.017 48.251 0 .066.001.134.003.2l.43 44.013c0 14.279 7.617 26.812 19 33.752v8.248c0 20.678 16.822 37.5 37.5 37.5h46c20.678 0 37.5-16.822 37.5-37.5v-8.248c11.383-6.94 19-19.473 19-33.752 0 0-.053-49.026-.153-49.515 1.423-17.382 11.959-29.737 25.447-43.825 26.997-28.199 41.867-65.383 41.867-104.7zm-101.162 240.04c0 12.406-10.094 22.5-22.5 22.5h-46c-12.406 0-22.5-10.094-22.5-22.5v-2.891c1.799.252 3.633.391 5.5.391h80c1.867 0 3.701-.14 5.5-.391zm-5.5-17.5h-80c-13.51 0-24.5-10.99-24.5-24.5v-.5h76c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-76v-25h128.768l.232 25h-18c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h18v.5c0 13.51-10.991 24.5-24.5 24.5z"></path>
											<path d="m304.177 234.285c-2.54 3.271-1.947 7.983 1.324 10.523 3.27 2.541 7.982 1.949 10.523-1.324l14.848-19.121c3.932-5.063 5.656-11.354 4.856-17.715-.801-6.361-4.03-12.029-9.094-15.961-5.063-3.932-11.358-5.666-17.715-4.855-6.361.8-12.029 4.029-15.96 9.093l-48.439 62.384-27.8-30.759c-8.875-9.816-24.082-10.582-33.897-1.711-4.756 4.299-7.553 10.192-7.877 16.595-.323 6.402 1.866 12.547 6.164 17.304l47.001 52c9.461 10.974 28.156 10.301 36.761-1.374l29.685-38.23c2.54-3.271 1.947-7.983-1.324-10.523s-7.982-1.949-10.523 1.324l-29.685 38.23c-3.222 4.378-10.253 4.633-13.785.515l-47-52c-3.328-3.682-3.04-9.384.643-12.712 3.68-3.324 9.383-3.04 12.711.643l33.8 37.396c2.946 3.416 8.802 3.22 11.488-.43l53.927-69.451c2.887-3.882 8.873-4.635 12.628-1.589 3.883 2.888 4.635 8.869 1.589 12.629z"></path>
										</g>
									</g>
								</svg>
							</div>
							<h4 className="section-subheading mt-3 pt-2">
								Our Vision
							</h4>
							<p>
								Our vision is to provide exceptional business
								process outsourcing services to our clients by
								combining cutting-edge technology, talented and
								dedicated professionals, and a commitment to
								quality. We aim to become a trusted partner to
								businesses around the world, helping them to
								streamline operations, improve efficiency, and
								achieve their goals. Our ultimate goal is to
								provide unparalleled value to our clients and
								gain more business to grow and become a much
								larger organization with diverse sectors and
								world-class administration by 2025.
							</p>
						</div>
					</div>
					<div className="col-md-6 col-12">
						<div className="ourvalue_container about-center">
							<div className="icon">
								<svg
									version="1.1"
									viewBox="0 0 752 752"
									xmlns="http://www.w3.org/2000/svg"
									width={165}
								>
									<g>
										<path d="m546.49 248.13c-2.3867-3.1289-6.8516-3.7422-9.9961-1.3711l-0.71094 0.66406h0.003906c-2.6328 2.5117-2.918 6.6133-0.66406 9.4688 30.93 41.047 45.148 92.297 39.781 143.41-5.3633 51.113-29.91 98.293-68.688 132.02-38.781 33.73-88.906 51.5-140.27 49.73-51.367-1.7695-100.15-22.953-136.51-59.273-36.363-36.32-57.605-85.078-59.434-136.44-1.832-51.363 15.879-101.51 49.562-140.33 33.684-38.816 80.836-63.422 131.94-68.844 51.109-5.4258 102.38 8.7305 143.46 39.613 2.8086 2.1328 6.7617 1.8477 9.2344-0.66406l0.71094-0.71094c2.3828-3.0391 1.9219-7.4219-1.043-9.8984-46.027-34.66-103.94-49.617-160.99-41.578-57.055 8.0391-108.58 38.41-143.24 84.438s-49.617 103.94-41.578 160.99c8.0352 57.051 38.41 108.58 84.438 143.24 37.445 28.34 83.129 43.672 130.09 43.664 54.176 0.058594 106.42-20.109 146.5-56.555 40.082-36.445 65.105-86.547 70.176-140.48 5.0664-53.934-10.184-107.82-42.77-151.1z" />
										<path d="m461.25 261.77c0.32422-0.32422 0.625-0.67188 0.89844-1.043 2.1953-3.2578 1.3516-7.6797-1.8945-9.8984-40.648-27.637-92.008-34.34-138.39-18.062-46.383 16.277-82.289 53.602-96.754 100.58-14.469 46.984-5.7734 98.039 23.422 137.59 29.195 39.547 75.43 62.891 124.59 62.906 46.141-0.035156 89.867-20.633 119.27-56.191 29.402-35.559 41.426-82.375 32.793-127.7-3.8203-20.32-11.734-39.656-23.254-56.828-1.0391-1.5742-2.668-2.6641-4.5195-3.0273-1.8516-0.36719-3.7695 0.023438-5.332 1.0859-0.375 0.24219-0.72656 0.52734-1.0391 0.85156-2.418 2.3984-2.7969 6.1719-0.90234 9 10.465 15.633 17.648 33.227 21.125 51.715 8.1914 42.953-4.0625 87.273-33.152 119.92-29.09 32.648-71.707 49.91-115.32 46.707-43.613-3.2031-83.25-26.508-107.26-63.059-24.008-36.547-29.648-82.18-15.266-123.48 14.379-41.293 47.148-73.551 88.664-87.281 41.516-13.73 87.059-7.3711 123.22 17.211 2.8711 1.9219 6.707 1.5039 9.0938-0.99609z" />
										<path d="m346.97 310.79c18.07-6.957 38.176-6.3945 55.832 1.5625 2.6289 1.168 5.6992 0.625 7.7695-1.3711 0.67969-0.62891 1.2266-1.3828 1.6094-2.2266 0.80859-1.7305 0.88281-3.7148 0.20312-5.5-0.67578-1.7852-2.0508-3.2188-3.8047-3.9727-24.723-11.023-53.168-9.918-76.969 2.9844-23.797 12.902-40.242 36.137-44.5 62.871-4.2539 26.734 4.1641 53.93 22.781 73.582 18.617 19.652 45.312 29.535 72.238 26.734 26.926-2.8008 51.02-17.965 65.191-41.031 14.176-23.062 16.816-51.406 7.1484-76.691-0.66797-1.7695-2.0117-3.1992-3.7383-3.9727-1.7227-0.76953-3.6836-0.82422-5.4492-0.14844-0.86328 0.32812-1.6523 0.82812-2.3203 1.4688-2.1055 1.9727-2.8008 5.0273-1.75 7.7188 6.9102 18.078 6.3555 38.16-1.5391 55.832-7.8945 17.668-22.488 31.48-40.562 38.391-18.078 6.9141-38.164 6.3633-55.832-1.5312-17.672-7.8945-31.484-22.484-38.398-40.562-6.9141-18.078-6.3633-38.16 1.5273-55.832 7.8945-17.672 22.484-31.484 40.562-38.398z" />
										<path d="m589.11 187.89h-14.965l19.984-19.984c2.6094-2.8008 2.5312-7.1602-0.17578-9.8672-2.7031-2.7031-7.0664-2.7812-9.8633-0.17188l-19.984 19.984v-14.965c0-3.9258-3.1836-7.1055-7.1055-7.1055s-7.1055 3.1797-7.1055 7.1055v29.172l-169.92 169.73c-5.0703-2.0391-10.789-1.7109-15.59 0.89453-4.8047 2.6094-8.1914 7.2227-9.2422 12.59-1.0508 5.3633 0.34766 10.914 3.8164 15.141 3.4648 4.2266 8.6367 6.6836 14.102 6.707 2.2344 0.007812 4.4531-0.41016 6.5352-1.2344 4.543-1.7383 8.207-5.207 10.195-9.6445 1.9883-4.4414 2.1367-9.4844 0.41016-14.035v-0.37891l169.73-169.73h29.172c3.9219 0 7.1016-3.1797 7.1016-7.1016 0-3.9258-3.1797-7.1055-7.1016-7.1055z" />
									</g>
								</svg>
							</div>
							<h4 className="section-subheading  pt-2">
								Our Mission
							</h4>
							<p>
								Our mission is to provide top-notch BPO services
								to our clients at an affordable price. We have
								been in the industry for more than 12 years, and
								we are well-versed in all aspects of BPO. We
								believe that our experience and expertise will
								help you achieve your business goals.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-gray">
				<div className="container">
					<h2 className="section-heading text-center">
						Radical Minds Journey last 12+ years
					</h2>
					<div className="story__row">
						<div className="row">
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<FaUserFriends />
									</div>
									<div className="story__content">
										<div className="year">2010</div>
										<p>50 Employees</p>
										<p className="subtitle">
											The Beginning
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<BsGraphUp />
									</div>
									<div className="story__content">
										<div className="year">2011</div>
										<p>200 Employees </p>
										<p className="subtitle">
											{" "}
											+telesales process
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<MdSecurity />
									</div>
									<div className="story__content">
										<div className="year">2013</div>
										<p>500 Employees</p>
										<p className="subtitle">
											+Employement service process
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<IoMdAirplane />
									</div>
									<div className="story__content">
										<div className="year">2015</div>
										<p>1800 Employees </p>
										<p className="subtitle">
											+E-commerce travel company
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<GiHealthNormal />
									</div>
									<div className="story__content">
										<div className="year">2016</div>
										<p>2200 Employees </p>
										<p className="subtitle">
											Health & wellness process and online
											food aggregator
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<BsFillBagFill />
									</div>
									<div className="story__content">
										<div className="year">2017</div>
										<p>3000 Employees </p>
										<p className="subtitle">
											+International travel client
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<AiFillHome />
									</div>
									<div className="story__content">
										<div className="year">2019</div>
										<p>4500 Employees</p>
										<p className="subtitle">
											Health & wellness process and <br />
											BFSI Process
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<HiShoppingCart />
									</div>
									<div className="story__content">
										<div className="year">2021</div>
										<p>5000+ Employees</p>
										<p className="subtitle">
											Focus on doubling the current count
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<div className="story__sec">
									<div className="icon">
										<AiFillBank />
									</div>
									<div className="story__content">
										<div className="year">2022</div>
										<p>7000+ Employees</p>
										<p className="subtitle">
											Focus on doubling the current count
										</p>
										<p className="subtitle">
											Accquired GAMMA BPO
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default About;

