import React, { useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../Components/BannerImge/BannerImage";
import PageLayout from "../../Components/PageLayout";
import "./Orangainsation-Type.scss";
import { useParams } from "react-router";
import { organizationType } from "./pages/organizationType";
import SmallSideBar from "../../Components/smallSideBar";
import SmallSideBarMobile from "../../Components/smallSideBarMobile";
import InquiryForm from "../../Components/inquiryForm";

const OrangainsationType = () => {
	const params = useParams();
	const slag = params.slag;
	const page = organizationType.find((prd) => prd.slag === slag);
	if (!page) {
		window.location.href = "/404-page-not-found";
	}
	const data = page.pagedata[0];
	const [showForm, setShowForm] = useState(false);


	const handleCloseForm = () => {
		setShowForm(false);
	};
	return (
		<PageLayout title={data.title} metaDescription={data.metaDescription}>
			<div className="solution_page">
			<SmallSideBar  isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false}/>
				<SmallSideBarMobile isEmail= {true}  isWhatsApp= {true} isQuote = {true} isDemo ={false} isSolutions ={false} />
				<BannerImage
					PageName={data.subtitle}
					BannerTitle={data.desc}
					Image={data.img}
				/>
				<div className="customer-sec">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12 pe-md-4">
								<div className="client-content p-0 d-flex flex-column h-100">
									<div>
										<h2 className="section-subheading text-left">
											{data.sec1Title}
										</h2>
									</div>
									<p>{data.para}</p>
								</div>
							</div>
							<div className="col-md-6 col-12">
								<div className="article_img mt-md-0 mt-3">
									<img
										src={data.img}
										className="img-fluid rounde-img shadow-img"
										alt={data.alt}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="rmt-abt bg-orange">
					<div className="container">
						<div className="w-1000 mx-auto">
							<h2 className="text-center section-subheading mb-2">
								{data.section[0].title}
							</h2>
							<p className="text-center">
								{" "}
								{data.section[0].subtitle}
							</p>
						</div>
						<div className="row rmt-row">
							{data.section[0].feature.map((feat) => (
								<div className="col-md-4 col-12 mb-lg-0 mb-4">
									<div className="rmt-card rmt-card-white center rmt-feature">
										<div className="icon icon80">
											<img
												src={feat.icon}
												alt=""
												className="img-fluid"
											/>
										</div>
										<div className="card-title">
											<h5>{feat.title}</h5>
										</div>
										<div className="card-desc">
											<p>{feat.para}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<section className="section-space bg-gray">
					<div className="container position-relative">
						<h2 className="text-center section-heading">
							{data.leadintTitle}
						</h2>
						<div className="brand__logo">
							<div className="w-1000 mx-auto">
								{/* <div className="brandLogoRow">
									{data.leadingLogo.map((logo, idx) => (
										<div className="logo-img">
											<img
												src={logo}
												alt=""
												className="img-fluid"
											/>
										</div>
									))}
								</div> */}
							</div>
						</div>
					</div>
				</section>
				<div className="section_5">
					<div className="container">
						<h2 className="section-heading text-center mb-2">
							{data.section[1].title}
						</h2>
						<p className="text-center">
							{data.section[1].subtitle}
						</p>
						<div className="row mt-4">
							{data.section[1].service.map((ser) => (
								<div className="col-md-4 col-sm-6 col-12">
									<div
										className="product_card icon-list w-100 h-100  vertical text-left"
										href="/en-sg/capabilities/integrations-apps"
										target="_blank"
										rel=""
									>
										<h5>{ser.title}</h5>
										<p>{ser.para}</p>
										<Link
											to={ser.link}
											className="arrow label-btn"
										>
											Learn more
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="container request_banner flex-column">
					<h2 className="section-heading text-center mb-2">
						Technology that enables communication
					</h2>
					<p className="text-center w-1000 mx-auto">
						Transform your contact centers into world-class customer
						experience centers and form longer-lasting
						relationships. Start your free trial, or speak with one
						of our team members today.
					</p>
					<button
						className="btn btn-primary btn-r mx-auto"
						// onClick={handleShow}
						onClick={() => setShowForm(true)}
					>
						Talk to an Expert
					</button>
				</div>
			</div>
			{showForm && <InquiryForm handleClose={handleCloseForm} />}
		</PageLayout>
	);
};

export default OrangainsationType;
