import React, { useEffect, useState } from "react";
import "./LocationTab.scss";
export const LocationTab = ({ children, active = 0 }) => {
	const [activeTab, setActiveTab] = useState(active);
	const [tabsData, setTabsData] = useState([]);

	useEffect(() => {
		let data = [];

		React.Children.forEach(children, (element) => {
			if (!React.isValidElement(element)) return;

			const {
				props: { tab, children },
			} = element;
			data.push({ tab, children });
		});
		setTabsData(data);
	}, [children]);

	return (
		<>
			<div className="row">
				<div className="col-xs-12 col-sm-3 col-md-3 col-lg-2">
					<div className="locationTab">
						<div className="navigation-tab">
							<ul>
								{tabsData.map(({ tab }, idx) => (
									<li key={idx}>
										<button
											type="button"
											className={`btn ${
												idx === activeTab
													? "active"
													: ""
											}`}
											onClick={() => {
												setActiveTab(idx);
											}}
										>
											{tab}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-9 col-md-9 col-lg-10">
					<div className="location-tabpanel">
						{tabsData[activeTab] && tabsData[activeTab].children}
					</div>
				</div>
			</div>
		</>
	);
};

const LocationPanel = ({ childrens }) => {
	return { childrens };
};

LocationTab.LocationPanel = LocationPanel;

export const CityTab = ({ children, active = 0 }) => {
	const [activeTab, setActiveTab] = useState(active);
	const [tabsData, setTabsData] = useState([]);

	useEffect(() => {
		let data = [];

		React.Children.forEach(children, (element) => {
			if (!React.isValidElement(element)) return;

			const {
				props: { tab, children },
			} = element;
			data.push({ tab, children });
		});
		setTabsData(data);
	}, [children]);

	return (
		<>
			<div className="row">
				<div className="col-md-3 col-12">
					<div className="location-content">
						<ul>
							{tabsData.map(({ tab }, idx) => (
								<li key={idx}>
									<button
										type="button"
										className={`btn ${
											idx === activeTab ? "active" : ""
										}`}
										onClick={() => setActiveTab(idx)}
									>
										{tab}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="col-md-9 col-12 country-content">
					{tabsData[activeTab] && tabsData[activeTab].children}
				</div>
			</div>
		</>
	);
};
const CityPanel = ({ childrens }) => {
	return { childrens };
};
CityTab.CityPanel = CityPanel;
