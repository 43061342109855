import React from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import {
	BiChevronRight
} from "react-icons/bi";
import { useState } from "react";
import { useEffect } from "react";

const Dropdown = ({ submenu, dropdown, depthlevel, isMegaMenu, isClass }) => {
	const [drop, setDrop] = useState(false);

	useEffect(() => {
		setDrop(dropdown)
	}, [dropdown])
	const handleClick = () => {
		setDrop(!drop);
	};
	depthlevel = depthlevel + 1;
	return (
		<>
			{isMegaMenu ? (
				<>
					<div
						className={`dropdown-menu dropdown-menu-v2  ${drop ? "show" : ""
							} ${isClass ? isClass : ""}`}>
						<div className="top-section">
							<div className="col-2">
								{isMegaMenu.map((menu, index) => {
									return (
										<div key={index}
											className={`innovation-wrapper ${menu.col ? "single_col" : ""
												}`}>
											<div className="title-wrapper">
												<h2>{menu.title}</h2>
											</div>
											<ul
												className={`${menu.class ? menu.class : ""
													}`}>
												{menu.submenu.map(
													(submen, index) => (
														<li key={index}>
															{submen.menu ? (
																<>
																	<Link
																		to={
																			submen.link
																		}>
																		<h3>
																			{
																				submen.title
																			}
																		</h3>
																		<p>
																			{submen.menu.map(
																				(
																					main,
																					index
																				) => (
																					<>
																						{
																							main.title
																						}{" "}
																					</>
																				)
																			)}
																		</p>
																	</Link>
																</>
															) : (
																<a
																	className="mega-drop-v1"
																	href={submen.link}
																	onClick={handleClick}
																>
																	<h3>
																		<span className="icon">
																			<BiChevronRight />
																		</span>
																		{submen.title}
																	</h3>
																	<p>{submen.desc}</p>
																</a>
															)}
														</li>
													)
												)}
											</ul>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</>
			) : (
				<ul className={`dropdown-menu droplevel_${depthlevel} ${drop ? "show" : ""}`}>
					{submenu.map((menu, index) => {
						return (
							<MenuItems
								items={menu}
								key={index}
								depthLevel={depthlevel}
							/>
						);
					})}
				</ul>
			)}
		</>
	);
};

export default Dropdown;
