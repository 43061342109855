import { Link } from "react-router-dom";
import {
	bsfi5,
	bsfi6,
	bsfi7,
	retail1,
	retail2,
	retail6,
	retail7,
	travel2,
	Logi1,
	Logi2,
	Logi4,
	travel3,
	Logi3,
	travel1,
	travel10,
} from "../../../assets";
export const organizationType = [
	//enterprises
	{
		slag: "enterprise",
		pagedata: [
			{
				metaDescription:
					"Radical Minds' contact center for enterprises offers a variety of products to help them streamline every aspect of their business.",
				title: "Enterprises Contact Center | Enterprises Call Center Software India",
				subtitle: "Enterprise",
				desc: "Large scale business solution",
				BannerTitle: [
					"Enterprise customer",
					<br />,
					"experience software",
				],
				sec1Title: "Enhance CX with cutting edge technology",
				para: [
					"At Radical Minds, we believe that CX is about more than just customer satisfaction. It's about building a relationship with your customers and then enhancing that relationship over time. That's why we're proud to offer cutting-edge technology that allows you to track every interaction your customers have with your brand from their first interaction with you to their last. From there, our system helps you identify patterns in their behavior so that you can build better relationships with them at every stage of their journey.",
					<br />,
					<br />,
					"We offer wide range of products that are designed to help you streamline every aspect of your business, from customer service to sales.",
					<br />,
					<br />,
					"We're proud to have served thousands of customers over the years through our unique approach to customer service and product development.",
				],
				leadintTitle: "Leading brands trust Radical Minds",
				leadingLogo: [Logi1, Logi2, Logi4, travel2, travel3],
				alt: "Enterprises Call Center Software",
				img: "../../assets/solution/enterprise.jpg",
				section: [
					{
						title: "Elighten your parther experince",
						subtitle:
							"Drive your business to a new scale with a world-class experience",
						feature: [
							{
								icon: "../../assets/solution/og-icons/ent-01.svg",
								title: "Personlise CX",
								para: "Every customer demand is different so the offering should be. Radical minds offer a personalised and dedicated team with cutting-edge infra and highly skilled domain expertise to achieve the highest Customer satisfaction.",
							},
							{
								icon: "../../assets/solution/og-icons/ent-02.svg",
								title: "Technology at its best",
								para: "Radical Minds provide all industry-compliant infrastructure and cutting-edge technology to meet all your needs under one umbrella, which enlighten the CX.",
							},
							{
								icon: "../../assets/solution/og-icons/ent-03.svg",
								title: "Cloud Ready Solution",
								para: "Worrying about your needs, Radical Minds offers a cloud-ready customized contact center to fulfill any Market demand and gets integrated with any CRM/ERP/Other applications, which in turn Customer Satisfaction.",
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell, or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Auto Predictive Dialer",
								para: "Auto predictive dialer future provides an easy and affordable way to grow your business.",
								link: "/services/auto-predictive-dialer",
							},
							{
								title: "Smart Routing",
								para: "Smart Routing is a smart and robust routing algorithm that balances the need for fast response against the correct answers",
								link: "/services/acd-smart-routing",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	//mid-sized
	{
		slag: "mid-sized",
		pagedata: [
			{
				metaDescription:
					"We provide a wide range of solutions through our call center services for small businesses to achieve successful growth.",
				title: "Call & Contact Center | Business Solution for Mid-Size Companies",
				subtitle: "Mid Sized",
				desc: "Scalable agile business Solution",
				BannerTitle: [
					"Deliver the best outsourced ",
					<br />,
					" service for Eevery customer",
				],
				sec1Title: "Cut the distance to meet customer demand",
				para: [
					"Radical Minds cloud/in prem ready Contact Center ready solution will always be loved by the customer as we value our customer growth journey and we ensure that no stone is left untouched to meet customer demand. We believe in making things easy and so the process be. Making the process simpler is our forte and our deep technology expertise helps us achieve this always. ",
					<Link to="/continous-innovation" className="interLink">
						Continuous Innovation
					</Link>,
					" help customer achieve their vision easily.",
				],
				leadintTitle: "Leading brands trust Radical Minds",
				leadingLogo: [bsfi7, bsfi6, bsfi5, retail2, retail1],
				alt: "Call Center Solutions for Small Business",
				img: "../../assets/solution/mid-business.jpg",
				section: [
					{
						title: "Make every expierence an unforgetteble experience",
						subtitle:
							"Managing and elevating the customer experience has never been so easy before but Radical Minds AI-enabled technology helps achieve this.",
						feature: [
							{
								icon: "../../assets/solution/og-icons/mid-01.svg",
								title: "Cloud",
								para: "Radical Minds offering focused on CX and Cloud architecture makes this possible and the way tech components combine to build a cloud. Infra resources get pooled through virtualization and shared across the network. We have an Open API-ready solution, which make integration easy, fast, secure, and transparent with any Application.",
							},
							{
								icon: "../../assets/solution/og-icons/mid-02.svg",
								title: "Agility",
								para: "Radical Minds believe in being agile means developing fast and making things available beyond imagination. Technology helps drive business growth. Cloud Agility allows us to treat issues like security, monitoring, and analysis.",
							},
							{
								icon: "../../assets/solution/og-icons/mid-03.svg",
								title: "Scalability",
								para: "Radical Minds Scalability means in every piece of infrastructure can be expanded to handle increased/decreased load and make the infra in line with the requirement of market demand.",
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell, or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Auto Predictive Dialer",
								para: "Auto predictive dialer future provides an easy and affordable way to grow your business.",
								link: "/services/auto-predictive-dialer",
							},
							{
								title: "Smart Routing",
								para: "Smart Routing is a smart and robust routing algorithm that balances the need for fast response against the correct answers",
								link: "/services/acd-smart-routing",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
	{
		slag: "small-business",
		pagedata: [
			{
				metaDescription:
					"Our contact center for small businesses provides a platform with numerous offerings to help them grow successfully.",
				title: "BPO Solution | Call Center | Contact Center for Small Business",
				subtitle: "Small Business",
				desc: "Solution for Compact Businesses",

				BannerTitle: [
					"Deliver the best outsourced ",
					<br />,
					" service for Eevery customer",
				],
				sec1Title: "Make winning as your habbit with small solution",
				para: [
					"You always think to make your business big but couldn't. Radical Minds make this happen for you. Making integration of all business pieces in one location through our cloud-ready solution will make you think, if this would have been so easy, I would have done it long back. No worries, connect with us and we will together make your dream true and make your dream into reality, which you would be thinking from a long.",
					<br />,
					<br />,
					"All you need is to click a button and grow your business with us. Your success is our success and we make this happen.",
				],
				leadintTitle: "Leading brands trust Radical Minds",
				leadingLogo: [Logi3, travel1, travel10, retail7, retail6],
				alt: "call center solutions for small business",
				img: "../../assets/solution/small-business.jpg",
				section: [
					{
						title: "Give your team what your business demands: Cloud Ready Solution",
						subtitle:
							"Win back time and resources with customer service tools designed for smooth customer engagements. An all-in-one contact centre solution gives your agents everything they need, right from the start.",
						feature: [
							{
								icon: "../../assets/solution/og-icons/small-01.svg",
								title: "Unlimited Business Value",
								para: "Radical Minds could ready solution that provides you freedom, choice, and agility to achieve success. Reason Radical Minds' most analytics platform, always delivers the flexibility of SMB businesses' demand with lots of control with almost zero risk.",
							},
							{
								icon: "../../assets/solution/og-icons/small-02.svg",
								title: "Agents Productivity at its high",
								para: "Radical Minds' cutting-edge solution makes Agents productive by providing all domain and customer-related information with a single click to avoid any miscommunication, which in turn always leads to Agent productivity and makes a win-win situation for Agents and Customers both and gets the highest Customer satisfaction for all time.",
							},
							{
								icon: "../../assets/solution/og-icons/small-03.svg",
								title: "Information - Single Click",
								para: "Radical Minds AI-enabled solution provide a deep insight into each data with a single click to delight customer and make an informed decision in line with the Organization's vision and save ample time in looking for information gathering, which is possible with very few solutions.",
							},
						],
					},
					{
						title: [
							"Transform your Industry with a single, unified platform",
						],
						subtitle:
							"Provide secure, personalised customer experiences and engage employees with an all-in-one solution",
						service: [
							{
								title: "IVR",
								para: "Interactive Voice Response is a technology that allows you to communicate with computer programs through the telephone.",
								link: "/services/ivr",
							},
							{
								title: "Computer Telephony Integration",
								para: "Telephone systems and software that allow call integration with computer software applications",
								link: "/services/cti-solutions",
							},
							{
								title: "Inbound Calling",
								para: "Inbound Calling is a highly scalable business phone system that allows your company to be scalable too.",
								link: "/services/inbound-call-center",
							},
							{
								title: "Outbound Calling",
								para: "Outbound Calling enables anyone to make high-quality outbound calls with artificial intelligence through phone, web, or API.",
								link: "/services/outbound-call-center",
							},
							{
								title: "Click to Call",
								para: "Click to Call is a service that lets you place calls using your computer or your mobile phone.",
								link: "/services/click-to-call",
							},
							{
								title: "Cloud Telephony",
								para: "Cloud Telephony is a hosted cloud based telephony platform for businesses to deliver world-class customer experience.",
								link: "/services/cloud-telephony",
							},
							{
								title: "CRM Integration",
								para: "Achieve faster and better sales results using cross-sales, up-sell, or even cross-sell with our highly advanced CRM integration.",
								link: "/services/crm-integration",
							},
							{
								title: "Auto Predictive Dialer",
								para: "Auto predictive dialer future provides an easy and affordable way to grow your business.",
								link: "/services/auto-predictive-dialer",
							},
							{
								title: "Smart Routing",
								para: "Smart Routing is a smart and robust routing algorithm that balances the need for fast response against the correct answers",
								link: "/services/acd-smart-routing",
							},
							{
								title: "Toll Free Number Service",
								para: "A toll-free number is a virtual number that customers can dial to reach a business anywhere across the nation.",
								link: "/services/toll-free-number-service",
							},
							{
								title: "Chatbot",
								para: "Chat Bot Service is a personal chat bot that can be customized to grow with your business needs.",
								link: "/services/chatbot",
							},
							{
								title: "Customer Journey Experience",
								para: "CX is transforming the way companies create amazing experiences for customers throughout their entire journey",
								link: "/services/customer-journey-experience",
							},
							{
								title: "Knowledge Management Platform",
								para: "KM platform is a web application that is used to manage knowledge and information in an easy and intuitive way.",
								link: "/services/knowledge-management-platform",
							},
						],
					},
				],
			},
		],
	},
];
