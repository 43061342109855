import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TextField } from './InputFeild/InputFeild';
import ButtonLoader from './UI/ButtonLoader';
import MobileNumberInput from './mobileInput';
import SelectBox from './selectBox';
import TextareaInput from './textarea-input';
import { api } from '../helper/api';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { getIpAddress } from '../helper/utils';


const InquiryForm = ({ handleClose }) => {


    const options = [
        { value: 'Voice', label: 'Voice' },
        { value: 'Non-Voice', label: 'Non-Voice' },
        { value: 'Inbound', label: 'Inbound' },
        { value: 'Outbound', label: 'Outbound' },
        { value: 'Chat', label: 'Chat' },
        { value: 'Back Office', label: 'Back Office' },
        { value: 'Email', label: 'Email' },
        { value: 'Machine Learning', label: 'Machine Learning' },
        { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
        { value: 'Others', label: 'Others' },
    ];

    const [formData, updateFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        countryCode: '+91',
        company: '',
        serviceSoftware: '',
        message: '',
        ip: '',
        pageURL: '',
        hidden: "home"
    });
    const location = useLocation();
    const fullURL = window.location.origin + location.pathname + location.search;
    const [selectedOption, setSelectedOption] = useState('');
    const [textValue, setTextValue] = useState('');
    const [isValidated, setIsValidated] = useState(false);
    const [email, setEmail] = useState(formData.email ?? '');
    const [isChecked, setIsChecked] = useState(true);
    const [captchaValue, setCaptchaValue] = useState('');
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        emailError: '',
        selectedOptionError: '',
        checkboxError: '',
        captchaError: '',
        nameError: '',
        lastNameError: '',
        mobileError: '',
        companyError: '',
    });

    const toastObj = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    };

    useEffect(() => {
        const fetchIp = async () => {
            const ipAddress = await getIpAddress();
            updateFormData((prevFormData) => ({
                ...prevFormData,
                ip: ipAddress,
            }));
        };

        fetchIp();
        updateFormData((prevFormData) => ({
            ...prevFormData,
            pageURL: fullURL,
        }));
    }, [location]);
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setIsCaptchaVerified(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'firstname' || name === 'lastname') {
            if (/^[a-zA-Z ]*$/.test(value)) {
                updateFormData({
                    ...formData,
                    [name]: value,
                });
                setErrors((prevErrors) => ({ ...prevErrors, [`${name}Error`]: '' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [`${name}Error`]: 'Only letters and spaces are allowed' }));
            }
        } else if (name === 'countryCode') {
            updateFormData({
                ...formData,
                [name]: value,
            });
        } else {
            updateFormData({
                ...formData,
                [name]: value.trim(),
            });
        }

        if (name === 'email') {
            setEmail(value.trim());
            if (!/\S+@\S+\.\S+/.test(value.trim())) {
                setErrors((prevErrors) => ({ ...prevErrors, emailError: 'Please enter a valid email address' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, emailError: '' }));
            }
        }
    };

    const handleTextareaChange = (value) => {
        setTextValue(value);
        updateFormData({
            ...formData,
            message: value,
        });
        setErrors((prevErrors) => ({ ...prevErrors, messageError: '' }));
    };

    const selectHandleChange = (event) => {
        setSelectedOption(event.target.value);
        updateFormData({
            ...formData,
            serviceSoftware: event.target.value,
        });
        setErrors((prevErrors) => ({ ...prevErrors, selectedOptionError: '' }));
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setErrors((prevErrors) => ({ ...prevErrors, checkboxError: '' }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        const fieldsToValidate = [
            { field: email, errorField: 'emailError', message: 'Please enter a valid email address', validator: value => /\S+@\S+\.\S+/.test(value) },
            { field: selectedOption, errorField: 'selectedOptionError', message: 'Please select an option' },
            { field: isChecked, errorField: 'checkboxError', message: 'You must agree to the privacy policy', validator: value => value === true },
            { field: formData.firstname, errorField: 'nameError', message: 'Please enter your first name' },
            { field: formData.lastname, errorField: 'lastNameError', message: 'Please enter your last name' },
            { field: formData.mobile, errorField: 'mobileError', message: 'Please enter your mobile number' },
            { field: formData.company, errorField: 'companyError', message: 'Please enter your company name' },
        ];


        fieldsToValidate.forEach(({ field, errorField, message, validator }) => {
            if (!validator ? !field : !validator(field)) {
                newErrors[errorField] = message;
                isValid = false;
            } else {
                newErrors[errorField] = '';
            }
        });

        if (!isCaptchaVerified) {
            newErrors['captchaError'] = 'Please complete the captcha';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setIsValidated(true);

        if (!validateForm()) {
            setLoading(false);
            return;
        }

        try {
            const response = await api({
                method: 'post',
                url: 'inquiry_home',
                data: {
                    ...formData,
                    homeInquiry: true,
                },
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200) {
                toast.success('Your Inquiry Submitted Successfully', toastObj);
                setTimeout(() => {
                    handleClose();
                    window.location.href = '/thank-you';
                }, 500);
            } else {
                toast.error('Please try Again!', toastObj);
            }
        } catch (error) {
            toast.error('Please try Again!', toastObj);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={true}
            onHide={handleClose}
            className="HomeModal modal-gray"
            centered
        >
            <Modal.Header closeButton className="d-flex justify-content-center">
                <h2 className="w-100 text-center ">Please Enter your Details below!</h2>
            </Modal.Header>
            <Modal.Body>
                <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-6 col-12">
                        <TextField
                            required
                            onChange={handleChange}
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            className="w-100"
                            type="text"
                            isValidated={isValidated}
                            value={formData.firstname}
                        />
                        {errors.nameError && (
                            <div className="invalid-feedback d-block">
                                {errors.nameError}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12">
                        <TextField
                            required
                            onChange={handleChange}
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            className="w-100"
                            type="text"
                            isValidated={isValidated}
                            value={formData.lastname}
                        />
                        {errors.lastNameError && (
                            <div className="invalid-feedback d-block">
                                {errors.lastNameError}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12">
                        <TextField
                            required
                            onChange={handleChange}
                            id="email"
                            name="email"
                            label="Work Email"
                            className="w-100"
                            type="email"
                            isValidated={isValidated}
                            value={formData.email}
                        />
                        {errors.emailError && (
                            <div className="invalid-feedback d-block">
                                {errors.emailError}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12">
                        <MobileNumberInput
                            formData={formData}
                            handleChange={handleChange}
                            isValidated={isValidated}
                            label="Mobile"
                            required
                        />
                        {errors.mobileError && (
                            <p className="invalid-feedback d-block">
                                {errors.mobileError}
                            </p>
                        )}
                    </div>
                    <div className="col-md-6 col-12">
                        <TextField
                            required
                            onChange={handleChange}
                            id="company"
                            name="company"
                            label="Company"
                            className="w-100"
                            type="text"
                            isValidated={isValidated}
                            value={formData.company}
                        />
                        {errors.companyError && (
                            <div className="invalid-feedback d-block">
                                {errors.companyError}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6 col-12">
                        <SelectBox
                            required
                            options={options}
                            value={selectedOption}
                            onChange={selectHandleChange}
                            placeholder="Select Options"
                            label="Services to be Outsourced"
                        />
                        {errors.selectedOptionError && (
                            <div className="invalid-feedback d-block">
                                {errors.selectedOptionError}
                            </div>
                        )}
                    </div>
                    <div className="col-12 mt-2">
                        <TextareaInput
                            value={textValue}
                            onChange={handleTextareaChange}
                            className="my-textarea"
                            placeholder="Enter your message here..."
                            rows={5}
                            label="Message"
                        />

                    </div>
                    <div className="mt-2 mb-2">
                        <label>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className=""
                            />
                            <span style={{ marginLeft: '8px', fontSize: '12px', color: '#666' }}>
                                I agree to allow Radical Minds Technologies Pvt. Ltd. to store and process my personal data as described in the
                                <a
                                    href="/privacy-policy" // Adjust the href to the correct path of your Privacy Policy page
                                    style={{ color: 'blue', textDecoration: 'underline', marginLeft: '4px' }}
                                >
                                    Privacy Policy
                                </a>.
                            </span>
                        </label>
                        {errors.checkboxError && (
                            <div className="invalid-feedback d-block">
                                {errors.checkboxError}
                            </div>
                        )}
                    </div>
                    <ReCAPTCHA
                        sitekey="6Lc-Jh0qAAAAAJE7XX96GKdmRP9quscNHZ7TLGRA"
                        //https://www.google.com/recaptcha/admin/create
                        // sitekey='6LeD9SIqAAAAAPoLrzPtTMGrtwr1XkGRSI718EI9' key for radmintec.com
                        onChange={handleCaptchaChange}
                    />
                    {errors.captchaError && (
                        <div className="invalid-feedback d-block">
                            {errors.captchaError}
                        </div>
                    )}
                    <div className="d-flex justify-content-center mt-3">
                        <ButtonLoader
                            type="submit"
                            loading={loading}
                            className="btn-r inquiryBtn"
                        >
                            Submit
                        </ButtonLoader>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default InquiryForm;
